import { DimensionMathValues } from '@src/client/helpers/reports/constants';
import { Dimension } from '@src/client/helpers/reports/types';
import { isLengthyArray } from '@src/client/lib/utils';
import { isEqual } from 'lodash-es';

import { getValidFilters } from '../global-property-filter/utils';

export const hasDimensionValueChanged = (dimensions: Dimension[], previousDimensions: Dimension[] = []): boolean => {
  const validCurrentDimensions = dimensions.filter((d) => d.name);
  const validPrevDimensions = previousDimensions.filter((d) => d.name);

  if (!isLengthyArray(validCurrentDimensions)) return false;

  if (validCurrentDimensions.length !== validPrevDimensions.length) {
    return true;
  }

  const hasFiltersChanged = () =>
    validCurrentDimensions?.some((d) => {
      const prevDimension = validPrevDimensions.find((prevD) => prevD.name === d.name);
      const prevDimensionValidFilters = getValidFilters(prevDimension?.filter ?? []);
      const currentDimensionValidFilters = getValidFilters(d?.filter ?? []);

      return !isEqual(prevDimensionValidFilters, currentDimensionValidFilters);
    });

  const hasMathAggregatorChanged = () =>
    validCurrentDimensions?.some((d) => {
      const prevDimension = validPrevDimensions.find((prevD) => prevD.name === d.name);
      const prevDimensionAggregator = prevDimension?.math;
      const currentDimensionAggregator = d.math;

      if (d.math.includes(DimensionMathValues.AGGREGATE_PROPERTIES)) {
        if (!d['aggregate-on']) return false;
        return (
          !isEqual(currentDimensionAggregator, prevDimensionAggregator) ||
          !isEqual(d['aggregate-on'], prevDimension?.['aggregate-on'])
        );
      }

      return !isEqual(currentDimensionAggregator, prevDimensionAggregator);
    });

  const hasDimensionNameChanged = () =>
    !isEqual(
      validCurrentDimensions.map((d) => d.name),
      validPrevDimensions.map((d) => d.name),
    );

  const hasFirstTimeFilterChanged = () =>
    !isEqual(
      validCurrentDimensions.map((d) => d.first_time_filter),
      validPrevDimensions.map((d) => d.first_time_filter),
    );

  const hasFlowsStepsChanged = () =>
    !isEqual(
      validCurrentDimensions.map((d) => d.steps_after),
      validPrevDimensions.map((d) => d.steps_after),
    ) ||
    !isEqual(
      validCurrentDimensions.map((d) => d.steps_before),
      validPrevDimensions.map((d) => d.steps_before),
    );

  return (
    hasFiltersChanged() ||
    hasMathAggregatorChanged() ||
    hasDimensionNameChanged() ||
    hasFirstTimeFilterChanged() ||
    hasFlowsStepsChanged()
  );
};

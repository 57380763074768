/* eslint-disable no-underscore-dangle */
import {
  logoutHandler,
  redirectToLogin,
  setGoogleLoginAuthCookies,
  setMicrosoftLoginAuthCookies,
} from '@src/client/modules/login/utils';
import dayjs from 'dayjs';
import { Cookies } from 'react-cookie';

import { ErrorTags } from '../analytics/events';
import Tracker from '../analytics/tracker';
import { dispatchCustomEvent } from '../event-emitter/eventEmitters';
import { CustomEventEmitterKeys } from '../event-emitter/types';
import { acquireAccessToken } from '../msal-utils';
import { setLastVisitedpath } from '../utils';
import { Cookie, UserEndpoints } from './constants';
import {
  AppVersionError,
  AuthError,
  ClientError,
  NetworkError,
  ServerError,
  SessionError,
  UnprocessableEntityError,
} from './errors';
import { refreshGoogleLogin, validateAndLogin } from './mutations/common';
import { OauthProvider } from './types/request';

export const BASE_REQUEST: RequestInit = {
  mode: 'cors',
  cache: 'no-store',
};

type ParamFunc<P, R> = (params: P) => R;

export interface NodeConfig<P, R> {
  // Given the input params, returns the path to request.
  path: ParamFunc<P, string> | string;
  // Given the input params, returns the request config.
  request?: ParamFunc<P, RequestInit> | RequestInit;
  // Transform response to something else.
  response?: (responseJson: any) => R;
}

/**
 * Handles a network error.
 */
function handleNetworkError<R = Response>(error: Error): Promise<R> {
  throw new NetworkError(error);
}

/**
 * Handles various status codes.
 * @param response HTTP response object.
 */
export function handleStatusCode(response: Response): Response {
  if (response.status >= 300) {
    if (response.status === 401 || response.status === 403) {
      throw new AuthError(response);
    } else if (response.status === 422) {
      throw new UnprocessableEntityError(response);
    } else if (response.status >= 400 && response.status < 500) {
      throw new ClientError(response);
    } else {
      throw new ServerError(response);
    }
  }
  return response;
}

/**
 * Parses the response body based on `Content-Type`.
 * @param response
 */
export function parseResponseBody(response: Response): any {
  const contentType = response.headers.get('Content-Type');
  if (contentType) {
    if (contentType.match(/^application\/json/)) {
      return response.json();
    }
    if (contentType.match(/^text/)) {
      return response.text();
    }
    return response.blob();
  }
  return response.blob();
}

/**
 * Handles various application response errors.
 * @param response Parsed response object (not HTTP).
 */
export function handleApplicationError(response: any) {
  if (typeof response !== 'object') {
    return response;
  }
  if (!response.result__) {
    return response;
  }

  switch (response.result__) {
    case 1:
      return response; // ok
    case -1:
      throw new ServerError(response.error_msg__); // generic error
    case -2:
      throw new AppVersionError(response.error_msg__);
    case -10:
      throw new SessionError(response.error_msg__);
    case -11:
      throw new AuthError(response.error_msg__);
    case -20:
      throw new ClientError(response.error_msg__);
    default:
      throw new ServerError(response.error_msg__);
  }
}

const getApiErrorType = (err: Error) => {
  if (err instanceof ServerError) {
    return 'ServerError';
  }
  if (err instanceof ClientError) {
    return 'ClientError';
  }
  if (err instanceof AuthError) {
    return 'AuthError';
  }
  if (err instanceof SessionError) {
    return 'SessionError';
  }
  if (err instanceof UnprocessableEntityError) {
    return 'UnprocessableEntityError';
  }
  return 'Unknown';
};

export default class ApiClient {
  cookies: Cookies;

  prefix: string;

  constructor(prefix = '') {
    this.cookies = new Cookies();
    this.prefix = prefix;
  }

  createRequest<P, R>(config: NodeConfig<P, R>) {
    return async (params: P): Promise<R> => {
      const startTime = performance.now();
      try {
        const req = await this.createRequestInner(config, params);
        return await req()
          .then((response) => this.handleInvalidCredentials<P, R>(response, config, params), handleNetworkError)
          .then(handleStatusCode)
          .then((res) => (config.response ? config.response(res) : parseResponseBody(res)))
          .then(handleApplicationError)
          .then(config.response || ((r) => r))
          .catch((e: Error) => {
            this.trackApiError(config, startTime, e, getApiErrorType(e));
            throw e;
          });
      } catch (e: any) {
        this.trackApiError(config, startTime, e, 'RequestCreationError');
        throw e;
      }
    };
  }

  async createRequestInner<P, R>(config: NodeConfig<P, R>, params: P): Promise<() => Promise<Response>> {
    const { path, request } = config;

    // Prefix is the protocol + host + path.
    const { prefix } = this;
    // Construct URL.
    const url = prefix + (typeof path === 'function' ? path(params) : path);
    // Construct request.
    const clientRequest = request ? (typeof request === 'function' ? request(params) : request) : { headers: {} }; // eslint-disable-line no-nested-ternary
    if (!this.isLoginFlowUrl(url) && this.isAuthTokenExpired()) {
      await this.handleAuthTokenRefetch(path !== UserEndpoints.userInfo); // userInfo api calls internally takes care of redirection
    }

    const token = this.cookies.get(Cookie.id_token) || '';

    const NEXT_HEADER = localStorage.getItem(Cookie.next) || '';
    const WORKSPACE_ID = localStorage.getItem(Cookie.workspace_id) || '';
    const TENANT_ID = localStorage.getItem(Cookie.tenant_id) || '';

    // This creates the whole request object.
    const finalRequest = {
      ...clientRequest,
      ...BASE_REQUEST,
      headers: {
        ...clientRequest.headers,
        ...(NEXT_HEADER.length > 0 ? { 'X-UD-Next': NEXT_HEADER } : {}),
        ...(WORKSPACE_ID.length > 0 ? { 'X-WORKSPACE-ID': WORKSPACE_ID } : {}),
        ...(TENANT_ID.length > 0 ? { 'X-TENANT-ID': TENANT_ID } : {}),
        Authorization: `Bearer ${token}`,
      },
    };
    return () => fetch(url, finalRequest);
  }

  private isGoogleLogin() {
    return this.cookies.get(Cookie.auth_type) && this.cookies.get(Cookie.auth_type) === OauthProvider.google;
  }

  private isMSLogin() {
    return this.cookies.get(Cookie.auth_type) && this.cookies.get(Cookie.auth_type) === OauthProvider.microsoft;
  }

  private isRefreshTokenPresent() {
    return this.cookies.get(Cookie.refresh_token) && this.cookies.get(Cookie.refresh_token) !== '';
  }

  private isAuthTokenExpired() {
    const token = this.cookies.get(Cookie.id_token) || '';
    const tokenExpiry = this.cookies.get(Cookie.id_token_expiry);

    return !token || (tokenExpiry && dayjs(tokenExpiry).isBefore(dayjs()));
  }

  // eslint-disable-next-line class-methods-use-this
  private isLoginFlowUrl(url: string) {
    return (
      url.includes(UserEndpoints.login) || url.includes(UserEndpoints.signup) || url.includes(UserEndpoints.refresh)
      // url.includes(UserEndpoints.authorize) Authorize call needs token
    );
  }

  async handleAuthTokenRefetch(redirect = true) {
    if (this.isGoogleLogin() && this.isRefreshTokenPresent()) {
      refreshGoogleLogin(this.cookies.get(Cookie.refresh_token))
        .then((value) => {
          setGoogleLoginAuthCookies(value);
          validateAndLogin()
            .then((r) => {})
            .catch((e) => {
              Tracker.trackError(e, ErrorTags.INVALID_CREDENTIALS_ERROR);
              this.handleLogout(redirect);
            });
        })
        .catch((e) => {
          Tracker.trackError(e, ErrorTags.INVALID_CREDENTIALS_ERROR);
          this.handleLogout(redirect);
        });
    } else if (this.isMSLogin()) {
      const authenticationResult = await acquireAccessToken();
      if (authenticationResult !== null && authenticationResult !== undefined) {
        setMicrosoftLoginAuthCookies(authenticationResult);
      } else {
        await this.handleLogout(redirect);
      }
    } else if (redirect) {
      console.log('neither google nor microsoft login , redirecting to login');
      // saveCurrentpathAndRedirectToLogin();
      await this.handleLogout(redirect);
    }
  }

  async handleInvalidCredentials<P, R>(response: Response, config: NodeConfig<P, R>, params: P) {
    if (response.status === 401) {
      const url = new URL(response.url);
      await this.handleAuthTokenRefetch(url.pathname !== this.prefix + UserEndpoints.userInfo); // userInfo api calls internally takes care of redirection
    }
    return response;
  }

  // eslint-disable-next-line class-methods-use-this
  trackApiError<P, R>(config: NodeConfig<P, R>, startTime: number, e: Error, apiErrorType: string) {
    const endTime = performance.now();
    const { path, request } = config;
    Tracker.trackError(e, ErrorTags.API_REQUEST_ERROR, {
      apiErrorType,
      path: path.toString(),
      request: request?.toString(),
      timeTaken: endTime - startTime,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleLogout = async (redirect: boolean) => {
    dispatchCustomEvent(CustomEventEmitterKeys.showSessionExpiredDialog, {
      title: 'Your session has expired',
      description: 'Please login to continue',
      onOk: async () => {
        setLastVisitedpath();
        await logoutHandler();
        if (redirect) {
          redirectToLogin(true);
        }
      },
    });
  };
}

/* eslint-disable  no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable radix  */
import { useLayoutTopPaddingBasedOnUiVersion } from '@src/client/hooks';
import { Button } from '@src/client/ui-library/button';
import { useUIVersion } from '@src/client/ui-library/theme-provider/uiProvider';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import { cn } from '../../ui-library/utils';
import LucidIconWrapper from '../lucid-icon-wrapper';
import { leftSidepanelOpenState, rightSidepanelOpenState } from './state';

interface LeftSidepanelProps extends PropsWithChildren {
  size?: 'small' | 'large';
  classname?: string;
  isResizable?: boolean;
}
const [leftPanelMinWidth, leftPanelMaxWidth, leftPanelDefaultWidthLarge, leftPanelDefaultWidthSmall] = [
  320, 600, 340, 260,
];
export function LeftSidepanel({ children, size = 'small', classname = '', isResizable = false }: LeftSidepanelProps) {
  const [isSidepanelOpen, setIsSidepanelOpen] = useRecoilState(leftSidepanelOpenState);
  const [sidebarWidth, setSidebarWidth] = useState(
    size === 'small' ? leftPanelDefaultWidthSmall : leftPanelDefaultWidthLarge,
  );
  const sidebarRef = useRef<HTMLDivElement>(null);
  const isResizing = useRef(false);
  const { uiVersion } = useUIVersion();
  const topPaddingClassname = useLayoutTopPaddingBasedOnUiVersion();

  const startResizing = () => {
    isResizing.current = true;
  };

  const stopResizing = () => {
    isResizing.current = false;
  };

  const widthSetter = (e: MouseEvent) => {
    if (!isResizing.current) {
      return;
    }

    setSidebarWidth((previousWidth) => {
      const newWidth = previousWidth + e.movementX / 2;

      const isWidthInRange =
        newWidth >= leftPanelMinWidth && newWidth <= Math.min(leftPanelMaxWidth, window.innerWidth / 2);

      return isWidthInRange ? newWidth : previousWidth;
    });
  };

  useEffect(() => {
    if (isResizable) {
      window.addEventListener('mousemove', widthSetter);

      window.addEventListener('mouseup', stopResizing);
    }
  }, [isResizable]);

  useEffect(() => {
    localStorage.setItem('pi_reportsidebarWidth', sidebarWidth.toString());
  }, [sidebarWidth]);

  return (
    <div
      className={cn(
        'flex border-r-border border-r ease-in-out duration-300',
        `${isSidepanelOpen ? (size === 'small' ? 'w-[260px]' : 'w-[320px] min-w-[320px]') : 'w-[60px]'}`,
      )}
      style={isSidepanelOpen && isResizable ? { width: `${sidebarWidth / 16}rem` } : {}}
      // onMouseDown={(e) => e.preventDefault()}
      ref={sidebarRef}
    >
      <div
        className={cn(
          `w-full overflow-y-scroll pt-6 px-2 flex flex-col no-scrollbar`,
          `sticky z-[11] bg-background left-0 `,
          topPaddingClassname,
          uiVersion === 'v3' ? '' : 'h-layout-v2  max-h-layout-v2',
          classname,
        )}
      >
        <div className="flex-1 overflow-y-scroll pb-12 no-scrollbar">{children}</div>
        <div className={isSidepanelOpen ? 'text-right mb-2' : 'text-center mb-2'}>
          <Button variant="icon" onClick={() => setIsSidepanelOpen(!isSidepanelOpen)} className="p-2">
            <LucidIconWrapper name={isSidepanelOpen ? 'arrow-left-to-line' : 'arrow-right-to-line'} />
          </Button>
        </div>
      </div>
      {isResizable && isSidepanelOpen ? (
        // Handle
        <div className="w-0.5 cursor-col-resize bg-foreground-secondary" onMouseDown={startResizing} />
      ) : null}
    </div>
  );
}

export function RightSidepanel({ children }: PropsWithChildren) {
  const [isSidepanelOpen, setIsSidepanelOpen] = useRecoilState(rightSidepanelOpenState);
  return (
    <div
      className={`${
        isSidepanelOpen ? 'w-[260px] pl-4 pr-2' : 'w-[40px] px-2'
      } sticky right-0 top-navbar h-layout-v2 max-h-layout-v2 overflow-y-scroll border-l-border border-l pt-6 flex flex-col ease-in-out duration-300 no-scrollbar`}
    >
      <div className="flex-1 overflow-y-scroll pb-12 no-scrollbar">{children}</div>
      {/* <div className="text-left">
        <Button variant="icon" onClick={() => setIsSidepanelOpen(!isSidepanelOpen)} className="shadow-lg">
          <SidePanelCollapseIcon className={isSidepanelOpen ? 'rotate-180' : ''} />
        </Button>
      </div> */}
    </div>
  );
}

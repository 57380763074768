import { Breakdown } from '@src/client/helpers/reports/types';
import { atom, selector, useResetRecoilState } from 'recoil';

import { PropertyAttribution } from '../../../helpers/reports/constants';

export const breakdownsState = atom<Breakdown[]>({
  key: 'breakdownsStateKey',
  default: [],
});

export const breakdownPropertyAttributionState = atom<PropertyAttribution>({
  key: 'breakdownPropertyAttributionState',
  default: PropertyAttribution.LAST_TOUCH,
});

export const validBreakdownsState = selector({
  key: 'validBreakdownsState',
  get: ({ get }) => {
    const breakdowns = get(breakdownsState);
    return breakdowns.filter(
      (d) => d.property && (Array.isArray(d.property) ? d.property.length > 0 : d.property !== ''),
    );
  },
});

export const isBreakdownsAppliedState = selector({
  key: 'isBreakdownsAppliedState',
  get: ({ get }) => {
    const breakdowns = get(breakdownsState);
    return (
      breakdowns.filter((d) => d.property && (Array.isArray(d.property) ? d.property.length > 0 : d.property !== ''))
        .length > 0
    );
  },
});

/* Helpers */
export const useResetBreakdownState = () => {
  const resetBreakdowns = useResetRecoilState(breakdownsState);

  return () => {
    resetBreakdowns();
  };
};

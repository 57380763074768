/* eslint-disable import/prefer-default-export */

// NOTE: put only common atoms here
import { UserInfo, Workspace } from '@src/client/lib/api/types/response';
import { atom } from 'recoil';

export const userInfoState = atom<UserInfo | undefined>({
  key: 'userInfoState',
  default: undefined,
});

export const activeWorkspaceState = atom<Workspace | null>({
  key: 'activeWorkspaceState',
  default: null,
});

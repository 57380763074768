import { useMemo } from 'react';

import { DEFAULT_MARGIN } from './utils';

export default function YAxis({ yScale, width }: { yScale: d3.ScaleLinear<number, number, never>; width: number }) {
  const ticks = useMemo(() => {
    let allTicks = yScale.ticks(6);

    if (allTicks.length > 6) {
      const indexMeasurer = Math.ceil(allTicks.length / 5);

      allTicks = allTicks.filter((tick, index) => index % indexMeasurer === 0);
    }
    // .concat(yScale.domain())
    return allTicks.map((value) => ({
      value,
      yOffset: yScale(value),
    }));
  }, [yScale]);

  return (
    <g>
      {ticks.map((tick) => (
        <g transform={`translate(0,${tick.yOffset})`} key={tick.value}>
          <line
            x1={DEFAULT_MARGIN.left}
            x2={width - DEFAULT_MARGIN.right}
            stroke="currentColor"
            strokeDasharray="1,3"
            opacity={0.5}
          />
          <text alignmentBaseline="middle" className="text-[10px]" fill="currentColor">
            {tick.value}
          </text>
        </g>
      ))}
    </g>
  );
}

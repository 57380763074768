import { SVGProps } from 'react';

import { useTheme } from '../theme-provider';

export function BarChartIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        d="M15.827 4a.75.75 0 0 0-.75.75v14.5c0 .414.336.75.75.75h3.423a.75.75 0 0 0 .75-.75V4.75a.75.75 0 0 0-.75-.75h-3.423Zm.48 2.083a.75.75 0 0 1 .75-.75h.962a.75.75 0 0 1 .75.75v11.834a.75.75 0 0 1-.75.75h-.961a.75.75 0 0 1-.75-.75V6.083ZM4.75 8a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75h3.423a.75.75 0 0 0 .75-.75V8.75a.75.75 0 0 0-.75-.75H4.75Zm.48 2.083a.75.75 0 0 1 .75-.75h.962a.75.75 0 0 1 .75.75v7.834a.75.75 0 0 1-.75.75h-.961a.75.75 0 0 1-.75-.75v-7.834ZM10.29 12a.75.75 0 0 0-.75.75v6.5c0 .414.335.75.75.75h3.422a.75.75 0 0 0 .75-.75v-6.5a.75.75 0 0 0-.75-.75H10.29Zm.48 2.083a.75.75 0 0 1 .75-.75h.962a.75.75 0 0 1 .75.75v3.834a.75.75 0 0 1-.75.75h-.962a.75.75 0 0 1-.75-.75v-3.834Z"
      />
    </svg>
  );
}

export function LineChartIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        d="M4.889 4A.889.889 0 0 0 4 4.889v13.333C4 19.194 4.806 20 5.778 20H19.11a.889.889 0 0 0 0-1.778H6.778a1 1 0 0 1-1-1V4.89A.889.889 0 0 0 4.888 4Zm14.243 3.577a.889.889 0 0 0-1.256-.006l-2.528 2.505a1 1 0 0 1-1.395.012l-1.26-1.206a1 1 0 0 0-1.39.006l-3.73 3.633a.889.889 0 0 0 1.24 1.273l2.501-2.435a1 1 0 0 1 1.389-.007l1.27 1.215a1 1 0 0 0 1.395-.012l3.759-3.72a.889.889 0 0 0 .005-1.258Z"
      />
    </svg>
  );
}

export function MetricChartIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        d="m8.115 4-.311 3.5h-2.96l-.193 2h2.975L7.138 15h-2.96L4 17h2.96l-.266 2.953V20h1.894l.267-3h5.21l-.267 2.953V20h1.895l.266-3h3.197l.178-2h-3.197l.488-5.5h3.183l.192-2h-3.197l.31-3.453V4H15.22l-.31 3.5h-5.21l.31-3.453V4H8.115ZM9.52 9.5h5.21l-.489 5.5h-5.21l.489-5.5Z"
      />
    </svg>
  );
}

export function PieChartIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        fillRule="evenodd"
        d="M14.071 4.006a.774.774 0 0 0-.617.184.774.774 0 0 0-.268.602v5.233c0 .442.347.789.789.789h5.233c.24 0 .442-.107.583-.248l.01-.01.009-.01a.788.788 0 0 0 .184-.617V9.92c-.413-3.062-2.854-5.487-5.912-5.913l-.01-.001Zm-2.323.498C7.478 4.504 4 7.984 4 12.252 4 16.522 7.479 20 11.748 20s7.748-3.479 7.748-7.748a.781.781 0 0 0-.79-.789h-6.17v-6.17a.781.781 0 0 0-.788-.789Zm3.015 4.733V5.779a5.371 5.371 0 0 1 3.458 3.458h-3.458Zm-9.186 3.015a6.18 6.18 0 0 1 5.382-6.12v6.12c0 .442.347.789.789.789h6.118c-.393 3.029-2.986 5.382-6.118 5.382a6.18 6.18 0 0 1-6.17-6.17Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function TableChartIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        d="M11.986 4a.983.983 0 0 0-.128.01H5.787c-.97 0-1.775.726-1.775 1.599v3.86a.72.72 0 0 0 0 .26v4.534a.72.72 0 0 0 0 .26v3.869c0 .873.805 1.597 1.775 1.597h6.064a.982.982 0 0 0 .29 0h6.072c.97 0 1.775-.724 1.775-1.598v-3.86a.718.718 0 0 0 0-.26V9.738a.718.718 0 0 0 0-.261V5.609c0-.873-.805-1.598-1.775-1.598h-6.076a.982.982 0 0 0-.15-.011ZM5.787 5.609h5.325v3.195H5.787V5.61Zm7.1 0h5.326v3.195h-5.325V5.61Zm-7.1 4.793h5.325v3.196H5.787v-3.196Zm7.1 0h5.326v3.196h-5.325v-3.196Zm-7.1 4.794h5.325v3.196H5.787v-3.196Zm7.1 0h5.326v3.196h-5.325v-3.196Z"
      />
    </svg>
  );
}

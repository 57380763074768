import { CompareTimePeriodCustomSubTypes, CompareTimePeriodSubTypes } from '@src/client/helpers/reports/constants';
import { isLengthyArray, snakeCaseToTitleCase } from '@src/client/lib/utils';
import dayjs, { Dayjs } from 'dayjs';
import { atom, selector } from 'recoil';

export type CustomCompareStateType = { date: Dayjs; type: CompareTimePeriodCustomSubTypes };

export const compareState = atom<string[]>({
  key: 'compareStateKey',
  default: [],
});

export const customCompareState = atom<CustomCompareStateType | undefined>({
  key: 'customCompareStateKey',
  default: undefined,
});

export const isDateCompareEnabledSelector = selector<boolean>({
  key: 'isDateCompareEnabledSelector',
  get: ({ get }) => {
    const compareData = get(compareState);

    return isLengthyArray(compareData) && compareData.length === 2;
  },
});

export const compareTriggerDisplayLabel = selector<string>({
  key: 'compareTriggerDisplayLabelSelector',
  get: ({ get }) => {
    const compareData = get(compareState);
    const customCompareData = get(customCompareState);

    if (!isLengthyArray(compareData)) return 'Compare';

    if (compareData[1] === CompareTimePeriodSubTypes.CUSTOM && customCompareData) {
      return `Compare to date range ${
        customCompareData.type === CompareTimePeriodCustomSubTypes.CUSTOM_START ? 'starting' : 'ending'
      } on ${dayjs(customCompareData.date).format('YYYY-MM-DD')}`;
    }

    return `Compare to ${snakeCaseToTitleCase(compareData[1])}`;
  },
});

import LayoutV2 from '@src/client/components/layout';
import { ScreenNames } from '@src/client/routes/data';
import { useUIVersion } from '@src/client/ui-library/theme-provider/uiProvider';

import HomeLeftNavigationPanel from './components/HomeLeftNavigationPanel';
import HomeRightPanel from './components/HomeRightPanel';
import ReportsView from './components/ReportsView';

export default function AllReportsScreen() {
  const { uiVersion } = useUIVersion();
  return (
    <LayoutV2 screenName={ScreenNames.HomeScreen}>
      {uiVersion === 'v2' ? (
        <div className="flex relative w-full">
          <HomeLeftNavigationPanel />
          <div className="flex-1 py-6 px-4">
            <ReportsView currentUserOnly={false} />
          </div>
          <HomeRightPanel hideCreatedBy={false} />
        </div>
      ) : (
        <div className="flex relative">
          <div className="flex-1 py-6 px-4">
            <ReportsView currentUserOnly={false} />
          </div>
          <HomeRightPanel hideCreatedBy={false} />
        </div>
      )}
    </LayoutV2>
  );
}

import { MetricCardType } from './constants';
import MetricCard from './MetricCard';

interface Props {
  virtualAllEventId: string;
}

export default function NewUserCard({ virtualAllEventId }: Props) {
  return (
    <MetricCard
      virtualAllEventId={virtualAllEventId}
      metricType={MetricCardType.NewUsers}
      chartDisplayType="area"
      noDataInfoMessage="Please ensure that you are setting the user id."
    />
  );
}

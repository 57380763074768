import { ErrorTags, EventNames, EventProperty } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { ReportType } from '@src/client/lib/api/types/response';
import { safeRunBlock } from '@src/client/lib/utils';

export enum ReportPerformanceMarkers {
  ReportRunStart = 'ReportRunStart',
  ReportServerRunEnd = 'ReportServerRunEnd',
  ReportClientRunEnd = 'ReportClientRunEnd',
}

export enum ReportPerformanceMeasures {
  ReportServerTime = 'ReportServerTime',
  ReportClientTime = 'ReportClientTime',
  ReportTotalTime = 'ReportTotalTime',
}

export const clearReportPerfMarkers = () => {
  performance.clearMarks(ReportPerformanceMarkers.ReportRunStart);
  performance.clearMarks(ReportPerformanceMarkers.ReportServerRunEnd);
  performance.clearMarks(ReportPerformanceMarkers.ReportClientRunEnd);
  performance.clearMeasures(ReportPerformanceMeasures.ReportServerTime);
  performance.clearMeasures(ReportPerformanceMeasures.ReportClientTime);
  performance.clearMeasures(ReportPerformanceMeasures.ReportTotalTime);
};

export const trackReportPerf = (
  reportType: ReportType,
  runId: string | undefined,
  additionalProps: Record<string, unknown> = {},
) => {
  safeRunBlock(() => {
    const serverMakrkerData = performance.measure(
      ReportPerformanceMeasures.ReportServerTime,
      ReportPerformanceMarkers.ReportRunStart,
      ReportPerformanceMarkers.ReportServerRunEnd,
    );
    const clientMarkerData = performance.measure(
      ReportPerformanceMeasures.ReportClientTime,
      ReportPerformanceMarkers.ReportServerRunEnd,
      ReportPerformanceMarkers.ReportClientRunEnd,
    );
    const toalMarkerData = performance.measure(
      ReportPerformanceMeasures.ReportTotalTime,
      ReportPerformanceMarkers.ReportRunStart,
      ReportPerformanceMarkers.ReportClientRunEnd,
    );

    Tracker.trackEvent(
      EventNames.REPORT_RENDER_TIME,
      {
        [EventProperty.ReportType]: reportType,
        [EventProperty.RunId]: runId,
        [EventProperty.ClientTime]: clientMarkerData?.duration,
        [EventProperty.PerceptServerTime]: serverMakrkerData?.duration,
        [EventProperty.TotalTime]: toalMarkerData?.duration,
      },
      {
        source: 'default',
        ...additionalProps,
      },
    );
  }, ErrorTags.REPORT_PERF_TRACKING_ERROR);
};

export enum LLMPerformanceMarkers {
  QueryStart = 'QueryStart',
  LLMServerRunEnd = 'LLMServerRunEnd',
  PerceptServerRunEnd = 'PerceptServerRunEnd',
  ClientRunEnd = 'ClientRunEnd',
}

export enum LLMPerformanceMeasures {
  LLMServerTime = 'LLMServerTime',
  PerceptServerTime = 'PerceptServerTime',
  ClientTime = 'ClientTime',
  TotalTime = 'TotalTime',
}

export const clearLLMPerfMarkers = () => {
  performance.clearMarks(LLMPerformanceMarkers.QueryStart);
  performance.clearMarks(LLMPerformanceMarkers.LLMServerRunEnd);
  performance.clearMarks(LLMPerformanceMarkers.PerceptServerRunEnd);
  performance.clearMarks(LLMPerformanceMarkers.ClientRunEnd);
  performance.clearMeasures(LLMPerformanceMeasures.LLMServerTime);
  performance.clearMeasures(LLMPerformanceMeasures.PerceptServerTime);
  performance.clearMeasures(LLMPerformanceMeasures.ClientTime);
  performance.clearMeasures(LLMPerformanceMeasures.TotalTime);
};

export const trackLLMPerf = (query: string) => {
  safeRunBlock(() => {
    const llmMarkerData = performance.measure(
      LLMPerformanceMeasures.LLMServerTime,
      LLMPerformanceMarkers.QueryStart,
      LLMPerformanceMarkers.LLMServerRunEnd,
    );
    const serverMarkerData = performance.measure(
      LLMPerformanceMeasures.PerceptServerTime,
      LLMPerformanceMarkers.LLMServerRunEnd,
      LLMPerformanceMarkers.PerceptServerRunEnd,
    );
    const clientMarkerData = performance.measure(
      LLMPerformanceMeasures.ClientTime,
      LLMPerformanceMarkers.PerceptServerRunEnd,
      LLMPerformanceMarkers.ClientRunEnd,
    );
    const toalMarkerData = performance.measure(
      LLMPerformanceMeasures.TotalTime,
      LLMPerformanceMarkers.QueryStart,
      LLMPerformanceMarkers.ClientRunEnd,
    );

    Tracker.trackEvent(EventNames.LLM_RENDER_TIME, {
      [EventProperty.Query]: query,
      [EventProperty.LLMTime]: llmMarkerData?.duration,
      [EventProperty.PerceptServerTime]: serverMarkerData?.duration,
      [EventProperty.ClientTime]: clientMarkerData?.duration,
      [EventProperty.TotalTime]: toalMarkerData?.duration,
    });
  }, ErrorTags.LLM_PERF_TRACKING_ERROR);
};

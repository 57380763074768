import LayoutV2 from '@src/client/components/layout';
import { ScreenNames } from '@src/client/routes/data';
import { useUIVersion } from '@src/client/ui-library/theme-provider/uiProvider';

import HomeLeftNavigationPanel from './components/HomeLeftNavigationPanel';
import HomeRightPanel from './components/HomeRightPanel';
import ReportsView from './components/ReportsView';

export default function MyReportsScreen() {
  const { uiVersion } = useUIVersion();
  return (
    <LayoutV2 screenName={ScreenNames.HomeScreen}>
      <div className="flex relative w-full">
        {uiVersion === 'v2' ? <HomeLeftNavigationPanel /> : null}
        <div className="flex-1 py-6 px-4">
          <ReportsView currentUserOnly />
        </div>
        <HomeRightPanel hideCreatedBy />
      </div>
    </LayoutV2>
  );
}

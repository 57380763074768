import { breakdownsState } from '@src/client/components/filters-and-selectors/breakdown-selector/state';
import {
  conversionTimeWindowState,
  conversionWindowFunctionState,
} from '@src/client/components/filters-and-selectors/conversion-criteria-filter/state';
import {
  customDateRangeState,
  dateRangeState,
} from '@src/client/components/filters-and-selectors/date-range-selector/state';
import { dimensionsStateV2 } from '@src/client/components/filters-and-selectors/dimension-filter/state';
import {
  flowsExpandedEventsListState,
  validFlowsExpandedEventsListState,
} from '@src/client/components/filters-and-selectors/expand-flow-events/state';
import { globalFiltersStateV2 } from '@src/client/components/filters-and-selectors/global-property-filter/state';
import { granularityState } from '@src/client/components/filters-and-selectors/granularity-selector';
import { DateRangeEnum, FlowsEventFilterType, FunnelCountTypes } from '@src/client/helpers/reports/constants';
import { FlowQueryBuilder } from '@src/client/helpers/reports/types';
import { PluralSupportedTimeUnits } from '@src/client/lib/api/types/response';
import { isLengthyArray } from '@src/client/lib/utils';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  flowPrivateDimensionErrorState,
  flowsChartDataState,
  flowsDescriptionState,
  flowsErrorState,
  flowsEventFilterTypeState,
  flowsEventRowCountState,
  flowsExcludeEventsState,
  flowsIncludeEventsState,
  flowsNameState,
  flowsRunIdState,
} from '../state';

export const useGetQueryBuilderDataForFlow = (): FlowQueryBuilder => {
  const flowTitle = useRecoilValue(flowsNameState);
  const flowDescription = useRecoilValue(flowsDescriptionState);
  const dimensions = useRecoilValue(dimensionsStateV2);
  const globalFilters = useRecoilValue(globalFiltersStateV2);
  const dateRange = useRecoilValue(dateRangeState);
  const customDateRange = useRecoilValue(customDateRangeState);
  const funnelConversionFunction = useRecoilValue(conversionWindowFunctionState);
  const funnelConversionWindow = useRecoilValue(conversionTimeWindowState);
  const granularity = useRecoilValue(granularityState);
  const flowsRowCount = useRecoilValue(flowsEventRowCountState);
  const flowsEventFilterType = useRecoilValue(flowsEventFilterTypeState);
  const flowsIncludeEvents = useRecoilValue(flowsIncludeEventsState);
  const flowsExcludeEvents = useRecoilValue(flowsExcludeEventsState);
  const flowsExpandedEvents = useRecoilValue(validFlowsExpandedEventsListState);

  return {
    flowTitle,
    flowDescription,
    flowsRowCount,
    flowsExpandedEvents,
    flowsEventFilterType,
    flowsIncludeEvents,
    flowsExcludeEvents,
    steps: dimensions,
    'global-filters': globalFilters,
    dateRange,
    customDateRange,
    sinceDateRange: customDateRange,
    function: funnelConversionFunction,
    measure_time_window: funnelConversionWindow,
    granularity,
  };
};

export const useSetAllFlowAtomValues = () => {
  const setDimensionState = useSetRecoilState(dimensionsStateV2);
  const setBreakdownsState = useSetRecoilState(breakdownsState);
  const setGlobalFiltersState = useSetRecoilState(globalFiltersStateV2);
  const setDateRangeState = useSetRecoilState(dateRangeState);
  const setReportName = useSetRecoilState(flowsNameState);
  const setReportDescription = useSetRecoilState(flowsDescriptionState);
  const setCustomDateRange = useSetRecoilState(customDateRangeState);
  const setFunnelConversionWindow = useSetRecoilState(conversionTimeWindowState);
  const setFunnelConversionFunction = useSetRecoilState(conversionWindowFunctionState);
  const setGranularityState = useSetRecoilState(granularityState);

  const setFlowsRowCount = useSetRecoilState(flowsEventRowCountState);
  const setFlowsEventFilterType = useSetRecoilState(flowsEventFilterTypeState);
  const setFlowsIncludeEvents = useSetRecoilState(flowsIncludeEventsState);
  const setFlowsExcludeEvents = useSetRecoilState(flowsExcludeEventsState);
  const setFlowsExpandedEvents = useSetRecoilState(flowsExpandedEventsListState);
  const resetFlowsSelectedtEventFilterType = useResetRecoilState(flowsEventFilterTypeState);

  return (data: FlowQueryBuilder) => {
    setDimensionState(data.steps || []);
    setBreakdownsState(data.breakdowns || []);
    setGlobalFiltersState(data['global-filters'] || []);
    setDateRangeState(data.dateRange);
    setReportName(data.flowTitle);
    setReportDescription(data.flowDescription);
    setFunnelConversionWindow(
      data.measure_time_window || {
        number: 14,
        unit: PluralSupportedTimeUnits.DAYS,
      },
    );
    setFunnelConversionFunction(data.function || FunnelCountTypes.TOTALS);

    if (data.granularity) {
      setGranularityState(data.granularity);
    }

    if (data.dateRange === DateRangeEnum.CUSTOM && isLengthyArray(data.customDateRange)) {
      setCustomDateRange(data.customDateRange!);
    }
    if (data.dateRange === DateRangeEnum.SINCE && isLengthyArray(data.sinceDateRange)) {
      setCustomDateRange(data.sinceDateRange!);
    }

    setFlowsRowCount(data.flowsRowCount);
    setFlowsIncludeEvents(data.flowsIncludeEvents ?? []);
    setFlowsExcludeEvents(data.flowsExcludeEvents ?? []);
    setFlowsExpandedEvents(data.flowsExpandedEvents ?? []);
    if (isLengthyArray(data.flowsIncludeEvents)) {
      setFlowsEventFilterType(FlowsEventFilterType.INCLUDE_EVENTS);
    } else if (isLengthyArray(data.flowsExcludeEvents)) {
      setFlowsEventFilterType(FlowsEventFilterType.EXCLUDE_EVENTS);
    } else {
      resetFlowsSelectedtEventFilterType();
    }
  };
};

export const useResetFlowsState = () => {
  const resetDimensionState = useResetRecoilState(dimensionsStateV2);
  const resetBreakdownsState = useResetRecoilState(breakdownsState);
  const resetGlobalFiltersState = useResetRecoilState(globalFiltersStateV2);
  const resetDateRangeState = useResetRecoilState(dateRangeState);
  const resetReportName = useResetRecoilState(flowsNameState);
  const resetReportDescription = useResetRecoilState(flowsDescriptionState);
  const resetCustomDateRange = useResetRecoilState(customDateRangeState);
  const resetFunnelConversionWindow = useResetRecoilState(conversionTimeWindowState);
  const resetFunnelConversionFunction = useResetRecoilState(conversionWindowFunctionState);
  const resetGranularityState = useResetRecoilState(granularityState);

  const resetFlowsRowCount = useResetRecoilState(flowsEventRowCountState);
  const resetFlowsEventFilterType = useResetRecoilState(flowsEventFilterTypeState);
  const resetFlowsIncludeEvents = useResetRecoilState(flowsIncludeEventsState);
  const resetFlowsExcludeEvents = useResetRecoilState(flowsExcludeEventsState);
  const resetFlowsExpandedEvents = useResetRecoilState(flowsExpandedEventsListState);

  const resetFlowPvtDimnErrState = useResetRecoilState(flowPrivateDimensionErrorState);

  return () => {
    resetDimensionState();
    resetBreakdownsState();
    resetGlobalFiltersState();
    resetDateRangeState();
    resetReportName();
    resetReportDescription();
    resetCustomDateRange();
    resetFunnelConversionWindow();
    resetFunnelConversionFunction();
    resetGranularityState();
    resetFlowsRowCount();
    resetFlowsEventFilterType();
    resetFlowsIncludeEvents();
    resetFlowsExcludeEvents();
    resetFlowsExpandedEvents();
    resetFlowPvtDimnErrState();
  };
};

export const useResetDerievedDataStatesOnParamChange = () => {
  const resetErrorState = useResetRecoilState(flowsErrorState);
  const resetRunId = useResetRecoilState(flowsRunIdState);
  const resetChartData = useResetRecoilState(flowsChartDataState);
  const resetFlowPvtDimnErrState = useResetRecoilState(flowPrivateDimensionErrorState);

  return () => {
    resetErrorState();
    resetRunId();
    resetChartData();
    resetFlowPvtDimnErrState();
  };
};

export const useResetAllFlowsAtomValues = () => {
  const resetDimensionState = useResetRecoilState(dimensionsStateV2);
  const resetGlobalFiltersState = useResetRecoilState(globalFiltersStateV2);
  const resetGranularityState = useResetRecoilState(granularityState);
  const resetDateRangeState = useResetRecoilState(dateRangeState);
  const resetReportNameState = useResetRecoilState(flowsNameState);
  const resetReportDescriptionState = useResetRecoilState(flowsDescriptionState);
  const resetConversionWindow = useResetRecoilState(conversionTimeWindowState);
  const resetConversionFunction = useResetRecoilState(conversionWindowFunctionState);

  const resetFlowsRowCount = useResetRecoilState(flowsEventRowCountState);
  const resetFlowsSelectedtEventFilterType = useResetRecoilState(flowsEventFilterTypeState);
  const resetFlowsEventsToExclude = useResetRecoilState(flowsExcludeEventsState);
  const resetFlowsEventsToInclude = useResetRecoilState(flowsIncludeEventsState);
  const resetFlowsExapandedEvents = useResetRecoilState(flowsExpandedEventsListState);

  const resetDerivedFlowStates = useResetDerievedDataStatesOnParamChange();

  return () => {
    resetDimensionState();
    resetGlobalFiltersState();
    resetGranularityState();
    resetDateRangeState();
    resetReportNameState();
    resetReportDescriptionState();
    resetDerivedFlowStates();
    resetConversionWindow();
    resetConversionFunction();
    resetFlowsRowCount();
    resetFlowsSelectedtEventFilterType();
    resetFlowsEventsToExclude();
    resetFlowsEventsToInclude();
    resetFlowsExapandedEvents();
  };
};

import { Filter, LogicalOperatorType } from '@src/client/helpers/reports/types';
import { PlatformItemType } from '@src/client/lib/api/types/request';
import { PluralSupportedTimeUnits } from '@src/client/lib/api/types/response';
import React, { ReactNode } from 'react';

export enum ItemStatus {
  REQUESTED = 'REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  ERROR = 'ERROR',
}

export interface GetUserCohortRequest {
  limit: number;
  offset: number;
  currentUserOnly: boolean;
  show_all_status: boolean;
}

export enum CohortType {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export interface CohortSummaryResponse {
  itemId: string;
  itemExternalId: string;
  configId: string;
  name: string;
  description: string;
  status: ItemStatus;
  createdAt: number;
  createdBy: string;
  cohortType: CohortType;
  cohortCount: number;
}

export interface DeleteItemResponse {
  itemExternalId: string;
  success: boolean;
}

export interface DownloadItemResponse {
  itemExternalId: string;
  url: string;
}

export interface CreateCohortRequest {
  itemName: string;
  itemDescription: string;
  itemScope: string;
  cohortType: CohortType;
  userIdValues?: string;
  params: any;
}

export interface CreateCohortResponse {
  itemId: string;
  itemExternalId: string;
  configId: string;
  projectId: string;
  tenantId: string;
}

export enum ItemScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export interface UpdateCohortRequest {
  projectId?: string;
  tenantId?: string;
  itemExternalId: string;
  itemName: string;
  itemDescription: string;
  itemScope: ItemScope;
  params: { [key: string]: any };
}

export interface CohortItemResponse {
  itemId: string;
  configId: string;
  itemExternalId: string;
  name: string;
  description: string;
  status: string;
  createdAt: number;
  createdBy: string;
  itemType: PlatformItemType;
  cohortType: CohortType;
  params: { [key: string]: any };
}

export enum PropertyType {
  EVENT = 'EVENT',
  COHORT = 'COHORT',
  USER = 'USER',
}

export enum SupportedMathAggregation {
  total = 'total',
  distinctCount = 'distinct_count',
}

export interface AggregationInfo {
  math?: SupportedMathAggregation;
  propertyType?: 'event_property';
  property?: string;
  property_name?: string;
}

export interface PropertyListType {
  value: string;
  label: string;
  dataType: string;
  cached: boolean;
}

export enum SupportedFilterConditions {
  greatherThanEqualTo = 'gte',
  greaterThan = 'gt',
  lessThan = 'lt',
  lessThanEqualTo = 'lte',
  equal = 'eq_variable',
  notEqual = 'neq',
  array = 'array',
}

export interface CohortRowFilter {
  condition: SupportedFilterConditions;
  value: number | string[];
}

export interface CohortRowTimeWindow {
  type: string;
  value?: number;
  unit?: PluralSupportedTimeUnits.DAYS | PluralSupportedTimeUnits.MONTHS | PluralSupportedTimeUnits.WEEKS;
  from_timestamp?: string;
  to_timestamp?: string;
}

export interface EventTypeRow {
  type: PropertyType;
  property: string;
  aggregation?: AggregationInfo;
  filter?: CohortRowFilter;
  time_window?: CohortRowTimeWindow;
  first_time_filter?: boolean;
  event_filters?: Filter[];
  dnd?: boolean;
}

export enum TimeWindowTypes {
  Last = 'Last',
  Fixed = 'Fixed',
  Since = 'Since',
}

export interface CohortFilterRow {
  filters: EventTypeRow[];
  operator: LogicalOperatorType;
}

export interface CohortSummary extends CohortSummaryResponse {
  key: React.Key;
}
export interface CohortMetaState {
  cohortTitle: string;
  cohortDescription: string;
  count?: number;
  firstTen?: string;
}

export interface FilterRowState {
  operator: LogicalOperatorType;
  event: EventTypeRow;
  key: React.Key;
}

export interface GroupFilter {
  filters: FilterRowState[];
  operator: LogicalOperatorType;
  key: React.Key;
}

export interface CohortCreateDropdownOptionType {
  label: string;
  value: string;
  type: PropertyType;
  icon: ReactNode;
}

export interface CohortCountResponse {
  tenantId: string;
  workspaceId: string;
  count: number;
}

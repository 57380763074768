import { useLayoutTopPaddingBasedOnUiVersion } from '@src/client/hooks';
import { cn } from '@src/client/ui-library/utils';
import { PropsWithChildren, ReactNode } from 'react';

interface Props extends PropsWithChildren {
  icon: ReactNode;
  title: string;
  withBorder?: boolean;
  extraElement?: ReactNode;
  containerClassname?: string;
}
export default function ListPageHeaderSection({
  icon,
  title,
  children,
  withBorder = true,
  extraElement,
  containerClassname,
}: Props) {
  const topPaddingClassname = useLayoutTopPaddingBasedOnUiVersion();

  return (
    <div
      className={cn(
        'flex flex-col w-full justify-between items-center px-3 py-5 rounded-t-xl sticky z-10 bg-background',
        topPaddingClassname,
        withBorder ? 'border border-border border-b-0' : '',
        containerClassname ?? '',
      )}
    >
      <div className="flex items-center justify-between w-full space-x-12">
        <div className="flex items-center">
          <div className="border border-border p-2 mr-2 rounded-lg bg-grayBlue-100 dark:bg-grayBlue-600">{icon}</div>
          <p className="font-bold text-lg ml-1 text-foreground-selected">{title}</p>
        </div>
        <div className="flex-1">{children}</div>
      </div>
      {extraElement ?? null}
    </div>
  );
}

import { CustomCompareStateType } from '@src/client/components/filters-and-selectors/compare-selector/atoms';
import { FilterOperatorEnums } from '@src/client/components/filters-and-selectors/global-property-filter/constants';
import { DimensionType, GroupByType, PluralSupportedTimeUnits } from '@src/client/lib/api/types/response';
import { FlowsExpandedEvent } from '@src/client/modules/flows/types';
import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';

import {
  ChartType,
  DateRangeEnum,
  FlowsEventFilterType,
  FunnelCountTypes,
  FunnelMeasureTypes,
  FunnelTrendStep,
  FunnelTrendType,
  FunnelType,
  GranularityEnum,
  PropertyAttribution,
  RetentionSubtypeEnum,
  ViewMode,
} from './constants';

export interface CompareOption {
  value: string;
  label: string;
  disabled: boolean;
  children?: CompareOption[];
}
export interface DateType {
  key: string;
  fromDate?: string;
  toDate?: string;
}

export interface Formula {
  name: string;
  expression: string;
}

export interface Breakdown {
  property: string | string[];
  label?: string;
  value?: string;
  group_by_type?: GroupByType;
  bucket_size?: number;
  include_null_users?: boolean;
  type: DimensionType; // DimensionType.PROPERTY | DimensionType.COHORT | DimensionType.TIMESTAMP
}

export enum FilterPropertyType {
  EVENT = 'EVENT',
  COHORT = 'COHORT',
}

export interface Filter {
  map?: string;
  key: number;
  property: string | string[];
  resource_type: FilterPropertyType;
  operator: FilterOperatorEnums;
  values: string | string[];
}

export interface AggregateProperties {
  aggregate: 'avg | sum | unique | percentile | min | max';
  column_name: string;
  percentile_value?: string; // this comes only if aggregate_properties.aggregate = "percentile"
}

export interface Dimension {
  alias: string;
  first_time_filter: boolean;
  name: string;
  dimension_label?: string;
  math: string[];
  filter: Filter[];
  'aggregate-on'?: string; // this comes only if math = "aggregate"
  // below steps_* properties are only applicable in case of flows report
  steps_before?: number;
  steps_after?: number;
  resource_type: DimensionType;
}

export interface FunnelStep {
  event?: string | undefined;
  step_label?: string;
  filter?: Filter[];
  alias: string;
  first_time_filter?: boolean;
  compare?: FunnelCompareStep[];
  is_excluded?: boolean;
}

export interface FunnelCompareStep {
  event: string | undefined;
  step_label: string;
  filter: Filter[];
  alias: string;
  first_time_filter: boolean;
  is_excluded?: boolean;
}

export interface QueryBuilder {
  dateRange: DateRangeEnum;
  breakdowns?: Breakdown[];
  'global-filters'?: Filter[];
  sinceDateRange?: [Dayjs, Dayjs] | [];
  customDateRange?: [Dayjs, Dayjs] | [];
  compare?: string[];
  granularity: GranularityEnum;
  customCompareData?: CustomCompareStateType;
}

export interface InsightQueryBuilder extends QueryBuilder {
  viewMode: ViewMode;
  insightTitle: string;
  insightDescription: string;
  chartType: ChartType;
  formulas: Formula[];
  dimensions: Dimension[];
}

export interface RetentionQueryBuilder extends QueryBuilder {
  retentionTitle: string;
  retentionDescription: string;
  dimensions: Dimension[];
  reportSubtype: RetentionSubtypeEnum;
  onAndAfterEnabled: boolean;
}

export interface FunnelQueryBuilder extends QueryBuilder {
  viewMode: ViewMode;
  funnelTitle: string;
  funnelDescription: string;
  function: FunnelCountTypes;
  measure_time_window: FunnelConversionTimeWindow;
  steps: FunnelStep[];
  measured_as: FunnelMeasureTypes;
  funnelType?: FunnelType;
  funnelTrendType?: FunnelTrendType;
  funnelTrendStep?: FunnelTrendStep;
  propertyAttribution: PropertyAttribution;
  isFunnelAnyOrder: boolean;
}

export interface FlowQueryBuilder extends QueryBuilder {
  flowTitle: string;
  flowDescription: string;
  function: FunnelCountTypes;
  measure_time_window: FunnelConversionTimeWindow;
  steps: Dimension[];
  flowsExpandedEvents: FlowsExpandedEvent[];
  flowsEventFilterType?: FlowsEventFilterType;
  flowsIncludeEvents?: string[];
  flowsExcludeEvents?: string[];
  flowsRowCount: number;
}

export interface EventsAndCohorts {
  label: string;
  value: string;
  icon?: ReactNode;
  resource_type: DimensionType;
}

export interface PropertiesAndCohorts {
  label: string;
  value: string;
  type: DimensionType;
  propertyCached: boolean;
  icon?: ReactNode;
}

export interface DimensionMathOption {
  value: string;
  label: string;
  children?: DimensionMathOption[];
}

export interface AggregatedProperties {
  aggregate: string;
  column_name: string;
  percentile_value?: number;
}

export interface DataVizRow extends Record<string, unknown> {
  key: string;
  uniqueKey: string;
  color: string;
  dateTs?: number;
}

export interface FunnelDataVizRow extends DataVizRow {
  avg_time_from_first_step: number;
  avg_time_from_prev_step: number;
  event: string;
  events: string[];
  percent_from_first_step: number;
  percent_from_prev_step: number;
  total: number;
  totalConv: number;
}

export interface LineChartDataPoint {
  date: number;
  value: number | null;
  compareDate?: number;
}

export interface LineChartData {
  series: string;
  color: string;
  key: string;
  data: Array<LineChartDataPoint>;
}

export interface FlattenLineChartData extends LineChartDataPoint {
  series: string;
  color: string;
  key: string;
}

export interface GenericChartData {
  key: string;
  value: number;
  color: string;
}

export type ChartData = GenericChartData[] | LineChartData[];

export interface DataVizRowAndChart {
  rows: DataVizRow[];
  chartData: ChartData;
}

export interface FunnelConversionTimeWindow {
  number: number;
  unit: PluralSupportedTimeUnits;
}

export interface FunnelBarGraphData {
  avg_time_from_first_step: number;
  avg_time_from_prev_step: number;
  event: string;
  percent_from_first_step: number;
  percent_from_prev_step: number | null;
  step_index: string;
  total: number;
  color: string;
  breakdown?: string;
  key: string;
  dataRowKey: string;
}

export type FunnelChartData = FunnelBarGraphData[] | LineChartData[];
export interface FunnelDataVizRowAndChart {
  rows: FunnelDataVizRow[];
  chartData: FunnelChartData;
}

export interface FormattedFiltersConfig {
  key: number;
  resource_type: string;
  property_name: string | string[]; // string[] in case of global filters
  operator: string;
  property_values: string | string[];
  property_value_dt: 'array' | 'string';
  property_map?: string;
}

export interface FormattedFiltersForAPI {
  mapping: string;
  config: FormattedFiltersConfig[];
}

export interface FunnelEventCompareMetadata {
  funnels_combinations: string[];
  breakdowns: string | string[];
}

export interface TimestampProps {
  date_range_type: DateRangeEnum;
  granularity: GranularityEnum;
  from_timestamp: string;
  to_timestamp: string;
}

export type LogicalOperatorType = 'and' | 'or';

import { isLengthyArray } from '@src/client/lib/utils';
import { uniq } from 'lodash-es';
import { atom, selector } from 'recoil';

import { dimensionsStateV2 } from '../../components/filters-and-selectors/dimension-filter/state';
import { FlowsEventFilterType } from '../../helpers/reports/constants';
import { SankeyChartData } from './types';
import { DROPOFF_EVENT_NAME, OTHER_EVENT_NAME } from './utils';

export const flowsNameState = atom<string>({
  key: 'flowsNameState',
  default: '',
});

export const flowsDescriptionState = atom<string>({
  key: 'flowsDescriptionState',
  default: '',
});

export const isFlowsLoadingState = atom<boolean>({
  key: 'isFlowsLoadingState',
  default: false,
});

export const flowsRunIdState = atom<string>({
  key: 'flowsRunIdState',
  default: undefined,
});

export const flowsErrorState = atom<Error | unknown>({
  key: 'flowsErrorState',
  default: null,
});

export const flowsEventRowCountState = atom<number>({
  key: 'flowsEventRowCountState',
  default: 5,
});

export const flowsEventFilterTypeState = atom<FlowsEventFilterType>({
  key: 'flowsEventFilterTypeStateV2',
  default: undefined,
});

export const flowsExcludeEventsState = atom<string[]>({
  key: 'flowsExcludeEventsStateV2',
  default: [],
});

export const flowsIncludeEventsState = atom<string[]>({
  key: 'flowsIncludeEventsStateV2',
  default: [],
});

export const flowsChartDataState = atom<SankeyChartData | null>({
  key: 'flowsChartDataState',
  default: null,
});

export const flowPrivateDimensionErrorState = atom<Error | unknown>({
  key: 'flowPrivateDimensionErrorState',
  default: null,
});

export const maxNodeValueState = selector({
  key: 'nodeWithMaxValueState',
  get: ({ get }) => {
    const chartData = get(flowsChartDataState);
    if (!chartData) return undefined;
    const { nodes } = chartData;
    if (!isLengthyArray(nodes)) return undefined;
    let maxValue = 0;
    nodes.forEach((n) => {
      if (n.total > maxValue) maxValue = n.total;
    });

    return maxValue;
  },
});

export const flowsEventFilterButtonLabelState = selector({
  key: 'flowsEventFilterButtonLabelState',
  get: ({ get }) => {
    const selectedEventFilterType = get(flowsEventFilterTypeState);
    const excludeEventsList = get(flowsExcludeEventsState);
    const includeEventsList = get(flowsIncludeEventsState);

    switch (selectedEventFilterType) {
      case FlowsEventFilterType.EXCLUDE_EVENTS:
        return !isLengthyArray(excludeEventsList) ? 'Filter Events' : `${excludeEventsList!.length} events excluded`;

      case FlowsEventFilterType.INCLUDE_EVENTS:
        return !isLengthyArray(includeEventsList) ? 'Filter Events' : `${includeEventsList!.length} events included`;

      default:
        return 'Filter Events';
    }
  },
});

export const sankeyAnchorNodeStepOrderState = selector({
  key: 'sankeyAnchorNodeStepOrderState',
  get: ({ get }) => {
    const dimensions = get(dimensionsStateV2);
    const anchorSteps: number[] = [];
    dimensions.forEach((d, index) => {
      if (index === 0) {
        anchorSteps.push(d.steps_before ?? 0);
      } else {
        anchorSteps.push(anchorSteps[anchorSteps.length - 1] + (dimensions[index - 1].steps_after ?? 0));
      }
    });
    return anchorSteps;
  },
});

export const sankeyStepsBreakpointsState = selector({
  key: 'sankeyStepsBreakpointsStateV2',
  get: ({ get }) => {
    const dimensions = get(dimensionsStateV2);
    const breakpoints: number[] = [];

    if (dimensions.length > 1) {
      let lastbreakpoint = 0;
      dimensions.forEach((dimension) => {
        const dimensionStepCount = (dimension.steps_after ?? 0) + (dimension.steps_before ?? 0) + 1;
        breakpoints.push(dimensionStepCount + lastbreakpoint);
        lastbreakpoint += dimensionStepCount;
      });
    }
    return breakpoints;
  },
});

export const getFlowsNodeEvents = selector({
  key: 'getFlowsNodeEvents',
  get: ({ get }) => {
    const flowsChartData = get(flowsChartDataState);
    if (flowsChartData) {
      const { nodes } = flowsChartData;
      const eventNames = nodes
        .filter((node) => node.event !== DROPOFF_EVENT_NAME && node.event !== OTHER_EVENT_NAME)
        .map((node) => node.event.split('|')[0]);

      return uniq(eventNames);
    }
    return null;
  },
});

// ML Platform
import { CohortEndpoints } from '@src/client/modules/cohorts/api/endpoints';
import {
  AddUserRequest,
  BasicUserInfo,
  CreateTeamRequest,
  TeamInfoDetailed,
  UpdateUserRoleRequest,
} from '@src/client/modules/uam/types';

import {
  AlertEndpoints,
  CustomEventsEndpoints,
  DashboardEndpoints,
  EventEndpoints,
  LlmEndpoints,
  ReportEndpoints,
  TeamEndpoints,
  UserEndpoints,
  UserInfoEndpoints,
} from '../constants';
import {
  AlertExtId,
  CreateAlertRequest,
  CreateAlertResponse,
  CreateCohortRequest,
  CreateCustomEventRequest,
  CreateDashboardRequest,
  CreateEventRequest,
  CreateReportRequest,
  FetchEventsRequest,
  LlmCreateReportRequest,
  LlmReportFeedback,
  RequestByItemExtId,
  UpdateAlertRequest,
  UpdateCohortRequest,
  UpdateDashboardRequest,
  UpdateEventRequest,
  UpdateLastActiveWorkspaceId,
  UpdateReportRequest,
} from '../types/request';
import {
  CreateCohortResponse,
  CreateDashboardResponse,
  CreateEventResponse,
  CreateItemResponse,
  CustomEventDTO,
  DeleteDashboardResponse,
  DeleteItemResponse,
  DuplicateItemResponse,
  FetchEventsResponse,
  LlmCreateReportResponse,
  LoginWithAuthCodeResponse,
  LoginWithIdTokenResponse,
  SignupResponseUser,
} from '../types/response';
import { fetchEndpoint, PlatformService, UserGovService } from '../utils';

export const signup = async (
  name: string,
  email: string,
  profileUrl: string | null,
  organization: string,
  contact: string,
) => {
  const data: SignupResponseUser = await fetchEndpoint(
    UserEndpoints.signup,
    {},
    {},
    { name, email, profileUrl, organization, contact },
    'POST',
    UserGovService,
  );
  return data;
};

export const validateAndLogin = async () => {
  const data: any = await fetchEndpoint(UserEndpoints.authorize, {}, {}, {}, 'POST', UserGovService);
  return data;
};

export const login = async (authCode: string) => {
  const data: LoginWithAuthCodeResponse = await fetchEndpoint(
    UserEndpoints.login,
    {},
    {},
    { authCode, authType: 'GOOGLE_OAUTH2' },
    'POST',
    UserGovService,
  );
  return data;
};

export const getTenants = async () => {
  const data: LoginWithAuthCodeResponse = await fetchEndpoint(UserEndpoints.tenants, {}, {}, {}, 'GET', UserGovService);
  return data;
};

export const loginWithIdToken = async (idToken: string) => {
  const data: LoginWithIdTokenResponse = await fetchEndpoint(
    UserEndpoints.login,
    {},
    {},
    { idToken, authType: 'MS_OAUTH2' },
    'POST',
    UserGovService,
  );
  return data;
};

export const refreshGoogleLogin = async (refreshToken: string) => {
  const data: LoginWithAuthCodeResponse = await fetchEndpoint(
    UserEndpoints.refresh,
    {},
    {},
    { refreshToken, authType: 'GOOGLE_OAUTH2' },
    'POST',
    UserGovService,
  );
  return data;
};

export const createReport = async (payload: CreateReportRequest) => {
  const data: CreateItemResponse = await fetchEndpoint(
    ReportEndpoints.createReport,
    {},
    {},
    payload,
    'POST',
    PlatformService,
  );
  return data;
};

export const duplicateReport = async (payload: RequestByItemExtId) => {
  const data: DuplicateItemResponse = await fetchEndpoint(
    ReportEndpoints.duplicateReport,
    {},
    { item_ext_id: payload.itemExtId },
    {},
    'POST',
    PlatformService,
  );
  return data;
};

export const createCohort = async (payload: CreateCohortRequest) => {
  const data: CreateCohortResponse = await fetchEndpoint(
    CohortEndpoints.createCohort,
    {},
    {},
    payload,
    'POST',
    PlatformService,
  );
  return data;
};

export const updateReport = async (payload: UpdateReportRequest) => {
  const data: CreateItemResponse = await fetchEndpoint(
    ReportEndpoints.initiateRun,
    {},
    {},
    payload,
    'POST',
    PlatformService,
  );
  return { ...data, traceId: payload.traceId };
};

export const fetchLlmReport = async (payload: LlmCreateReportRequest) => {
  const data: LlmCreateReportResponse = await fetchEndpoint(
    LlmEndpoints.llmItem,
    {},
    {},
    payload,
    'POST',
    PlatformService,
  );
  return { ...data };
};

export const updateCohort = async (payload: UpdateCohortRequest) => {
  const data: CreateCohortResponse = await fetchEndpoint(
    CohortEndpoints.cohortByItemExtId,
    { item_ext_id: payload.itemExternalId },
    {},
    payload,
    'POST',
    PlatformService,
  );
  return data;
};

export const deleteReport = async (payload: RequestByItemExtId) => {
  const data: DeleteItemResponse = await fetchEndpoint(
    ReportEndpoints.insightByItemExtId,
    { item_ext_id: payload.itemExtId },
    {},
    {},
    'DELETE',
    PlatformService,
  );
  return data;
};

export const deleteCohort = async (payload: RequestByItemExtId) => {
  const data: DeleteItemResponse = await fetchEndpoint(
    CohortEndpoints.cohortByItemExtId,
    { item_ext_id: payload.itemExtId },
    {},
    {},
    'DELETE',
    PlatformService,
  );
  return data;
};

export const saveDashboard = async (payload: CreateDashboardRequest) => {
  const data: CreateDashboardResponse = await fetchEndpoint(
    DashboardEndpoints.createDashboard,
    {},
    {},
    payload,
    'POST',
    PlatformService,
  );
  return data;
};
export const updateDashboard = async (payload: UpdateDashboardRequest) => {
  const data: CreateDashboardResponse = await fetchEndpoint(
    DashboardEndpoints.updateDashboard,
    { item_ext_id: payload.itemExtId },
    {},
    payload,
    'PUT',
    PlatformService,
  );
  return data;
};

export const deleteDashboard = async (payload: RequestByItemExtId) => {
  const data: DeleteDashboardResponse = await fetchEndpoint(
    DashboardEndpoints.dashboardByItemExtId,
    { item_ext_id: payload.itemExtId },
    {},
    {},
    'DELETE',
    PlatformService,
  );
  return data;
};

export const createEvent = async (payload: CreateEventRequest) => {
  const data: CreateEventResponse = await fetchEndpoint(
    EventEndpoints.eventByItem,
    {},
    {},
    payload,
    'POST',
    PlatformService,
  );
  return data;
};

export const updateEvent = async (payload: UpdateEventRequest) => {
  const data: CreateEventResponse = await fetchEndpoint(
    EventEndpoints.eventByItemExtId,
    { item_ext_id: payload.itemExternalId },
    {},
    payload,
    'PUT',
    PlatformService,
  );
  return data;
};

export const fetchEventList = async (payload: FetchEventsRequest): Promise<FetchEventsResponse> => {
  const data: FetchEventsResponse = await fetchEndpoint(EventEndpoints.fetch, {}, {}, payload, 'POST', PlatformService);
  return { ...data, traceId: payload.traceId };
};

export const updateLastActiveWorkspaceId = async (payload: UpdateLastActiveWorkspaceId) => {
  const data: any = await fetchEndpoint(
    UserEndpoints.updateLastActiveWorkspaceId,
    {},
    { lastActiveWorkspaceId: payload.lastActiveWorkspaceId },
    {},
    'PUT',
    UserGovService,
  );
  return data;
};

export const createAlert = async (payload: CreateAlertRequest) => {
  const data: CreateAlertResponse = await fetchEndpoint(
    AlertEndpoints.addAlert,
    {},
    {},
    payload,
    'POST',
    PlatformService,
  );
  return data;
};

export const updateAlertAnalysisDashboardReportList = async (payload: UpdateAlertRequest) => {
  const data = await fetchEndpoint(
    AlertEndpoints.updateDashboardReportList,
    { item_ext_id: payload.alertItemExtId },
    {},
    { dashboardReportList: payload.dashboardReportList },
    'PUT',
    PlatformService,
  );
  return data;
};

export const deleteCustomEvent = async (payload: RequestByItemExtId) => {
  const data: DeleteDashboardResponse = await fetchEndpoint(
    CustomEventsEndpoints.eventByItemExtId,
    { item_ext_id: payload.itemExtId },
    {},
    {},
    'DELETE',
    PlatformService,
  );
  return data;
};

export const createCustomEvent = async (payload: CreateCustomEventRequest) => {
  const data: CustomEventDTO = await fetchEndpoint(
    CustomEventsEndpoints.create,
    {},
    {},
    payload,
    'POST',
    PlatformService,
  );
  return data;
};

export const updateCustomEvent = async (payload: { data: CreateCustomEventRequest; itemExternalId: string }) => {
  const data: CreateEventResponse = await fetchEndpoint(
    CustomEventsEndpoints.eventByItemExtId,
    { item_ext_id: payload.itemExternalId },
    {},
    payload.data,
    'PUT',
    PlatformService,
  );
  return data;
};

export const updateLlmReportFeedback = async (payload: LlmReportFeedback) =>
  fetchEndpoint(LlmEndpoints.updateLlmFeedback, '', '', payload, 'POST', PlatformService);

export const deleteAlert = async (payload: AlertExtId) => {
  const data: boolean = await fetchEndpoint(
    AlertEndpoints.byItemExtId,
    { item_ext_id: payload.alertItemExtId },
    {},
    {},
    'DELETE',
    PlatformService,
  );
  return data;
};

export const updateUserRole = async (payload: UpdateUserRoleRequest) => {
  const data = await fetchEndpoint(
    UserInfoEndpoints.role,
    { user_id: payload.userId },
    { updated_role: payload.updatedRoleName },
    {},
    'PUT',
    UserGovService,
  );
  return data;
};

export const createTeam = async (payload: CreateTeamRequest) => {
  const data: TeamInfoDetailed = await fetchEndpoint(TeamEndpoints.base, {}, {}, payload, 'POST', UserGovService);
  return data;
};

export const deleteTeam = async (payload: { teamId: string }) =>
  fetchEndpoint(TeamEndpoints.team, { team_id: payload.teamId }, {}, {}, 'DELETE', UserGovService);

export const deleteUser = async (payload: { userId: string }) => {
  fetchEndpoint(UserInfoEndpoints.deleteUser, { user_id: payload.userId }, {}, {}, 'DELETE', UserGovService);
};

export const addUser = async (payload: AddUserRequest) => {
  const data: BasicUserInfo = await fetchEndpoint(UserInfoEndpoints.base, {}, {}, payload, 'POST', UserGovService);
  return data;
};

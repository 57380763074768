import { atom, useRecoilValue } from 'recoil';

import { AnimatedPILoader } from '../../ui-library/loaders';

export const globalPageLoaderState = atom<boolean>({
  key: 'globalPageLoaderState',
  default: false,
});

export default function GlobalPageLoader() {
  const showGloablPageLoader = useRecoilValue(globalPageLoaderState);

  if (showGloablPageLoader)
    return (
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[2000] bg-foreground-secondary/60">
        {/* <SpinLoader /> */}
        <AnimatedPILoader />
      </div>
    );

  return null;
}

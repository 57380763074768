import { SelectOptionsType } from '@src/client/ui-library/select';
import { atom } from 'recoil';

export const createdByFilterState = atom<SelectOptionsType[]>({
  key: 'createdByFilterState',
  default: [],
});

export const searchCreatedByFilterState = atom<SelectOptionsType[]>({
  key: 'searchCreatedByFilterState',
  default: [],
});

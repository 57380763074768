import { DateRangeEnum, GranularityEnum } from '@src/client/helpers/reports/constants';
import dayjs, { Dayjs } from 'dayjs';
import { atom, selector, useResetRecoilState } from 'recoil';

import { granularityState } from '../granularity-selector';

export const dateRangeState = atom<DateRangeEnum>({
  key: 'dateRangeState',
  default: DateRangeEnum.SEVEN_DAYS,
});

// ["". ""] -> tuple.. 1st value is from and 2nd value is to
export const customDateRangeState = atom<[Dayjs, Dayjs] | []>({
  key: 'customDateRangeState',
  default: [],
});

export const isTodayInDateRange = selector({
  key: 'isTodayInDateRange',
  get: ({ get }) => {
    const dateRange = get(dateRangeState);
    const customDateRange = get(customDateRangeState);
    const granularity = get(granularityState);
    return !(
      dateRange === DateRangeEnum.YESTERDAY ||
      (granularity === GranularityEnum.DAY &&
        dateRange === DateRangeEnum.CUSTOM &&
        !customDateRange[1]?.isSame(dayjs(), 'day')) ||
      (granularity === GranularityEnum.WEEK &&
        dateRange === DateRangeEnum.CUSTOM &&
        !customDateRange[1]?.isSame(dayjs(), 'week')) ||
      (granularity === GranularityEnum.MONTH &&
        dateRange === DateRangeEnum.CUSTOM &&
        !customDateRange[1]?.isSame(dayjs(), 'month'))
    );
  },
});

/* Helpers */
export const useResetDateRangeState = () => {
  const resetDateRangeState = useResetRecoilState(dateRangeState);
  const resetCustomDateRange = useResetRecoilState(customDateRangeState);

  return () => {
    resetDateRangeState();
    resetCustomDateRange();
  };
};

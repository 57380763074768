import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { CloseIcon, FirstTimeFilterIcon, InlineFiltersIcon } from '@src/client/ui-library/icons/ReportIcons';
import { Popover, PopoverContent, PopoverTrigger } from '@src/client/ui-library/popover';
import { useMemo, useState } from 'react';

import { cn, HoverableClassNames } from '../../../ui-library/utils';

export enum EventFilterRowDropdownKeys {
  FILTER = 'FILTER',
  FIRST_TIME_FILTER = 'FIRST_TIME_FILTER',
  REMOVE = 'REMOVE',
}

interface Props {
  firstTimeFilter: boolean;
  onClick: (key: EventFilterRowDropdownKeys) => void;
}

export default function EventFilterRowDropdownMenu({ firstTimeFilter, onClick }: Props) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const dropdownOptions = useMemo(() => {
    const options = [
      {
        label: 'Filter',
        key: EventFilterRowDropdownKeys.FILTER,
        icon: <InlineFiltersIcon />,
      },
      {
        label: 'Remove',
        key: EventFilterRowDropdownKeys.REMOVE,
        icon: <CloseIcon />,
      },
    ];

    if (!firstTimeFilter) {
      options.splice(1, 0, {
        label: 'First Time Filter',
        key: EventFilterRowDropdownKeys.FIRST_TIME_FILTER,
        icon: <FirstTimeFilterIcon />,
      });
    }

    return options;
  }, [firstTimeFilter]);

  const handleOptionClick = (key: EventFilterRowDropdownKeys) => {
    onClick(key);
    setPopoverOpen(false);
  };

  return (
    <Popover open={popoverOpen} onOpenChange={(open) => setPopoverOpen(open)}>
      <PopoverTrigger className="p-1 border-0 rounded-full" onClick={() => setPopoverOpen(!popoverOpen)}>
        <DotsHorizontalIcon />
      </PopoverTrigger>
      <PopoverContent className="w-auto flex flex-col space-y-2 px-1 py-2">
        {dropdownOptions.map((option) => (
          <div
            key={option.key}
            tabIndex={0}
            role="button"
            className={cn('inline-flex items-center space-x-2 rounded-lg p-1 text-xs', HoverableClassNames)}
            onClick={() => handleOptionClick(option.key)}
            onKeyDown={() => handleOptionClick(option.key)}
          >
            {option.icon}
            <span>{option.label}</span>
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
}

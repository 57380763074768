import { ErrorTags, EventNames } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { Workspace } from '@src/client/lib/api/types/response';
import { SVGProps, useState } from 'react';
import { useMutation } from 'react-query';

import { Button } from '../../../ui-library/button';
import { Input } from '../../../ui-library/input';
import { createWorkspace } from '../api/mutations';

function WorkspaceIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="99" height="99" rx="8" fill="#EAECF0" />
      <rect x="18" y="17" width="28" height="28" fill="#D0D5DD" />
      <rect x="52" y="51" width="28" height="28" rx="14" fill="#D0D5DD" />
      <path d="M18 79L32 65L46 51L46 79L18 79Z" fill="#D0D5DD" />
      <path d="M66 17L80 45H52L66 17Z" fill="#D0D5DD" />
    </svg>
  );
}

export function CreateWorkspaceSection({
  isLimitExhausted,
  isOnboardingFlow,
  onWsChange,
}: {
  isLimitExhausted?: boolean;
  isOnboardingFlow: boolean;
  onWsChange: (ws: Workspace, redirect: string) => void;
}) {
  const [org, setOrg] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [error, setError] = useState<string>('');

  const createWorkspaceMutation = useMutation(createWorkspace, {
    onSuccess: (ws) => {
      Tracker.trackEvent(EventNames.CREATE_WORKSPACE_SUCCESS);
      localStorage.setItem(`percept_wallet_${ws.workspaceId}_signup_bonus_dialog_viewed`, 'true');
      onWsChange({ id: ws.workspaceId, name: ws.workspaceName, tenantId: org }, `/${ws.workspaceId}/sdk-integrations`);
    },
    onError: (err: Error) => {
      setError(`${err.name}:${err.message}`);
      Tracker.trackError(err, ErrorTags.WORKSPACE_CREATION_ERROR);
      Tracker.trackEvent(
        EventNames.CREATE_WORKSPACE_FAILED,
        {},
        {
          createWsErrorName: err.name,
          createWsErrormessage: err.message,
        },
      );
    },
  });

  const handleChange = (value: string) => {
    if (value.trim().length >= 0 && /^[a-zA-Z\s]*$/.test(value)) {
      setOrg(value);
    }
  };

  const contactUsOnMail = (
    <a href="mailto:support@perceptinsight.com" className="text-gray-400 font-semibold">
      Contact us at support@perceptinsight.com
    </a>
  );

  return (
    <div className="flex flex-col px-8 items-start m-auto">
      <span className="mt-8 text-foreground text-sm">Enter workspace name</span>
      <div className="flex w-full items-center gap-4">
        <Input
          className="mt-1 flex-grow placeholder:text-gray-500 border-gray-300 bg-white"
          placeholder="your-workspace-name"
          value={org}
          disabled={isLimitExhausted}
          onChange={(e) => handleChange(e.target.value)}
        />
        <p className="font-bold text-foreground">.perceptinsight</p>
      </div>
      <div className="my-6 flex gap-4 w-full items-center">
        <div className="relative">
          <WorkspaceIcon className="h-[70px] w-[70px]" />
          {logoUrl && (
            <img src={logoUrl} alt="workspace-logo" className="h-[70px] w-[70px] rounded-lg absolute top-0" />
          )}
        </div>
        <Input
          className="mt-1 w-full placeholder:text-gray-500 border-gray-300 bg-white"
          placeholder="logo url"
          value={logoUrl}
          disabled={isLimitExhausted}
          onChange={(e) => setLogoUrl(e.target.value)}
        />
      </div>
      <Button
        disabled={org === '' || createWorkspaceMutation.isLoading || isLimitExhausted}
        onClick={() => {
          Tracker.trackEvent(EventNames.CREATE_WORKSPACE_CLICKED);
          createWorkspaceMutation.mutateAsync({
            workspaceName: org.toLowerCase(),
            isOnboarding: isOnboardingFlow,
            logoUrl,
          });
        }}
        loading={createWorkspaceMutation.isLoading}
        className="w-full"
      >
        Create Workspace →
      </Button>
      {error && (
        <div className="text-warning mt-2 text-center w-full">
          Unable to create workspace.
          <br />
          {contactUsOnMail}
        </div>
      )}
      {isLimitExhausted && (
        <div className="text-danger mt-2 text-center w-full">
          Workspace limit exhausted.
          <br />
          {contactUsOnMail}
        </div>
      )}
    </div>
  );
}

import React, { Children, useRef } from 'react';
import { MenuListProps } from 'react-select';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

export function VirtualMenuList(props: MenuListProps) {
  const { children, maxHeight, focusedOption, selectProps } = props;

  const itemCount = Children.count(children);

  const virtuosoRef = useRef<VirtuosoHandle>(null);
  // const listRef = useRef<HTMLElement | null>(null);

  // useEffect(() => {
  //   if (!focusedOption) return;
  //   const index = children?.findIndex(
  //     (child) => child.props.label === focusedOption.label
  //   );
  //   virtuosoRef.current?.scrollToIndex(index);
  // }, [focusedOption]);

  return Array.isArray(children) ? (
    <Virtuoso
      ref={virtuosoRef}
      // scrollerRef={scrollerRef}
      totalCount={itemCount}
      itemContent={(index) => children[index]}
      style={{
        height: maxHeight,
      }}
    />
  ) : null;
}

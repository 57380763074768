import { CustomSvgIconDarkModeStroke, CustomSvgIconLightModeStroke } from '@src/client/ui-library/icons/v3/constants';
import { useTheme } from '@src/client/ui-library/theme-provider';
import { LucideProps } from 'lucide-react';
import dynamicIconImports from 'lucide-react/dynamicIconImports';
import { lazy, Suspense, useMemo } from 'react';

interface IconProps extends Omit<LucideProps, 'ref'> {
  name: keyof typeof dynamicIconImports;
}

const fallback = <div style={{ background: 'transparent', width: 24, height: 24 }} />;

export default function LucidIconWrapper({ stroke, name, ...props }: IconProps) {
  const { theme } = useTheme();
  const strokeColor = stroke || (theme === 'light' ? CustomSvgIconLightModeStroke : CustomSvgIconDarkModeStroke);
  const LucideIcon = useMemo(() => lazy(dynamicIconImports[name]), [name]);
  return (
    <Suspense fallback={fallback}>
      <LucideIcon stroke={strokeColor} {...props} />
    </Suspense>
  );
}

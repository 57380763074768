/* eslint-disable no-restricted-globals */
import { ChartType } from '@src/client/helpers/reports/constants';
import { LineChartData } from '@src/client/helpers/reports/types';
import { TimeseriesDataApiRes } from '@src/client/lib/api/types/response';
import { formatLargeNumber, isLengthyArray } from '@src/client/lib/utils';
import { ReportType } from '@src/client/modules/alerts/types';
import { Tooltip } from '@src/client/ui-library/tooltip';
import { isNumber } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import LucidIconWrapper from '../lucid-icon-wrapper';
import { MetricCardType, MetricDataApiRes } from './constants';
import { useMetricDataFetcher } from './hooks';
import MetricCardWrapper from './MetricCardWrapper';
import MetricLineChartRenderer from './MetricLineChartRenderer';
import { getLineChartDataForMetricCard, getMetricChangePercentage } from './utils';

interface Props {
  virtualAllEventId: string;
  metricType: MetricCardType;
  chartDisplayType?: 'area' | 'line';
  noDataInfoMessage?: string;
}

function ValueChangeRenderer({ original, compare }: { original: TimeseriesDataApiRes; compare: TimeseriesDataApiRes }) {
  const change = getMetricChangePercentage(original, compare);

  if (!change) return null;

  return (
    <span className={`text-md font-bold ${change > 0 ? 'text-success' : 'text-danger'}`}>
      {change > 0 ? '+' : ''} {change}%
    </span>
  );
}

export default function MetricCard({
  virtualAllEventId,
  metricType,
  chartDisplayType = 'line',
  noDataInfoMessage,
}: Props) {
  const { data, isLoading, error } = useMetricDataFetcher(
    metricType,
    ReportType.INSIGHT,
    ChartType.LINE,
    virtualAllEventId,
  );

  const lineChartData: LineChartData[] = useMemo(
    () => getLineChartDataForMetricCard(data as MetricDataApiRes, metricType),
    [data?.original], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (
      !isNumber((data as MetricDataApiRes)?.original['$All Events-A'].total) ||
      (data as MetricDataApiRes)?.original['$All Events-A'].total === 0
    ) {
      localStorage.removeItem(`pi_Home_${location.pathname}_${metricType}`);
      localStorage.removeItem(`pi_Home_${location.pathname}_metric_ts`);
    }
  }, [data, metricType]);

  if (error) return null;

  const value = isNumber((data as MetricDataApiRes)?.original['$All Events-A'].total)
    ? formatLargeNumber((data as MetricDataApiRes)?.original['$All Events-A'].total)
    : 'NA';

  return (
    <MetricCardWrapper isLoading={isLoading}>
      <div className="flex space-x-2 items-center">
        <div>
          <h4 className="font-medium text-sm">{metricType}</h4>
          {data ? (
            <>
              <div className="inline-flex items-center space-x-2">
                <h3 className="font-bold text-h5 text-foreground-selected">{value}</h3>
                {noDataInfoMessage && value === 'NA' ? (
                  <Tooltip content={<p>{noDataInfoMessage}</p>}>
                    <div>
                      <LucidIconWrapper name="info" />
                    </div>
                  </Tooltip>
                ) : null}
              </div>
              <ValueChangeRenderer
                original={(data as MetricDataApiRes).original['$All Events-A']}
                compare={(data as MetricDataApiRes).compare['$All Events-A']}
              />
            </>
          ) : null}
        </div>
        {isLengthyArray(lineChartData) ? (
          <div className="flex-1">
            <MetricLineChartRenderer lineChartData={lineChartData} chartDisplayType={chartDisplayType} />
          </div>
        ) : null}
      </div>
    </MetricCardWrapper>
  );
}

import { fetchEndpoint, UserGovService } from '@src/client/lib/api/utils';

import { CreateInvoicePayload, Invoice, PaginatedTransactions, PaymentInitResponse } from '../types';
import { PaymentEndPoints, RewardsEndpoints } from './endpoints';

export const getWalletBalance = async () => {
  const data: number = await fetchEndpoint(RewardsEndpoints.getBalance, {}, {}, {}, 'GET', UserGovService);
  return data;
};

export const getWalletLedger = async (pageIndex: number) => {
  const data: PaginatedTransactions = await fetchEndpoint(
    RewardsEndpoints.getledger,
    {},
    { page: pageIndex },
    {},
    'GET',
    UserGovService,
  );
  return data;
};

export const createInvoice = async (payload: CreateInvoicePayload): Promise<Invoice> => {
  const data: Invoice = await fetchEndpoint(
    PaymentEndPoints.createInvoice,
    {},
    {},
    { ...payload },
    'POST',
    UserGovService,
  );
  return data;
};

export const payInvoice = async (invoiceId: string): Promise<PaymentInitResponse> => {
  const data: PaymentInitResponse = await fetchEndpoint(
    PaymentEndPoints.payInvoice,
    { invoice_id: invoiceId },
    {},
    {},
    'POST',
    UserGovService,
  );
  return data;
};

import * as qs from 'querystringify';

import ApiClient from './client';
import { APIEndpoints } from './constants';

export const UserGovService = new ApiClient('/api/governance');
export const PlatformService = new ApiClient('/api/metadata');
export const ExperimentService = new ApiClient('/api/experiment');

export const fetchAPIResponse = <R>(
  url: APIEndpoints,
  request: RequestInit,
  method = 'POST',
  API: ApiClient = PlatformService,
): Promise<R> =>
  (method === 'POST' || method === 'PATCH' || method === 'PUT' || method === 'DELETE'
    ? API.createRequest({
        path: url,
        request,
      })({})
    : API.createRequest({
        path: url,
        request: {
          method: 'GET',
        },
      })({})) as Promise<R>;

export const generateCompleteApiEndpoint = (url: APIEndpoints, pathParams: any = {}, queryParams: any = {}) => {
  Object.keys(pathParams).forEach((key: string) => {
    // eslint-disable-next-line no-param-reassign
    url = url.replace(`{${key}}`, pathParams[key]) as any;
  });
  const queryString = qs.stringify(queryParams, true);
  url = (url + queryString) as any; // eslint-disable-line no-param-reassign
  return url;
};

export const fetchEndpoint = async <R>(
  url: APIEndpoints,
  pathParams: any = {},
  queryParams: any = {},
  bodyParams: any = {},
  method = 'POST',
  API: ApiClient = PlatformService,
  customHeaders: HeadersInit = {},
  contentType: 'application/json' | 'multipart/form-data' | 'text/plain' = 'application/json',
): Promise<R> => {
  url = generateCompleteApiEndpoint(url, pathParams, queryParams); // eslint-disable-line no-param-reassign

  const request: RequestInit = {
    method,
    body: contentType === 'application/json' ? JSON.stringify(bodyParams) : bodyParams,
    headers: {
      Accept: 'application/json',
      ...(contentType === 'application/json' ? { 'Content-Type': contentType } : {}),
      ...customHeaders,
    },
  };

  const data = (await fetchAPIResponse(url, request, method, API)) as Promise<R>;
  if ((data as unknown as any).code === 403) {
    throw new Error((data as unknown as any).message);
  }

  return data;
};

export const getUseQueryOptions = (retry: boolean | number = 0, staleTime: number = 60 * 1000, enabled = true) => ({
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  staleTime,
  retry,
  enabled,
  fetchPolicy: 'no-cache',
});

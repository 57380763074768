import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';

export const handleWorkerError = (error: ErrorEvent, errorTag: ErrorTags) => {
  Tracker.trackError(error.error as any, errorTag, {
    workerErrorMessage: error.message,
    workerErrorFile: error.filename,
    workerErrorLine: error.lineno,
    workerErrorCol: error.colno,
  });
};

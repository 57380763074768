import { formatDate } from '@src/client/helpers/reports/dataUtils';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { granularityState } from '../../../filters-and-selectors/granularity-selector';

export default function XAxis({
  xScale,
  height,
  ticksCount,
}: {
  xScale: d3.ScaleTime<number, number, never>;
  height: number;
  ticksCount: number;
}) {
  const granularity = useRecoilValue(granularityState);

  const ticks = useMemo(
    () =>
      xScale.ticks(Math.min(ticksCount, 8)).map((value) => ({
        value,
        xOffset: xScale(value),
      })),
    [xScale, ticksCount],
  );

  const formatLabel = useCallback((val: any) => formatDate(granularity, val), [granularity]);

  return (
    <g>
      {ticks.map(({ value, xOffset }, index) => (
        // if (index === 0) return null;
        <g key={value.getTime()} transform={`translate(${xOffset}, 0)`}>
          {/* <line y1={height - 5} y2={height - 10} stroke="currentColor" /> */}
          <text y={height} textAnchor="middle" fill="currentColor" className="text-[9px] font-medium">
            {formatLabel(value)}
          </text>
        </g>
      ))}
    </g>
  );
}

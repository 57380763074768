import { ChevronDownIcon } from '@radix-ui/react-icons';
import { Button } from '@src/client/ui-library/button';
import { useRecoilState } from 'recoil';

import { groupOperatorState } from '../recoil/atoms';

export default function GroupOperatorSelector() {
  const [groupOperator, setGroupOperator] = useRecoilState(groupOperatorState);

  return (
    <div className="relative ">
      <div className="w-full h-px bg-border" />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Button
          variant="outline"
          className="py-1 px-2 text-primary bg-primary-light dark:bg-foreground-secondary dark:text-white"
          onClick={() => {
            if (groupOperator === 'and') setGroupOperator('or');
            else setGroupOperator('and');
          }}
        >
          {groupOperator === 'and' ? 'And' : 'Or'}
          <ChevronDownIcon className="ml-1" />
        </Button>
      </div>
    </div>
  );
}

import DAUCard from '@src/client/components/common-metrics-card/DAUCard';
import EventVolumeCard from '@src/client/components/common-metrics-card/EventVolumeCard';
import NewUserCard from '@src/client/components/common-metrics-card/NewUserCard';
import LucidIconWrapper from '@src/client/components/lucid-icon-wrapper';
import { useVirtualAllEventInfo } from '@src/client/helpers/reports/hooks';
import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { SpinLoader } from '@src/client/ui-library/loaders';
import { cn } from '@src/client/ui-library/utils';
import { Link } from 'react-router-dom';

export default function MetricOverview({ isHomepage = true }: { isHomepage?: boolean }) {
  const { virtualAllEvent, isLoading, error } = useVirtualAllEventInfo();
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();

  if (error) return null;

  if (!isLoading && !virtualAllEvent) return null;
  return (
    <div className={cn('w-full', isHomepage ? 'px-4 border-b border-border' : '')}>
      {isLoading ? (
        <SpinLoader size="small" />
      ) : (
        <>
          {/* {isHomepage && <DefaultDashboardBanner />} */}
          {isHomepage && (
            <p className="font-medium text-sm mb-4 text-foreground">Your product insights at a glance (7 days)</p>
          )}

          <div className="flex justify-start gap-x-4 relative h-[150px] pb-8 w-full">
            <div className="h-full flex-1 max-w-lg border border-border rounded-md p-2">
              <NewUserCard virtualAllEventId={virtualAllEvent!.itemExternalId} />
            </div>
            <div className="h-full flex-1 max-w-lg border border-border rounded-md p-2">
              <DAUCard virtualAllEventId={virtualAllEvent!.itemExternalId} />
            </div>
            <div className="h-full flex-1 max-w-lg border border-border rounded-md p-2">
              <EventVolumeCard virtualAllEventId={virtualAllEvent!.itemExternalId} />
            </div>
            {isHomepage && (
              <Link
                to={getLinkWithWorkspace('insights-hub')}
                className="h-full flex-1 flex space-x-1 items-center cursor-pointer hover:bg-foreground-secondary justify-center max-w-lg border border-border rounded-md p-2"
              >
                <p className="text-sm font-medium">View all</p>
                <LucidIconWrapper name="circle-arrow-right" size={18} />
              </Link>
            )}
          </div>
        </>
      )}
    </div>
  );
}

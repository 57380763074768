import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { cn } from '@src/client/ui-library/utils';
import { Link, useLocation } from 'react-router-dom';

import { SidepanelActiveNavClassNames, SidepanelNavLinkClassNames } from './constants';
import LinkInfoTooltip from './LinkInfoTooltip';

export default function SidepanelNavLink({
  isNavigationSidepanelOpen,
  path,
  text,
  icon,
  reloadPage = false,
  tooltipContent,
  rightNode,
}: {
  isNavigationSidepanelOpen: boolean;
  path: string;
  text: string;
  icon: React.ReactNode;
  reloadPage?: boolean;
  tooltipContent?: React.ReactNode;
  rightNode?: React.ReactNode;
}) {
  const { pathname: currentPathname } = useLocation();
  const isActivePath = currentPathname.includes(path);
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const classNamesToApply = cn(
    SidepanelNavLinkClassNames,
    isActivePath ? SidepanelActiveNavClassNames : '',
    isNavigationSidepanelOpen ? 'p-2' : 'px-1 py-2 justify-center',
  );

  return (
    <LinkInfoTooltip content={tooltipContent ?? null} side="right" sideOffset={12}>
      <Link
        to={getLinkWithWorkspace(path)}
        className={classNamesToApply}
        reloadDocument={reloadPage}
        data-testid={`${path.replaceAll('/', '-')}-nav-link`}
      >
        {isNavigationSidepanelOpen ? (
          <div className="flex w-full items-center justify-between">
            <div className="flex space-x-2 items-center">
              {icon}
              <p className="text-sm font-medium">{text}</p>
            </div>
            {rightNode || null}
          </div>
        ) : (
          icon
        )}
      </Link>
    </LinkInfoTooltip>
  );
}

import { ReportTypeDisplyInfo, ReportTypes } from '@src/client/helpers/constants';
import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { Link } from 'react-router-dom';

import CreateReportPills from './CreateReportPills';

export default function NoReportsPlaceholder({ showReportPills }: { showReportPills?: boolean }) {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <img src="/images/v2/no-reports-created.svg" width={300} alt="No Reports illustration" />
      <div className="text-center items-center px-3 py-5">
        <p className="font-bold text-md">No reports created</p>
        <p className="m-1 text-sm">Click on the below options to create your first report</p>
      </div>
      <div className="flex gap-4 w-full justify-center">
        {showReportPills ? (
          <div className="w-full">
            <CreateReportPills />
          </div>
        ) : (
          ReportTypes.map((reportType) => {
            const data = ReportTypeDisplyInfo[reportType];
            return (
              <Link to={getLinkWithWorkspace(data.path)} key={data.path}>
                <div className="p-3.5 rounded-lg w-[225px] h-[180px] flex flex-col" style={{ background: data.color }}>
                  <div className="flex items-center mb-3">
                    <img src={data.imageSrc} className="w-8 h-8 mr-2" alt={`${data.title} icon`} />
                    <p className="text-md font-semibold text-gray-950">{data.title}</p>
                  </div>
                  <p className="text-xs font-normal text-gray-700 mb-4">{data.description}</p>
                  <div className="flex-grow" />
                  <p className="text-primary-dark text-sm font-semibold">{`+ Create ${data.title}`}</p>
                </div>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
}

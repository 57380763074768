import { CustomEventEmitterDetails, CustomEventEmitterKeys } from '@src/client/lib/event-emitter/types';
import AlertDialog from '@src/client/ui-library/alert-dialog';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';

export default function GlobalAlert() {
  const [alertDetails, setAlertDetails] = useState<CustomEventEmitterDetails>();

  useEffect(() => {
    const handleAlert = (event: CustomEvent<CustomEventEmitterDetails>) => {
      const details: CustomEventEmitterDetails = event.detail;

      if (!isEmpty(details) && (details.title || details.description)) {
        setAlertDetails(details);
      }
    };

    window.addEventListener(CustomEventEmitterKeys.showSessionExpiredDialog, handleAlert as EventListener);

    return () =>
      window.removeEventListener(CustomEventEmitterKeys.showSessionExpiredDialog, handleAlert as EventListener);
  }, []);

  const resetAlertDetails = () => setAlertDetails(undefined);

  const handleCancel = () => {
    if (alertDetails?.onCancel) {
      alertDetails.onCancel();
    }
    resetAlertDetails();
  };

  const handleOk = () => {
    if (alertDetails?.onOk) {
      alertDetails.onOk();
    }
    resetAlertDetails();
  };

  if (!alertDetails) return null;

  return (
    <AlertDialog
      open={!!alertDetails}
      title={alertDetails.title}
      description={alertDetails.description}
      onCancel={alertDetails?.onCancel ? handleCancel : undefined}
      onContinue={handleOk}
    />
  );
}

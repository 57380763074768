export const organisations: string[] = [
  'Apple',
  'Amazon',
  'Microsoft',
  'Google',
  'Visa',
  'JPMorgan Chase',
  'Walmart',
  'Bank of America',
  'United Health',
  'Intel',
  'Verizon',
  'Disney',
  'Cisco',
  'Pfizer',
  'Boeing',
  'Home Depot',
  'Mastercard',
  'Chevron',
  'Adobe',
  'Wells Fargo',
  'Coca-Cola',
  'Comcast',
  'Oracle',
  'IBM',
  'Anthem',
  'Cigna',
  '3M',
  'Target',
  'Goldman Sachs',
  'Union Pacific',
  'Netflix',
  'GE',
  'Amex',
  'Honeywell',
  'Dow',
  'Caterpillar',
  'Morgan Stanley',
  'Abbott',
  'DuPont',
  'Philip Morris',
  'Salesforce',
  'GM',
  'Merck',
  'Nike',
  'Costco',
  'Lowe',
  'Medtronic',
  'ExxonMobil',
  'CVS',
  'Capital One',
  'PayPal',
  'Raytheon',
  'Lockheed Martin',
  'Accenture',
  'Booking',
  'Delta',
  'Amgen',
  'Ford',
  'Charter',
  'Bristol Myers Squibb',
  'Home Depot',
  'PepsiCo',
  'Lilly',
  'Starbucks',
  'Gilead',
  'Micron',
  'Marriott',
  'FCStone',
  'Thermo Fisher',
  'American Tower',
  'FIS',
  'Altria',
  'TJX',
  'Danaher',
  'Texas Instruments',
  'UPS',
  'Salesforce',
  'Stryker',
  'EOG Resources',
  'Exelon',
  'AmerisourceBergen',
  'Berkshire Hathaway',
  'Micron',
  'Fox',
  'ConocoPhillips',
  'Teva',
  'CenturyLink',
  'Colgate',
  'Synchrony',
  'State Street',
  'Northrop Grumman',
  'General Dynamics',
  'MetLife',
  'Progressive',
  'Qualcomm',
  'eBay',
  'Marathon Petroleum',
  'Prudential',
  'HCA Healthcare',
  'PACCAR',
  'Ecolab',
  'DXC Technology',
  'PSEG',
  'Marsh and McLennan',
  'Anadarko',
  'Lincoln National',
  'Kraft Heinz',
  'Travelers',
  'AutoNation',
  'Emerson',
  'Halliburton',
  'Humana',
  'Coke',
  'Kroger',
  'Molson Coors',
  'Sempra',
  'Eversource',
  'Conagra',
  'Devon Energy',
  'Principal',
  'Estée Lauder',
  'HP',
  'Reliance Industries',
  'Tata Consultancy Services',
  'HDFC Bank',
  'Infosys',
  'ICICI Bank',
  'Hindustan Unilever',
  'State Bank of India',
  'Bharti Airtel',
  'Maruti Suzuki',
  'Wipro',
  'HCL Technologies',
  'ITC Limited',
  'Kotak Mahindra Bank',
  'Larsen and Toubro',
  'Axis Bank',
  'Asian Paints',
  'Bajaj Finserv',
  'Nestle India',
  'Sun Pharmaceutical',
  'Mahindra and Mahindra',
  'Tech Mahindra',
  'Titan Company',
  'Bajaj Finance',
  'UltraTech Cement',
  'Power Grid Corporation',
  'NTPC',
  'IndusInd Bank',
  'JSW Steel',
  'Hindalco Industries',
  'Tata Steel',
  'Cipla',
  "Dr. Reddy's Laboratories",
  'Bharti Infratel',
  'Bharat Petroleum',
  'Grasim Industries',
  'Tata Motors',
  'Hero MotoCorp',
  'Eicher Motors',
  'Oil and Natural Gas Corporation',
  'Adani Ports',
  'Coal India',
  'GAIL',
  'Britannia Industries',
  'Shree Cement',
  'Adani Enterprises',
  'Zee Entertainment',
  'Godrej Consumer Products',
  'Ambuja Cements',
  'Pidilite Industries',
  'Bosch',
  'Siemens India',
  'Dabur India',
  'Marico',
  'Avenue Supermarts',
  "Divi's Laboratories",
  'ICICI Prudential',
  'HDFC Life',
  'United Spirits',
  'Havells India',
  'Motherson Sumi Systems',
  'Apollo Hospitals',
  'Bajaj Auto',
  'TVS Motor Company',
  'Bank of Baroda',
  'Punjab National Bank',
  'Tata Power',
  'LIC Housing Finance',
  'Adani Green Energy',
  'Adani Power',
  'Piramal Enterprises',
  'GMR Infrastructure',
  'DLF',
  'SRF',
  'Indian Oil Corporation',
  'Tata Elxsi',
  'Mindtree',
  'Mphasis',
  'Lupin',
  'Voltas',
  'Torrent Pharmaceuticals',
  'Muthoot Finance',
  'Crompton Greaves',
  'Page Industries',
  'Biocon',
  'Glenmark Pharmaceuticals',
  'ABB India',
  'Exide Industries',
  'Canara Bank',
  'Union Bank of India',
  'Bank of India',
  'IDFC First Bank',
  'Jubilant FoodWorks',
  'Petronet LNG',
  'NHPC',
  'REC Limited',
  'Indraprastha Gas',
  'Container Corporation',
  'Coforge',
  'L&T Technology Services',
  'BHEL',
  'NMDC',
  'BEL',
  'Ashok Leyland',
  'AU Small Finance Bank',
  'IRCTC',
  'IDBI Bank',
  'Federal Bank',
  'RBL Bank',
  'Trent',
  'PNB Housing Finance',
  'SBI Life Insurance',
  'SBI Cards and Payment Services',
  'Vodafone Idea',
  'Bandhan Bank',
  'LIC',
  'Godrej Properties',
  'MRF',
  'Aditya Birla Capital',
  'Tata Chemicals',
  'ICICI Lombard',
  'Hindustan Zinc',
  'PVR',
  'Max Financial Services',
  'Tata Communications',
  'Adani Transmission',
  'Jindal Steel and Power',
  'Schaeffler India',
  'Amara Raja Batteries',
  'Quess Corp',
  'ICRA',
  'Minda Industries',
  'Aarti Industries',
  'Aegis Logistics',
  'AIA Engineering',
  'Ajanta Pharma',
  'Alembic Pharmaceuticals',
  'Alkem Laboratories',
  'Allcargo Logistics',
  'Amara Raja Batteries',
  'Apollo Tyres',
  'Arvind',
  'Asahi India Glass',
  'Ashok Leyland',
  'Atul',
  'Balkrishna Industries',
  'Balrampur Chini Mills',
  'Bank of Maharashtra',
  'BASF India',
  'Bata India',
  'Berger Paints',
  'Bharat Dynamics',
  'Bharat Electronics',
  'Bharat Forge',
  'Bharat Rasayan',
  'Birlasoft',
  'Blue Dart Express',
  'Blue Star',
  'Borosil Renewables',
  'BSE',
  'CAMS',
  'Capri Global Capital',
  'Castrol India',
  'CEAT',
  'Central Bank of India',
  'Century Plyboards',
  'Century Textiles',
  'CESC',
  'Chambal Fertilisers',
  'Cholamandalam',
  'City Union Bank',
  'Clariant Chemicals',
  'CMS Info Systems',
  'Cochin Shipyard',
  'Coffee Day Enterprises',
  'Colgate Palmolive',
  'Coromandel International',
  'Cosmo Films',
  'CRISIL',
  'Cummins India',
  'Dalmia Bharat',
  'DCB Bank',
  'Deepak Fertilisers',
  'Deepak Nitrite',
  'Delta Corp',
  'Dhanlaxmi Bank',
  'Dixon Technologies',
  'Dr. Lal PathLabs',
  'Dredging Corporation',
  'Edelweiss Financial Services',
  'EID Parry',
  'Electronics Mart India',
  'Engineers India',
  'Equitas Holdings',
  'Eris Lifesciences',
  'Escorts',
  'Fine Organic Industries',
  'Firstsource Solutions',
  'FMCG',
  'Fortis Healthcare',
  'Future Retail',
  'GAIL',
  'Gateway Distriparks',
  'GE Power India',
  'GHCL',
  'GlaxoSmithKline Consumer Healthcare',
  'GMM Pfaudler',
  'Goodyear India',
  'Granules India',
  'Graphite India',
  'GSK Pharma',
  'Gujarat Gas',
  'Gujarat State Fertilizers and Chemicals',
  'Gujarat State Petronet',
  'Gulf Oil Lubricants India',
  'Hatsun Agro Product',
  'Hawkins Cookers',
  'HeidelbergCement India',
  'Hero FinCorp',
  'Hexaware Technologies',
  'Himadri Speciality Chemical',
  'Hindalco Industries',
  'Hindustan Copper',
  'Hindustan Oil Exploration Company',
  'Hindustan Unilever',
  'Hindustan Zinc',
  'HPL Electric and Power',
  'HSIL',
  'HUDCO',
  'IEX',
  'IFB Industries',
  'IIFL Finance',
  'IL&FS Transportation Networks',
  'Indian Bank',
  'Indian Hotels',
  'Indoco Remedies',
  'Indostar Capital Finance',
  'Indraprastha Gas',
  'IndusInd Bank',
  'INEOS Styrolution India',
  'Info Edge',
  'Ingersoll Rand',
  'Intellect Design Arena',
  'Ion Exchange',
  'IRB Infrastructure Developers',
  'ITI',
  'J&K Bank',
  'Jagran Prakashan',
  'Jamna Auto Industries',
  'Jindal Poly Films',
  'JK Cement',
  'JK Lakshmi Cement',
  'JK Paper',
  'JK Tyre and Industries',
  'JM Financial',
  'JSW Energy',
  'Jubilant Ingrevia',
  'Just Dial',
  'Jyothy Labs',
  'Kabra Extrusiontechnik',
  'Kajaria Ceramics',
  'Kalyani Steels',
  'Kansai Nerolac Paints',
  'Karur Vysya Bank',
  'Kaveri Seed Company',
  'KEC International',
  'KEI Industries',
  'Kirloskar Brothers',
  'Kirloskar Oil Engines',
  'KPR Mill',
  'KRBL',
  'L&T Finance Holdings',
  'La Opala RG',
  'Lakshmi Vilas Bank',
  'Laurus Labs',
  'Laxmi Machine Works',
  'Lemon Tree Hotels',
  'LIC Housing Finance',
  'Linde India',
  'Lux Industries',
  'Magma Fincorp',
  'Mahindra CIE Automotive',
  'Mahindra Holidays and Resorts India',
  'Mahindra Lifespace Developers',
  'Majesco',
  'Mangalore Refinery and Petrochemicals',
  'Manpasand Beverages',
  'Marico',
  'Marksans Pharma',
  'MAS Financial Services',
  'Mazagon Dock Shipbuilders',
  'Metropolis Healthcare',
  'Minda Corporation',
  'Mishra Dhatu Nigam',
  'MMTC',
  'MOIL',
  'Mold-Tek Packaging',
  'Monsanto India',
  'Motherson Sumi Systems',
  'Motilal Oswal Financial Services',
  'MphasiS',
  'MRPL',
  'Multi Commodity Exchange of India',
  'NACL Industries',
  'NALCO',
  'Navin Fluorine International',
  'NBCC',
  'NCC',
  'NESCO',
  'Nestle India',
  'Network18 Media and Investments',
  'NLC India',
  'NMDC',
  'NOCIL',
  'NTPC',
  'Oil India',
  'Omkar Speciality Chemicals',
  'OnMobile Global',
  'Oracle Financial Services Software',
  'Orient Cement',
  'Orient Electric',
  'Orient Refractories',
  'Oriental Bank of Commerce',
  'Orissa Minerals Development Company',
  'Oudh Sugar Mills',
  'Page Industries',
  'Panacea Biotec',
  'Parag Milk Foods',
  'Persistent Systems',
  'Petronet LNG',
  'Pfizer',
  'Phillips Carbon Black',
  'Phoenix Mills',
  'Pidilite Industries',
  'PI Industries',
  'PNB Gilts',
  'Polycab India',
  'PPAP Automotive',
  'Prabhat Dairy',
  'Precision Camshafts',
  'Premier Explosives',
  'Prestige Estates Projects',
  'Prism Johnson',
  'PTC India',
  'Quess Corp',
  'Quick Heal Technologies',
  'Radico Khaitan',
  'Radico Khaitan',
  'Rain Industries',
  'Rallis India',
  'Rane Holdings',
  'Rashtriya Chemicals and Fertilizers',
  'Raymond',
  'RBL Bank',
  'Redington India',
  'Reliance Capital',
  'Reliance Infrastructure',
  'Reliance Power',
  'Religare Enterprises',
  'RITES',
  'Rossari Biotech',
  'Ruchi Soya Industries',
  'Sagar Cements',
  'SBI Cards and Payment Services',
  'SBI Life Insurance',
  'Schneider Electric Infrastructure',
  'Sharda Cropchem',
  'Shilpa Medicare',
  'Shipping Corporation of India',
  'Shoppers Stop',
  'Shriram City Union Finance',
  'Shriram Transport Finance',
  'SJVN',
  'SKF India',
  'Solar Industries India',
  'Solara Active Pharma Sciences',
  'Sona BLW Precision Forgings',
  'Southeastern Railway',
  'SpiceJet',
  'SREI Infrastructure Finance',
  'SRF',
  'Sterlite Technologies',
  'Strides Pharma Science',
  'Subros',
  'Sudarshan Chemical Industries',
  'Sunteck Realty',
  'Suprajit Engineering',
  'Supreme Industries',
  'Surya Roshni',
  'Suzlon Energy',
  'Syngene International',
  'Taj GVK Hotels and Resorts',
  'Tamil Nadu Newsprint and Papers',
  'Tata Coffee',
  'Tata Consumer Products',
  'Tata Elxsi',
  'Tata Investment Corporation',
  'Tata Metaliks',
  'Tata Power',
  'Tata Steel',
  'TeamLease Services',
  'Tech Mahindra',
  'Thangamayil Jewellery',
  'The Byke Hospitality',
  'The India Cements',
  'The Phoenix Mills',
  'Thermax',
  'Thyrocare Technologies',
  'Timken India',
  'Tinna Rubber and Infrastructure',
  'Titan Company',
  'Torrent Pharmaceuticals',
  'Trident',
  'Triveni Engineering and Industries',
  'TTK Prestige',
  'TV18 Broadcast',
  'TVS Electronics',
  'UCO Bank',
  'Ujjivan Financial Services',
  'Ujjivan Small Finance Bank',
  'UltraTech Cement',
  'Unichem Laboratories',
  'Union Bank of India',
  'United Breweries',
  'United Spirits',
  'UPL',
  'V-Guard Industries',
  'V-Mart Retail',
  'Varun Beverages',
  'Vascon Engineers',
  'Venkys',
  'Veritas',
  'Vesuvius India',
  'Vindhya Telelinks',
  'Vinyl Chemicals',
  'VIP Industries',
  'Voltamp Transformers',
  'Voltas',
  'VRL Logistics',
  'VST Industries',
  'Walchandnagar Industries',
  'Wendt',
  'Wipro',
  'Wonderla Holidays',
  'Yes Bank',
  'Zee Entertainment Enterprises',
  'Zensar Technologies',
  'Zydus Wellness',
];

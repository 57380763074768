export const workspaceIcons = [
  <svg key="icon-1" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="5" fill="#717BBC" />
    <circle cx="7" cy="18" r="4" transform="rotate(90 7 18)" fill="#293056" />
    <rect x="13" y="13" width="8" height="9" rx="2" fill="#293056" />
    <rect x="3" y="3" width="18" height="8" rx="2" fill="#293056" />
  </svg>,
  <svg key="icon-2" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="5" fill="#717BBC" />
    <path
      d="M14.4277 22C13.7337 22 13.2512 21.3086 13.5056 20.6629C14.4616 18.2358 15.1784 16.9894 16.0709 14.8756C16.4247 14.0378 17.6369 14.0126 17.9898 14.8507L20.4156 20.6119C20.6931 21.2711 20.2091 22 19.4939 22H14.4277Z"
      fill="#293056"
    />
    <rect x="21" y="3" width="8" height="18" rx="2" transform="rotate(90 21 3)" fill="#293056" />
    <circle cx="7" cy="18" r="4" transform="rotate(90 7 18)" fill="#293056" />
  </svg>,
  <svg key="icon-3" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="5" fill="#717BBC" />
    <circle cx="17" cy="7" r="4" transform="rotate(90 17 7)" fill="#293056" />
    <rect x="13" y="13" width="8" height="9" rx="2" fill="#293056" />
    <rect x="3" y="3" width="8" height="19" rx="2" fill="#293056" />
  </svg>,
];

import { components as ReactSelectComponents, MultiValueProps, NoticeProps, OptionProps } from 'react-select';

import { SelectCheckIcon } from '../icons/ReportIcons';
import { Tooltip } from '../tooltip';
import { cn } from '../utils';
import { SelectOptionsType } from './types';

export const highlightInputMatch = (item: string | undefined, keyword: string) => {
  if (!item || !keyword || typeof item !== 'string') return item;
  const lowerCasedInputValue = keyword.toLowerCase();
  const hitIndex = item.toLocaleLowerCase().indexOf(lowerCasedInputValue);
  if (hitIndex === -1) return item;
  const before = item.slice(0, hitIndex);
  const match = item.slice(hitIndex, hitIndex + keyword.length);
  const after = item.slice(hitIndex + keyword.length);
  return (
    <span>
      {before}
      <b>{match}</b>
      {after}
    </span>
  );
};

export function CustomOption({ innerProps, data, isSelected, isDisabled, isFocused, ...props }: OptionProps) {
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = innerProps; // check: https://www.botsplash.com/post/optimize-your-react-select-component-to-smoothly-render-10k-data

  const { inputValue } = props.selectProps;

  return (
    <div
      {...rest}
      className={cn(
        'cursor-default select-none flex w-full justify-between items-center rounded py-1.5 px-2 mb-1 bg-transparent  hover:bg-foreground-secondary',
        isFocused ? 'bg-foreground-secondary' : '',
        isSelected ? 'bg-primary/20' : '',
        isDisabled ? 'cursor-not-allowed' : '',
      )}
    >
      <Tooltip content={(data as any).tooltip ?? (data as any).label}>
        <div className="inline-flex items-center space-x-1 w-full">
          {(data as any).icon ? (data as any).icon : null}
          <p className={`truncate text-sm text-foreground outline-none ${isDisabled ? 'opacity-50' : ''}`}>
            {highlightInputMatch((data as any).label, inputValue)}
          </p>
        </div>
      </Tooltip>
      {isSelected ? <SelectCheckIcon /> : null}
    </div>
  );
}

export function NoOptionsMessageSync({ ...props }: NoticeProps) {
  return <p className="text-xs font-normal text-center">No options found</p>;
}

export function NoOptionsMessageAsync({ ...props }: NoticeProps) {
  return (
    <p className="text-xs text-center">
      {props.selectProps.inputValue.length <= 1 ? 'Start typing to view options' : 'No options found'}
    </p>
  );
}

export function CustomMultiValue({ index, getValue, data, ...props }: MultiValueProps) {
  const maxToShow = 2;
  const values = getValue();
  const overflowCount = Math.max(values.length - maxToShow, 0);

  if (index < maxToShow) {
    return (
      <Tooltip
        content={<p className="text-foreground">{((values[index] as any)?.label as string) ?? ''}</p>}
        side="bottom"
        contentClassname="bg-background border border max-h-[180px] max-w-[520px] overflow-y-scroll"
      >
        <span>
          <ReactSelectComponents.MultiValue index={index} data={data} getValue={getValue} {...props} />
        </span>
      </Tooltip>
    );
  }
  if (index === maxToShow) {
    const overflowValues = values
      .slice(maxToShow)
      .map((value) => (value as SelectOptionsType).label)
      .join(', ');

    return (
      <Tooltip
        content={<p className="text-foreground">{overflowValues}</p>}
        side="bottom"
        contentClassname="bg-background border border max-h-[180px] max-w-[520px] overflow-y-scroll"
      >
        <span>
          <ReactSelectComponents.MultiValue
            index={index}
            getValue={getValue}
            data={{ isFixed: true }}
            {...props}
          >{`+${overflowCount} more`}</ReactSelectComponents.MultiValue>
        </span>
      </Tooltip>
    );
  }
  return null;
}

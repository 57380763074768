import { userInfoState } from '@src/client/recoil/atoms';
import { useRecoilValue } from 'recoil';

const getGreetingTime = () => {
  const currentHour = new Date().getHours();

  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    // Between 12 PM and 5PM
    return 'Good afternoon';
  }
  if (currentHour >= splitEvening) {
    // Between 5PM and Midnight
    return 'Good evening';
  }
  // Between dawn and noon
  return 'Good morning';
};

export default function UserGreeting() {
  const userInfo = useRecoilValue(userInfoState);

  if (!userInfo) return null;

  return <h4 className="px-4  font-bold text-sm mt-4 mb-2 text-foreground-selected">{getGreetingTime()},</h4>;
}

import { PluralSupportedTimeUnits } from '@src/client/lib/api/types/response';
import { atom, useResetRecoilState } from 'recoil';

import { FunnelCountTypes } from '../../../helpers/reports/constants';
import { FunnelConversionTimeWindow } from '../../../helpers/reports/types';

export const conversionTimeWindowState = atom<FunnelConversionTimeWindow>({
  key: 'conversionTimeWindowState',
  default: {
    number: 1,
    unit: PluralSupportedTimeUnits.DAYS,
  },
});

export const conversionWindowFunctionState = atom<FunnelCountTypes>({
  key: 'conversionWindowFunctionState',
  default: FunnelCountTypes.UNIQUES,
});

/** Helpers */
export const useResetConversionWindowValues = () => {
  const resetConversionTimeWindow = useResetRecoilState(conversionTimeWindowState);
  const resetConversionWindowFunction = useResetRecoilState(conversionWindowFunctionState);

  return () => {
    resetConversionTimeWindow();
    resetConversionWindowFunction();
  };
};

/* tslint:disable:max-classes-per-file */

/* eslint-disable max-classes-per-file */
export type ApiErrorType =
  | 'auth'
  | 'client'
  | 'server'
  | 'network'
  | 'session'
  | 'app_version'
  | 'unprocessable_entity';

export class ApiError extends Error {
  type: ApiErrorType;

  status: number | null;

  statusText?: string;

  details: Promise<string>;

  public static getMessage(type: ApiErrorType): string {
    switch (type) {
      case 'auth':
        return 'Not Authorised';
      case 'client':
        return 'Request Error';
      case 'server':
        return 'Server Error';
      case 'network':
        return 'Network Error';
      case 'session':
        return 'Invalid Session';
      case 'app_version':
        return 'Invalid App Version';
      case 'unprocessable_entity':
        return 'Unprocessable Entity';
      default:
        return 'Error';
    }
  }

  constructor(type: ApiErrorType, response: Response | Error) {
    super(ApiError.getMessage(type));
    this.type = type;
    this.status = response instanceof Response ? response.status : null;
    this.statusText = response instanceof Response ? response.statusText : undefined;
    this.details = response instanceof Response ? response.text() : Promise.resolve(response.message);
  }
}

export class AppVersionError extends ApiError {
  constructor(response: Response) {
    super('app_version', response);
  }
}

export class SessionError extends ApiError {
  constructor(response: Response) {
    super('session', response);
  }
}

export class AuthError extends ApiError {
  constructor(response: Response) {
    super('auth', response);
  }
}

export class ClientError extends ApiError {
  constructor(response: Response) {
    super('client', response);
  }
}

export class ServerError extends ApiError {
  constructor(response: Response) {
    super('server', response);
  }
}

export class NetworkError extends ApiError {
  constructor(error: Error) {
    super('network', error);
  }
}

export class UnprocessableEntityError extends ApiError {
  constructor(response: Response) {
    super('unprocessable_entity', response);
  }
}

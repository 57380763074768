/* eslint-disable react/no-children-prop */
import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import ActiveWorkspaceNavigator from '../components/common-router-components/ActiveWorkspaceNavigator';
import ProtectedRoute from '../components/common-router-components/ProtectedRoute';
import UnauthorizedAccess from '../components/common-router-components/UnauthorizedAccess';
import HomeScreen from '../modules/home';
import CreateNewWorkspace from '../modules/login/CreateNewWorkspace';
import Welcome from '../modules/login/Welcome';
import { ALL_ROUTES } from './data';

const NoMatch = React.lazy(() => import('@src/client/components/common-router-components/404'));
const Login = React.lazy(() => import('@src/client/modules/login/Login'));
const Unsubscribe = React.lazy(() => import('@src/client/modules/unsubscribe/Unsubscribe'));

const ACCESS_SPECIFIC_ROUTES = ALL_ROUTES.map((routeData) => {
  const hasSubroutes = Array.isArray(routeData.subRoutes) && routeData.subRoutes.length > 0;
  const baseRoute = hasSubroutes ? <Outlet /> : routeData.component;
  return (
    <Route
      path={`${routeData.path}`}
      key={routeData.path}
      element={
        <ProtectedRoute
          children={<UnauthorizedAccess children={baseRoute} routeAccessSelector={routeData.selector} />}
        />
      }
    >
      {hasSubroutes ? (
        <>
          <Route
            index
            key={routeData.path}
            element={
              <ProtectedRoute
                children={
                  <UnauthorizedAccess children={routeData.component} routeAccessSelector={routeData.selector} />
                }
              />
            }
          />
          {routeData.subRoutes?.map((subRoute) => (
            <Route
              path={subRoute.path}
              key={routeData.path + subRoute.path}
              element={
                <ProtectedRoute
                  children={
                    <UnauthorizedAccess children={subRoute.component} routeAccessSelector={routeData.selector} />
                  }
                />
              }
            />
          ))}
          <Route path="*" element={<NoMatch />} />
        </>
      ) : null}
    </Route>
  );
});

function Router() {
  return (
    <Routes>
      <Route path="/" element={<ActiveWorkspaceNavigator />} />
      <Route path="/login" element={<Login />} />
      <Route path="/unsubscribe" element={<Unsubscribe />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/create-workspace" element={<CreateNewWorkspace />} />
      <Route path=":workspaceId" element={<Outlet />}>
        <Route
          index
          path="home"
          element={
            <ProtectedRoute>
              <UnauthorizedAccess routeAccessSelector="query_run">
                <HomeScreen />
              </UnauthorizedAccess>
            </ProtectedRoute>
          }
        />
        <Route path="" element={<NoMatch />} />
        {ACCESS_SPECIFIC_ROUTES}
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default Router;

import { INITIAL_POLLING_INTERVAL } from '@src/client/helpers/reports/constants';
import { ErrorTags, EventNames, EventProperty } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { getReportRunData, getReportUsers, getRunByRunId } from '@src/client/lib/api/queries/common';
import { GetReportUsersRequest } from '@src/client/lib/api/types/request';
import { DimensionType, GetReportUsersRes } from '@src/client/lib/api/types/response';
import { isLengthyArray } from '@src/client/lib/utils';
import { PageType } from '@src/client/routes/types';
import { useToast } from '@src/client/ui-library/toast/use-toast';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { validBreakdownsState } from '../filters-and-selectors/breakdown-selector/state';
import { isDateCompareEnabledSelector } from '../filters-and-selectors/compare-selector/atoms';
import { dimensionsStateV2 } from '../filters-and-selectors/dimension-filter/state';
import { formulasState } from '../filters-and-selectors/forrmula-creator/state';
import {
  reportuserViewDataCanFetchMoreState,
  reportuserViewDataOffsetState,
  reportUserViewDataState,
  reportUserViewErrorState,
  reportUserViewLoadingState,
  showUserViewAtom,
  userViewFunnelStepMetaInfoState,
  userViewInsightInfoState,
} from './state';
import { ViewUsersProps } from './types';
import { DEFAULT_REPORT_USER_LIST_FETCH_SIZE, getApiPayloadForUserListApi } from './utils';

export const useResetReportUserViewData = () => {
  const resetUserViewFunnelStepData = useResetRecoilState(userViewFunnelStepMetaInfoState);
  const resetUserData = useResetRecoilState(reportUserViewDataState);
  const resetOffset = useResetRecoilState(reportuserViewDataOffsetState);
  const resetErrorState = useResetRecoilState(reportUserViewErrorState);
  const resetLoadingState = useResetRecoilState(reportUserViewLoadingState);
  const resetCanLoadMore = useResetRecoilState(reportuserViewDataCanFetchMoreState);

  return () => {
    resetUserData();
    resetUserViewFunnelStepData();
    resetOffset();
    resetErrorState();
    resetLoadingState();
    resetCanLoadMore();
  };
};

export const useReportUserConfigFetcher = () => {
  const showUsersViewInfo = useRecoilValue(showUserViewAtom);
  const userStepMetaInfo = useRecoilValue(userViewFunnelStepMetaInfoState); // userStep is mandatory for funnel report type
  const userViewInsightInfo = useRecoilValue(userViewInsightInfoState);
  const [searchParams] = useSearchParams();
  const [configId, setConfigId] = useState(searchParams.get('configId'));
  const { toast } = useToast();
  const [runId, setRunId] = useState<string>();
  const [dataOutputPath, setDataOutputPath] = useState<string>();
  const [refetchInterval, setRefetchInterval] = useState<number | false | undefined>(INITIAL_POLLING_INTERVAL);
  const [runDataIsLoading, setRunDataIsLoading] = useState(false);
  const [userData, setUserData] = useRecoilState(reportUserViewDataState);
  const setuserListFetchError = useSetRecoilState(reportUserViewErrorState);
  const [isLoading, setIsLoading] = useRecoilState(reportUserViewLoadingState);
  const currentPage = useRecoilValue(reportuserViewDataOffsetState);
  const showUsersView = useRecoilValue(showUserViewAtom);
  const setCanFetchMore = useSetRecoilState(reportuserViewDataCanFetchMoreState);
  const isDateCompareEnabled = useRecoilValue(isDateCompareEnabledSelector);

  const runStatusResponse = useQuery([runId!], () => getRunByRunId(runId), {
    enabled: runId != null,
    refetchIntervalInBackground: false,
    refetchInterval,
    refetchOnWindowFocus: false,
  });

  const createUserDataRunIdReq = useMutation(getReportUsers, {
    onSuccess: (res) => {
      setRunId(res.runId);
    },
    onError: (err: Error) => {
      setuserListFetchError(err);
      Tracker.trackError(err, ErrorTags.FETCH_REPORT_USERS_CONFIG_ERROR);
      toast({ variant: 'danger', title: 'Error fetching users' });
      setIsLoading(false);
    },
  });

  const getRunDataRequest = useMutation(getReportRunData, {
    onSuccess: (response: GetReportUsersRes) => {
      if (isLengthyArray(response.original)) {
        const userList = [...userData, ...response.original];
        setUserData(userList.sort((a, b) => new Date(b.max_time).getTime() - new Date(a.max_time).getTime()));
      }
      setCanFetchMore(
        isLengthyArray(response.original) && response.original.length === DEFAULT_REPORT_USER_LIST_FETCH_SIZE,
      );
      // setCanFetchMore(
      //   isLengthyArray(response.original) &&
      //     (currentPage + 1) * DEFAULT_REPORT_USER_LIST_FETCH_SIZE < (userStepMetaInfo?.stepData?.total ?? 0),
      // );
      setIsLoading(false);
    },
    onError: (error: Error, variables) => {
      toast({ variant: 'danger', title: 'Error loading user data', description: error.toString() });
      Tracker.trackError(error, ErrorTags.REPORT_USERS_DATA_FETCH_ERROR);
      setIsLoading(false);
    },
  });

  const triggerRun = () => {
    let payload: GetReportUsersRequest;
    try {
      payload = getApiPayloadForUserListApi(showUsersViewInfo!.reportType, userStepMetaInfo!, userViewInsightInfo!, {
        currentPage,
        isDateCompareEnabled,
        configId: configId!,
      });
    } catch (error: any) {
      Tracker.trackError(error, ErrorTags.REPORT_USER_VIEW_CREATE_PAYLOAD_ERROR);
      toast({ variant: 'danger', description: 'Something went wrong. Please try again later' });
      return;
    }

    createUserDataRunIdReq.mutate(payload);
  };

  useEffect(() => {
    if (showUsersView && configId) {
      triggerRun();
    }
  }, [configId, userStepMetaInfo, currentPage, showUsersView]); // eslint-disable-line react-hooks/exhaustive-deps

  // set loading state effect
  useEffect(() => {
    if (runDataIsLoading || createUserDataRunIdReq.isLoading) {
      setIsLoading(true);
      setuserListFetchError(undefined);
    } // NOTE: not setting loading to false in else as it will show a flash of empty state illustration. It is being state to false on success/error of overall flow
  }, [runDataIsLoading, createUserDataRunIdReq.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let terminationTimer: number | undefined;
    // let increaseRefetchInterval: number | undefined;
    if (runStatusResponse.data?.status === 'FINISHED') {
      setRefetchInterval(false);
      if (terminationTimer) clearTimeout(terminationTimer);
      if (runId != null && runStatusResponse.data.outputPath != null) {
        setDataOutputPath(runStatusResponse.data.outputPath);
        setRunDataIsLoading(false);
      }
    }
    if (runStatusResponse.data && runStatusResponse.data?.status === 'ERRORED') {
      setRefetchInterval(false);
      toast({
        variant: 'danger',
        title: 'Unable to fetch user list',
        description: 'Something went wrong during query execution. Please try after some time',
      });
      setRunDataIsLoading(false);
      setuserListFetchError(
        new Error('Funnel user list query run errored', { cause: runStatusResponse.data.errorMsg }),
      );
    }
    if (
      runStatusResponse.data?.status === 'INITIATED' ||
      runStatusResponse.data?.status === 'QUERY_BUILT' ||
      runStatusResponse.data?.status === 'RESULT_AGGREGATED'
    ) {
      setRunDataIsLoading(true);
      // double the polling duration every 5sec
      // increaseRefetchInterval = window.setInterval(() => {
      //   setRefetchInterval((prevRefetchInterval) =>
      //     typeof prevRefetchInterval === 'number' ? prevRefetchInterval * 2 : INITIAL_POLLING_INTERVAL,
      //   );
      // }, 5000);

      // stop the polling after 60sec
      terminationTimer = window.setTimeout(() => {
        setRefetchInterval(false);
        setRunDataIsLoading(false);
        toast({
          variant: 'danger',
          title: 'Unable to fetch user list',
          description: 'Query execution terminated due to internal error. Please try after some time.',
        });
        setuserListFetchError(new Error('Funnel user list query run errored', { cause: 'Request timed out' }));
      }, 60000);
    }

    return () => {
      clearTimeout(terminationTimer);
      // clearInterval(increaseRefetchInterval);
    };
  }, [runStatusResponse.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dataOutputPath) {
      getRunDataRequest.mutate(dataOutputPath);
    }
  }, [dataOutputPath]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isLoading,
  };
};

export const useHandleViewUsersClick = ({ selectedTooltipData, pageType, chartType, reportType }: ViewUsersProps) => {
  const setShowUsersView = useSetRecoilState(showUserViewAtom);
  const setUserViewInsightInfo = useSetRecoilState(userViewInsightInfoState);

  return () => {
    if (pageType === PageType.INSIGHT && selectedTooltipData) {
      Tracker.trackEvent(EventNames.VIEW_USERS_LIST, {
        [EventProperty.ReportType]: reportType,
      });
      setUserViewInsightInfo({
        chartType,
        data: selectedTooltipData,
      });
      setShowUsersView({
        show: true,
        reportType,
      });
    }
  };
};

export const useShowViewUsers = () => {
  const breakdowns = useRecoilValue(validBreakdownsState);
  const formulas = useRecoilValue(formulasState);
  const dimensions = useRecoilValue(dimensionsStateV2);

  // TODO: jeevesh.g@udaan.com
  // Add support of formula and other breakdowns for view users information inside insights
  const showViewUsers =
    formulas.length === 0 &&
    breakdowns.every((b) => b.type === 'PROPERTY') &&
    dimensions.every((d) => d.resource_type !== DimensionType.VIRTUAL_EVENT);

  return showViewUsers;
};

import { SelectOptionsType } from '../../../ui-library/select';

export const FILTER_CONECTORS = ['&', '||'];

export enum FilterOperatorEnums {
  EQUALS = 'equals',
  NOT_EQUALS = 'not_equals',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'not_contains',
  GREATER_THAN_EQUAL = 'greater_than_equal',
  LESS_THAN_EQUAL = 'less_than_equal',
  PREFIX = 'prefix',
  SUFFIX = 'suffix',
  IS_SET = 'is_set',
  IS_NOT_SET = 'is_not_set',
  IS_BETWEEN = 'is_between',
  IS_NOT_BETWEEN = 'is_not_between',
}

export const FILTER_OPERATORS = [
  FilterOperatorEnums.EQUALS,
  FilterOperatorEnums.NOT_EQUALS,
  FilterOperatorEnums.CONTAINS,
  FilterOperatorEnums.NOT_CONTAINS,
  FilterOperatorEnums.GREATER_THAN_EQUAL,
  FilterOperatorEnums.LESS_THAN_EQUAL,
  FilterOperatorEnums.PREFIX,
  FilterOperatorEnums.SUFFIX,
  FilterOperatorEnums.IS_SET,
  FilterOperatorEnums.IS_NOT_SET,
  FilterOperatorEnums.IS_BETWEEN,
  FilterOperatorEnums.IS_NOT_BETWEEN,
];

export const FILTER_OPERATORS_WITH_MULTI_VALUE_SUPPORT = [FilterOperatorEnums.EQUALS, FilterOperatorEnums.NOT_EQUALS];
export const FILTER_OPERATORS_WITH_SINGLE_SELECT = [
  FilterOperatorEnums.CONTAINS,
  FilterOperatorEnums.NOT_CONTAINS,
  FilterOperatorEnums.GREATER_THAN_EQUAL,
  FilterOperatorEnums.LESS_THAN_EQUAL,
];

export const FILTER_OPERATORS_WITH_STRING_INPUT = [FilterOperatorEnums.PREFIX, FilterOperatorEnums.SUFFIX];

export const FILTER_OPERATORS_WITHOUT_VALUE = [FilterOperatorEnums.IS_SET, FilterOperatorEnums.IS_NOT_SET];

export const FILTER_OPERATORS_WITH_RANGE_VALUES = [FilterOperatorEnums.IS_BETWEEN, FilterOperatorEnums.IS_NOT_BETWEEN];

export const FILTER_CONNECTOR_DROPDOWN_OPTIONS: SelectOptionsType[] = FILTER_CONECTORS.map((fc) => ({
  label: fc === '&' ? 'And' : 'Or',
  value: fc,
}));

export const FILTER_OPERATORS_DROPDOWN_OPTIONS: SelectOptionsType[] = FILTER_OPERATORS.map((fo) => ({
  label: fo,
  value: fo,
}));

import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { getUseQueryOptions } from '@src/client/lib/api/utils';
import { getWalletBalance } from '@src/client/modules/wallet/api/queries';
import { walletBalance } from '@src/client/modules/wallet/atoms';
import { PerceptCoinIcon } from '@src/client/modules/wallet/WalletIcons';
import { CustomSvgIconActiveModeStroke } from '@src/client/ui-library/icons/v3/constants';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import LucidIconWrapper from '../lucid-icon-wrapper';
import SidepanelNavLink from './SidepanelNavLink';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';

export default function WalletMenuNew({
  isNavigationSidepanelOpen,
  currentPathname,
}: {
  isNavigationSidepanelOpen: boolean;
  currentPathname: string;
}) {
  const [balance, setBalance] = useRecoilState(walletBalance);
  const navigate = useNavigate();
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();

  useQuery(['walletBalance'], () => getWalletBalance(), {
    ...getUseQueryOptions(),
    onSuccess: (res) => {
      setBalance(res);
    },
    onError: (err: Error) => {
      Tracker.trackError(err, ErrorTags.FETCH_WALLET_BALANCE);
    },
  });

  return (
    <SidepanelNavLink
      isNavigationSidepanelOpen={isNavigationSidepanelOpen}
      path="wallet"
      text="Wallet"
      rightNode={
        balance ? (
          <div className="inline-flex items-center space-x-1">
            <p className="font-bold text-sm">{`${balance}`}</p>
            <PerceptCoinIcon className="h-6 w-fit" />
          </div>
        ) : undefined
      }
      icon={
        <LucidIconWrapper
          name="wallet"
          stroke={currentPathname.includes('wallet') ? CustomSvgIconActiveModeStroke : undefined}
        />
      }
      tooltipContent={
        <SidepanelNavlinkTooltip
          icon={<LucidIconWrapper name="wallet" stroke={CustomSvgIconActiveModeStroke} />}
          description="Check your account balance"
        />
      }
    />
  );
}

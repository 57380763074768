export interface CustomEventDTO {
  itemId: string;
  configId: string;
  itemExternalId: string;
  name: string;
  description: string;
  type: CustomEventType;
  workspaceId: string;
  tenantId: string;
  status: CustomEventStatus;
  createdAt: number;
  createdBy: string;
  params: CustomEventParams;
}

export enum CustomEventType {
  CUSTOM_EVENT = 'CUSTOM_EVENT',
  CUSTOM_PROPERTY = 'CUSTOM_PROPERTY',
  VIRTUAL_EVENT = 'VIRTUAL_EVENT',
  EVENT_GROUP = 'EVENT_GROUP',
}

export enum CustomEventStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

export type CustomEventParams = {
  events: Array<{
    alias: string;
    name: string;
    filters: FormattedFiltersForAPI;
  }>;
};

export interface FormattedFiltersForAPI {
  mapping: string;
  config: FormattedFiltersConfig[];
}

export interface FormattedFiltersConfig {
  key: number;
  resource_type: string;
  property_name: string | string[]; // string[] in case of global filters
  operator: string;
  property_values: string | string[];
  property_value_dt: 'array' | 'string';
  property_map?: string;
}

export interface GetCustomEventsRequest {
  limit: number;
  offset: number;
  currentUserOnly: boolean;
  status?: CustomEventStatus;
  type?: CustomEventType;
}

import { atom } from 'recoil';

export const walletBalance = atom<number | undefined>({
  key: 'walletBalance',
  default: undefined,
});

export const showCreditsAdditionWindowState = atom<boolean>({
  key: 'showCreditsAdditionWindowState',
  default: false,
});

export const showCreditsAdditionSuccessState = atom<boolean>({
  key: 'showCreditsAdditionSuccessState',
  default: false,
});

export const showCreditsAdditionCancelledState = atom<boolean>({
  key: 'showCreditsAdditionCancelledState',
  default: false,
});

export const rechargedAmountState = atom<number | undefined>({
  key: 'rechargedAmountState',
  default: undefined,
});

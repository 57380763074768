import { PropertiesAndCohorts } from '@src/client/helpers/reports/types';
import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { searchDimensionValues } from '@src/client/lib/api/queries/common';
import { convertToLabelValue, isLengthyArray } from '@src/client/lib/utils';
import { Button } from '@src/client/ui-library/button';
import { CloseIcon } from '@src/client/ui-library/icons/ReportIcons';
import { Label } from '@src/client/ui-library/label';
import { SelectOptionsType } from '@src/client/ui-library/select';
import AsyncCreatableSelect from '@src/client/ui-library/select/AsyncCreatableSelect';
import { Textarea } from '@src/client/ui-library/textarea';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { GroupBase, OptionsOrGroups } from 'react-select/dist/declarations/src/types';
import { useRecoilState } from 'recoil';

import { selectedUsersState } from '../recoil/atoms';

export default function SelectUsersArea() {
  const [valueArray, setValueArray] = useRecoilState(selectedUsersState);
  const [csvString, setCsvString] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);

  const propertyValuesSearch = useMutation(searchDimensionValues, {
    retry: 1,
    onSuccess: (_response) => {},
    onError: (err: Error, variables) => {
      Tracker.trackError(err, ErrorTags.DIMENSION_VALUES_SEARCH_ERROR, {
        dimension: variables.dimension,
        searchString: variables.searchString,
        limit: variables.limit,
        offset: variables.offset,
      });
    },
  });

  const handleDelete = (str: string) => {
    const newArray = valueArray.filter((val) => val.label !== str);
    setValueArray(newArray);
  };

  const addValuesByString = () => {
    const vals: SelectOptionsType[] = csvString
      .split(',')
      .filter((val) => val.trim() !== '')
      .map((val) => ({ label: val.trim(), value: val.trim() }));
    const newArray = [...valueArray];
    vals.forEach((val) => {
      if (!newArray.some((item) => item.value === val.value)) {
        newArray.push(val);
      }
    });
    setCsvString('');
    setValueArray(newArray);
  };

  const loadPropertyValues = (
    inputValue: string,
    _cb: any,
  ): Promise<
    OptionsOrGroups<PropertiesAndCohorts | SelectOptionsType, GroupBase<PropertiesAndCohorts | SelectOptionsType>>
  > | void =>
    new Promise((resolve) => {
      propertyValuesSearch
        .mutateAsync({
          dimension: 'user_id',
          limit: 1000,
          searchString: inputValue.length < 1 ? '<all>' : inputValue,
          offset: 0,
        })
        .then((dimensionValuesResponse: string | string[]) => {
          const convertedValues = convertToLabelValue(dimensionValuesResponse);
          const dimensionVals = Array.isArray(convertedValues) ? convertedValues : [convertedValues];
          valueArray
            .slice()
            .reverse()
            .forEach((value) => {
              if (!dimensionVals.includes(value) && value.value.includes(inputValue)) {
                dimensionVals.unshift(value);
              }
            });
          resolve(dimensionVals);
        })
        .catch((err: Error) => {
          console.log({ err });
          Tracker.trackError(err, ErrorTags.DIMENSION_VALUES_SEARCH_ERROR, {
            dimension: 'user_id',
            searchString: inputValue,
            limit: 1000,
            offset: 0,
          });
          resolve([]);
        });
    });

  useEffect(() => {
    const scrollToBottom = () => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    };
    const scrollTimeout = setTimeout(scrollToBottom, 50);
    return () => clearTimeout(scrollTimeout);
  }, [valueArray]);

  return (
    <div className="flex flex-col gap-2">
      <AsyncCreatableSelect
        isMulti
        placeholder="Search User ID's"
        onChange={(val) => {
          setValueArray(val as SelectOptionsType[]);
        }}
        value={valueArray}
        loadOptions={loadPropertyValues}
        defaultOptions
        controlShouldRenderValue={false}
        closeMenuOnSelect={false}
        selectClassNames={{
          container: 'w-[450px]',
          placeholder: 'font-medium',
        }}
      />
      <div
        ref={containerRef}
        className="flex flex-wrap border border-border rounded-xl p-2 gap-2 max-h-[200px] overflow-y-scroll"
      >
        {isLengthyArray(valueArray) ? (
          valueArray.map((option, index) => (
            <div
              key={option.value + Math.random().toString()}
              className="border border-border rounded-lg py-1 px-1.5 flex gap-2 text-gray-500 dark:text-gray-400"
            >
              {option.label}
              <Button variant="icon" onClick={() => handleDelete(option.value)}>
                <CloseIcon />
              </Button>
            </div>
          ))
        ) : (
          <span className="p-1 border border-transparent text-border">No Selected UserId&apos;s</span>
        )}
      </div>
      <span className="font-bold my-2 mt-4">Or</span>
      <div className="flex flex-col items-start">
        <Label className="text-sm font-medium">Add User ID’s</Label>
        <div className="h-px" />
        <Textarea
          placeholder="Ex: GHSLLDU98695, GHSLLDU98695,GHSLLDU98695"
          value={csvString}
          onChange={(e) => {
            setCsvString(e.target.value);
          }}
        />
        <div className="h-2" />
        <Button disabled={csvString.trim() === ''} onClick={addValuesByString} className="h-10 min-w-200">
          Add
        </Button>
      </div>
    </div>
  );
}

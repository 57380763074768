export enum SdkType {
  WEB = 'WEB',
  REACT_NATIVE = 'REACT NATIVE',
  FLUTTER = 'FLUTTER',
  JAVA = 'JAVA',
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEBFLOW = 'WEBFLOW',
  SHOPIFY = 'SHOPIFY',
  WORDPRESS = 'WORDPRESS',
}

export interface SdkIntegrationResponseV2 {
  success: boolean;
  apikey: string;
  data: SdkInfoResV2[];
}

export interface SdkInfoResV2 {
  sdkType: string;
  // sdk version - DO NOT USE THIS. The value coming from backend is dummy
  // sdkTotalEvents - DO NOT USE THIS. The value coming from backend is dummy
}

export interface SdkIntegrationResponse {
  success: boolean;
  apikey: string;
  data: SdkInfo[];
}

export interface SdkInfo {
  sdkType: string;
  sdkVersion: string;
  sdkTotalEvents: string;
}

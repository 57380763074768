/* eslint-disable no-restricted-globals */
import { ChartType } from '@src/client/helpers/reports/constants';
import { useIsOnboardingCreatedWorkspace } from '@src/client/helpers/reports/hooks';
import { createReport } from '@src/client/lib/api/mutations/common';
import { getReportRunData, getRunByRunId } from '@src/client/lib/api/queries/common';
import { ReportRunDataBaseRe, ReportType } from '@src/client/lib/api/types/response';
import { useEffect, useState } from 'react';

import { MetricCardType } from './constants';
import { getConfigForMetricsCard } from './utils';

export const useMetricDataFetcher = (
  metricType: MetricCardType,
  reportType: ReportType,
  chartType: ChartType,
  virtualAllEventId: string,
) => {
  const [data, setData] = useState<ReportRunDataBaseRe>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const isOnboardingFlowWs = useIsOnboardingCreatedWorkspace();

  const dataFetcher = async () => {
    let runId = '';
    let outputPath = '';
    setIsLoading(true);
    try {
      const payload = getConfigForMetricsCard(metricType, reportType, chartType, virtualAllEventId);
      const configData = await createReport(payload);
      runId = configData.runId;
    } catch (err) {
      setError(err as Error);
      setIsLoading(false);
      return;
    }

    // eslint-disable-next-line consistent-return
    const pollRunStatus = async (): Promise<void> => {
      const response = await getRunByRunId(runId);
      if (['FINISHED', 'ERRORED'].indexOf(response?.status ?? '') === -1) {
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
        return pollRunStatus();
      }

      if (response && response.outputPath) {
        outputPath = response.outputPath;
        try {
          const responseData = await getReportRunData(outputPath);
          setData(responseData);
          setIsLoading(false);
          localStorage.setItem(`pi_Home_${location.pathname}_${metricType}`, JSON.stringify(responseData));
          localStorage.setItem(`pi_Home_${location.pathname}_metric_ts`, JSON.stringify(Date.now()));
        } catch (e) {
          setError(e as Error);
          setIsLoading(false);
        }
      } else {
        setError(Error('Run status call failed'));
        setIsLoading(false);
      }
    };

    await pollRunStatus();
  };

  useEffect(() => {
    const cacheValue = localStorage.getItem(`pi_Home_${location.pathname}_${metricType}`);
    const cachedTs = localStorage.getItem(`pi_Home_${location.pathname}_metric_ts`);

    const canUseCachedValue = (): boolean =>
      !!cacheValue &&
      !!cachedTs &&
      Date.now() - JSON.parse(cachedTs) < (isOnboardingFlowWs ? 2 * 60 * 1000 : 30 * 60 * 1000); // 2 min cache for new users and 30 mins for old users

    if (canUseCachedValue()) {
      setData(JSON.parse(cacheValue!));
      setIsLoading(false);
      return;
    }
    dataFetcher();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { data, isLoading, error };
};

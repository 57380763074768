import { Filter } from '@src/client/helpers/reports/types';
import { isLengthyArray } from '@src/client/lib/utils';
import { isEqual } from 'lodash-es';

import { FILTER_OPERATORS_WITH_RANGE_VALUES, FILTER_OPERATORS_WITHOUT_VALUE } from './constants';

const isValidFilter = (filter: Filter): boolean => {
  if (FILTER_OPERATORS_WITHOUT_VALUE.includes(filter.operator)) return true;
  if (FILTER_OPERATORS_WITH_RANGE_VALUES.includes(filter.operator)) {
    return isLengthyArray(filter.values) && filter.values.length === 2 && (filter.values as string[]).every((v) => !!v);
  }
  return !!(filter.property && filter.property !== '' && filter.values.length > 0);
};

const validFilters = (filters: Filter[] | undefined) =>
  filters && isLengthyArray(filters) ? filters.filter((f) => isValidFilter(f)) : [];

export const getValidFilters = (filters: Filter[] | undefined) =>
  filters ? filters.filter((f) => isValidFilter(f)).map((f, i) => ({ ...f, key: i + 1 })) : [];

export const getUpdatedFilters = (oldFilters: Filter[], filter: Filter, idx: number) =>
  [...oldFilters].map((f, i) => (i === idx ? filter : f));

export const hasGlobalFiltersChanged = (
  filters: Filter[] | undefined,
  previousFilters: Filter[] | undefined,
): boolean => !isEqual(validFilters(filters), validFilters(previousFilters));

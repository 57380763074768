import { FlowsExpandedEvent } from '@src/client/modules/flows/types';
import { atom, selector } from 'recoil';

export const flowsExpandedEventsListState = atom<FlowsExpandedEvent[]>({
  key: 'flowsExpandedEventsListStateV2',
  default: [],
});

export const validFlowsExpandedEventsListState = selector({
  key: 'validFlowsExpandedEventsListState',
  get: ({ get }) => {
    const flowsExpandedEventsList = get(flowsExpandedEventsListState);
    return flowsExpandedEventsList.filter((d) => d.event && d.property && d.property !== '');
  },
});

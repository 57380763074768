import { PlatformItemType } from '@src/client/lib/api/types/request';
import { ReportType } from '@src/client/lib/api/types/response';
import { Checkbox } from '@src/client/ui-library/checkbox';
import { useEffect } from 'react';
import { RecoilState, useRecoilState, useResetRecoilState } from 'recoil';

// NOTE: Dashboard is not a 'ReportType' in our system.

export type ReportFilterOptionType = {
  label: string;
  imageSrc: string;
  value: ReportType | PlatformItemType;
  reportType: ReportType | null;
  itemType: PlatformItemType;
};

const ReportTypeFilterOptions = [
  {
    label: 'Insights',
    value: ReportType.INSIGHT,
    imageSrc: '/images/v2/insights-small.png',
    reportType: ReportType.INSIGHT,
    itemType: PlatformItemType.REPORT,
  },
  {
    label: 'Funnels',
    value: ReportType.FUNNEL,
    imageSrc: '/images/v2/funnels-small.png',
    reportType: ReportType.FUNNEL,
    itemType: PlatformItemType.REPORT,
  },
  {
    label: 'Flows',
    value: ReportType.FLOW,
    imageSrc: '/images/v2/flows-small.png',
    reportType: ReportType.FLOW,
    itemType: PlatformItemType.REPORT,
  },
  {
    label: 'Retention',
    value: ReportType.RETENTION,
    imageSrc: '/images/v2/retention-small.png',
    reportType: ReportType.RETENTION,
    itemType: PlatformItemType.REPORT,
  },
  {
    label: 'Dashboard',
    value: PlatformItemType.DASHBOARD,
    imageSrc: '/images/v2/dashboard-small.png',
    reportType: null,
    itemType: PlatformItemType.DASHBOARD,
  },
] satisfies ReportFilterOptionType[];

interface Props {
  hiddenReportTypes?: Array<ReportType | null>;
  hiddenItemTypes?: Array<PlatformItemType>;
  reportTypeFiltersState: RecoilState<Array<ReportFilterOptionType>>;
}

export default function ReportTypeFilter({
  reportTypeFiltersState,
  hiddenReportTypes = [],
  hiddenItemTypes = [],
}: Props) {
  const [activeReportTypes, setActiveReportTypes] = useRecoilState(reportTypeFiltersState);
  const resetReportTypeFilters = useResetRecoilState(reportTypeFiltersState);

  useEffect(() => () => resetReportTypeFilters(), []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterSelection = (item: ReportFilterOptionType, _checked: boolean) => {
    const selectedReportTypes = [...activeReportTypes];
    const itemIndex = selectedReportTypes.findIndex((rt) => rt.value === item.value);

    if (itemIndex > -1) {
      selectedReportTypes.splice(itemIndex, 1);
      setActiveReportTypes([...selectedReportTypes]);
    } else {
      setActiveReportTypes([...selectedReportTypes, item]);
    }
  };

  return (
    <div className="border border-border rounded-xl py-3 px-4">
      <p className="text-sm font-semibold mb-4">Type</p>
      <div className="flex flex-col space-y-6">
        {ReportTypeFilterOptions.map((item) => {
          const isChecked = activeReportTypes.findIndex((rt) => rt.value === item.value) > -1;

          if (hiddenReportTypes.indexOf(item.reportType) > -1) return null;
          if (hiddenItemTypes.indexOf(item.itemType) > -1) return null;

          return (
            <div key={item.value} className="flex items-center space-x-2.5">
              <Checkbox
                id={item.value}
                checked={isChecked}
                onCheckedChange={(checked) => handleFilterSelection(item, !!checked)}
              />
              <label
                htmlFor={item.value}
                className="inline-flex items-center text-sm font-normal text-foreground dark:text-foreground-light leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                <img height={24} width={24} src={item.imageSrc} className="mr-1" alt={`${item.label} icon`} />
                {item.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

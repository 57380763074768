import LayoutV2 from '@src/client/components/layout';
import { useCheckSdkIntegrationOnHomepage } from '@src/client/helpers/reports/hooks';
import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { ScreenNames } from '@src/client/routes/data';
import { PageLoader } from '@src/client/ui-library/loaders';
import { useUIVersion } from '@src/client/ui-library/theme-provider/uiProvider';
import { Navigate } from 'react-router-dom';

import HomeLeftNavigationPanel from './components/HomeLeftNavigationPanel';
import HomeRightPanel from './components/HomeRightPanel';
import ReportsView from './components/ReportsView';
import HomeV3 from './HomeV3';

export default function HomeScreen() {
  const { uiVersion } = useUIVersion();
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const { isSdkIntegrated, isSdkLoading } = useCheckSdkIntegrationOnHomepage();

  if (isSdkLoading) return <PageLoader />;

  if (!isSdkIntegrated)
    return (
      <Navigate
        to={{
          pathname: getLinkWithWorkspace('sdk-integrations'),
        }}
      />
    );

  return (
    <LayoutV2 screenName={ScreenNames.HomeScreen}>
      {uiVersion === 'v3' ? (
        <HomeV3 />
      ) : (
        <div className="flex relative w-full">
          {uiVersion === 'v2' ? <HomeLeftNavigationPanel /> : null}
          <div className="flex-1 py-6 px-4">
            <ReportsView currentUserOnly={false} />
          </div>
          <HomeRightPanel hideCreatedBy={false} />
        </div>
      )}
    </LayoutV2>
  );
}

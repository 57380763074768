import { BoardsIconV3 } from '@src/client/ui-library/icons/v3';

import DashboardNavLinksV3 from './DashboardNavlinkV3';
import NestedNavigationMenu from './NestedNavigationMenu';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';

export default function BoardsMenu({
  isNavigationSidepanelOpen,
  currentPathname,
}: {
  isNavigationSidepanelOpen: boolean;
  currentPathname: string;
}) {
  const isBoardsPageOpen = currentPathname.includes('dashboard') && !currentPathname.includes('heatmaps');
  return (
    <NestedNavigationMenu
      isNavigationSidepanelOpen={isNavigationSidepanelOpen}
      triggerText="Your Boards"
      isActive={isBoardsPageOpen}
      triggerIcon={<BoardsIconV3 active={isBoardsPageOpen} className={isNavigationSidepanelOpen ? '' : 'ml-1'} />}
      tooltipContent={<SidepanelNavlinkTooltip icon={<BoardsIconV3 active />} description="Your Boards" />}
    >
      <DashboardNavLinksV3 currentPathname={currentPathname} />
    </NestedNavigationMenu>
  );
}

import { DefaultDashboardGraphColor } from '@src/client/modules/dashboard/constants';

import LucidIconWrapper from '../lucid-icon-wrapper';

export default function FeatureLock({ isNavigationSidepanelOpen }: { isNavigationSidepanelOpen: boolean }) {
  return isNavigationSidepanelOpen ? (
    <div
      className=" bg-defaultReportColors-4E5BA6 opacity-40 dark:opacity-60 p-1 rounded-md "
      style={{ backgroundColor: `#${DefaultDashboardGraphColor}` }}
    >
      <LucidIconWrapper name="lock" size={10} className="font-bold" stroke="white" />
    </div>
  ) : (
    <div
      className="absolute bottom-[-10px] right-[-6px] bg-defaultReportColors-4E5BA6 opacity-40 dark:opacity-60 p-1 rounded-md "
      style={{ backgroundColor: `#${DefaultDashboardGraphColor}` }}
    >
      <LucidIconWrapper name="lock" size={10} className="font-bold" stroke="white" />
    </div>
  );
}

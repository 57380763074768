import { Skeleton } from '@src/client/ui-library/skeleton';
import { cn } from '@src/client/ui-library/utils';
import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  hideBorder?: boolean;
  isLoading: boolean;
}
export default function MetricCardWrapper({ hideBorder = true, isLoading, children }: Props) {
  return (
    <div className={cn('p-2 relative h-full', hideBorder ? '' : 'border border-border rounded-md')}>
      {isLoading ? (
        <div className="space-y-2">
          <Skeleton className="h-6 w-28" />
          <Skeleton className="h-4 w-20" />
        </div>
      ) : (
        children
      )}
    </div>
  );
}

import { ErrorTags } from '@src/client/lib/analytics/events';
import { useEffect, useRef } from 'react';

import FunnelWorker from './funnelWorker?worker';
import InsightWorker from './insightWorker?worker';
import { ReportWrokerType } from './types';
import { handleWorkerError } from './utils';

const getWorker = (workerType: ReportWrokerType): Worker => {
  switch (workerType) {
    case ReportWrokerType.INSIGHT_WORKER:
      return new InsightWorker();
    case ReportWrokerType.FUNNEL_WORKER:
      return new FunnelWorker();
    default:
      throw new Error('Unsupported worker type passed');
  }
};

const getWorkerErrorTag = (workerType: ReportWrokerType): ErrorTags => {
  switch (workerType) {
    case ReportWrokerType.INSIGHT_WORKER:
      return ErrorTags.INSIGHT_WORKER_ERROR;
    case ReportWrokerType.FUNNEL_WORKER:
      return ErrorTags.FUNNEL_WORKER_ERROR;
    default:
      return ErrorTags.UNKNOWN_WROKER_ERROR;
  }
};

export const useReportWorker = (
  workerType: ReportWrokerType,
  workerResponseHandler: (e: MessageEvent<string>) => void,
) => {
  const computeWorker = useRef<Worker>();

  useEffect(() => {
    computeWorker.current = getWorker(workerType);

    computeWorker.current.onerror = (error) => handleWorkerError(error, getWorkerErrorTag(workerType));

    return () => computeWorker.current?.terminate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (computeWorker.current) {
      computeWorker.current.onmessage = workerResponseHandler;
    }
  }, [workerResponseHandler]);

  return { computeWorker: computeWorker.current };
};

import { Button } from '@src/client/ui-library/button';
import {
  FileDeleteIcon,
  FileDownloadIcon,
  FileParseErrorIcon,
  FileUploadIcon,
} from '@src/client/ui-library/icons/ReportIcons';
import { Input } from '@src/client/ui-library/input';
import { Label } from '@src/client/ui-library/label';
import { SpinLoader } from '@src/client/ui-library/loaders';
import { ChangeEvent, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { csvFileOnErrorState, csvFileState } from '../recoil/atoms';
import { initiateDownload } from '../utils/cohortUtils';

export default function UploadCsvArea({ isLoading }: { isLoading: boolean }) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [csvFile, setCsvFile] = useRecoilState(csvFileState);
  const [onError, setOnError] = useRecoilState(csvFileOnErrorState);

  const handleFileInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file !== null) {
      setOnError(false);
      setCsvFile(file);
    }
  };

  const handleDeleteFile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCsvFile(undefined);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div>
      <Label
        className={`relative block h-40 cursor-pointer  rounded-xl border border-border ${
          onError ? 'bg-yellow-50' : csvFile ? 'bg-gray-50 dark:bg-gray-400' : 'bg-primary-light dark:bg-indigo-50'
        }`}
      >
        <Input
          type="file"
          className="absolute inset-0 opacity-0 cursor-pointer disabled:opacity-0"
          ref={fileInputRef}
          onChange={(event) => handleFileInput(event)}
          onClick={() => {
            if (onError) setOnError(false);
          }}
          accept=".csv"
          disabled={isLoading}
        />
        {csvFile ? (
          onError ? (
            <section className="absolute inset-0 flex flex-col items-center justify-center h-full gap-1">
              <FileParseErrorIcon />
              <span className=" text-warning">File invalid, parsing error</span>
              <span className=" font-semibold text-gray-900">{csvFile.name}</span>
              <span className=" font-bold text-primary">Upload again</span>
            </section>
          ) : (
            <span
              className={`absolute inset-0 flex items-center justify-center h-full text-lg font-bold  gap-2 ${
                isLoading ? 'text-gray-400 dark:text-gray-700' : 'text-gray-900'
              }`}
            >
              {isLoading && <SpinLoader relative size="small" />}
              {csvFile.name}
              <Button variant="icon" color="danger" onClick={handleDeleteFile} disabled={isLoading}>
                <FileDeleteIcon />
              </Button>
            </span>
          )
        ) : (
          <span className="absolute inset-0 flex items-center justify-center h-full text-lg font-bold text-primary gap-2">
            <FileUploadIcon />
            Upload .CSV File
          </span>
        )}
      </Label>
      <Button
        variant="link"
        className="px-0 hover:no-underline"
        disabled={isLoading}
        onClick={() => initiateDownload('/cohort_sample.csv')}
      >
        <FileDownloadIcon />
        <span className="ml-2 font-bold dark:text-popover-foreground">Download Sample .CSV file</span>
      </Button>
    </div>
  );
}

export interface RotuesInfo {
  title: string;
  description: string;
  path: string;
  component: JSX.Element;
  selector: string;
  subRoutes?: RotuesInfo[];
  screenName: string;
}

export enum PageType {
  INSIGHT = 'INSIGHT',
  FUNNEL = 'FUNNEL',
  COHORT = 'COHORT',
  DASHBOARD = 'DASHBOARD',
  EVENT = 'EVENT',
  CUSTOM_EVENT = 'CUSTOM_EVENT',
  ENGAGE = 'ENGAGE',
  FLOW = 'FLOW',
  RETENTION = 'RETENTION',
}

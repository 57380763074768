export default function SidepanelNavlinkTooltip({ icon, description }: { icon: React.ReactNode; description: string }) {
  return (
    <div className="flex items-center justify-start space-x-2">
      {icon && <div className="w-8 h-8 p-1 bg-white flex items-center justify-center rounded-md">{icon}</div>}
      <div>
        <p className="text-xs font-medium">{description}</p>
      </div>
    </div>
  );
}

import { UserGovService } from '@src/client/lib/api/utils';
import { AnimatedPILoader } from '@src/client/ui-library/loaders';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

import { Cookie } from '../../lib/api/constants';
import { setLastVisitedpath } from '../../lib/utils';

let tokenExpiryTimer: NodeJS.Timeout;

function ProtectedRoute({ children }: any) {
  const [cookies] = useCookies([Cookie.id_token, Cookie.refresh_token, Cookie.id_token_expiry]);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [showChildren, setShowChildren] = useState(false);

  const tokenExpiry = cookies[Cookie.id_token_expiry];
  const isExpired = cookies._id_token && tokenExpiry ? dayjs(tokenExpiry).isBefore(dayjs()) : true; // eslint-disable-line no-underscore-dangle

  const updateAccessToken = async (redirect = false) => {
    try {
      await UserGovService.handleAuthTokenRefetch(redirect);
      setShowChildren(true);
    } catch (_error) {
      setRedirectToLogin(true);
    }
  };

  // use refresh token to fetch latest token based on next token expiry timestamp
  useEffect(() => {
    if (isExpired) {
      updateAccessToken(true);
      return undefined;
    }

    if (!isExpired) {
      const timeLeftToExpire = dayjs(tokenExpiry).diff(dayjs());
      const thresholdTimeToFetch = 5 * 60 * 1000;

      if (timeLeftToExpire > thresholdTimeToFetch) {
        clearTimeout(tokenExpiryTimer);
        tokenExpiryTimer = setTimeout(() => {
          updateAccessToken();
        }, timeLeftToExpire - thresholdTimeToFetch);
        setShowChildren(true);
      } else {
        updateAccessToken(true);
      }
    } else {
      setShowChildren(true);
    }

    return () => {
      clearTimeout(tokenExpiryTimer);
    };
  }, [tokenExpiry, isExpired]);

  if (showChildren) return children;

  if (redirectToLogin) {
    setLastVisitedpath();
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return (
    <div className="h-full flex items-center justify-center bg-background" style={{ height: '100vh' }}>
      <AnimatedPILoader />
    </div>
  );
}

export default ProtectedRoute;

import { TimeseriesDataApiRes } from '@src/client/lib/api/types/response';

export enum MetricCardType {
  DAU = 'DAU',
  MAU = 'MAU',
  EventVolume = 'Event Volume',
  NewUsers = 'New Users',
}

export type MetricDataApiRes = {
  original: {
    '$All Events-A': TimeseriesDataApiRes;
  };
  compare: {
    '$All Events-A': TimeseriesDataApiRes;
  };
};

export enum RewardsEndpoints {
  getBalance = '/rewards/balance',
  getledger = '/rewards/ledger',
}

export enum PaymentEndPoints {
  createInvoice = '/invoices',
  payInvoice = '/payments/{invoice_id}',
  verifyPayment = '/payments/verify/{invoice_id}',
  getInvoice = '/invoices/{invoice_id}',
  cancelPayment = '/payments/cancel/{invoice_id}',
}

import {
  DimensionDetailed,
  DimensionType,
  PropertyDataTypes,
  SingularSupportedTimeUnits,
} from '@src/client/lib/api/types/response';
import { snakeCaseToTitleCase } from '@src/client/lib/utils';
import { CommunicationEvents } from '@src/client/modules/engage/engage-attribution/types';
import dayjs from 'dayjs';
import { round } from 'lodash-es';

import { CustomEventType } from '../../modules/custom-events/types';
import {
  CompareTimePeriodSubTypes,
  CompareType,
  DATE_FORMAT,
  DateRangeEnum,
  DimensionMathCountUsers,
  DimensionMathValues,
} from './constants';
import { isPastCompareString, replacePastFlagFromCompareString } from './dataUtils';
import { CompareOption, DataVizRow, DateType, DimensionMathOption } from './types';

export const getCompareOptions = (): CompareOption[] => [
  {
    value: CompareType.TIME_PERIOD,
    label: snakeCaseToTitleCase(CompareType.TIME_PERIOD),
    disabled: false,
    children: [
      {
        value: CompareTimePeriodSubTypes.PERVIOUS_DAY,
        label: snakeCaseToTitleCase(CompareTimePeriodSubTypes.PERVIOUS_DAY),
        disabled: false,
        children: [],
      },
      {
        value: CompareTimePeriodSubTypes.PERVIOUS_WEEK,
        label: snakeCaseToTitleCase(CompareTimePeriodSubTypes.PERVIOUS_WEEK),
        disabled: false,
        children: [],
      },
      {
        value: CompareTimePeriodSubTypes.PERVIOUS_MONTH,
        label: snakeCaseToTitleCase(CompareTimePeriodSubTypes.PERVIOUS_MONTH),
        disabled: false,
        children: [],
      },
      {
        value: CompareTimePeriodSubTypes.CUSTOM,
        label: snakeCaseToTitleCase(CompareTimePeriodSubTypes.CUSTOM),
        disabled: false,
        children: [],
      },
    ],
  },
];

const TODAY = dayjs();

export function numberToAlphabets(num: number) {
  if (num < 0) {
    return 'Invalid input';
  }

  let result = '';
  while (num >= 0) {
    const remainder = num % 26;
    result = String.fromCharCode(65 + remainder) + result; // 65 corresponds to 'A' in ASCII

    num = Math.floor(num / 26) - 1; // eslint-disable-line no-param-reassign

    if (num < 0) {
      break;
    }
  }

  return result;
}

export function getCustomEventDimensionType(customEventType: CustomEventType): DimensionType {
  switch (customEventType) {
    case CustomEventType.CUSTOM_EVENT: {
      return DimensionType.CUSTOM_EVENT;
    }
    case CustomEventType.EVENT_GROUP: {
      return DimensionType.EVENT_GROUP;
    }
    case CustomEventType.VIRTUAL_EVENT: {
      return DimensionType.VIRTUAL_EVENT;
    }
    default: {
      return DimensionType.CUSTOM_EVENT;
    }
  }
}

export const DATE_RANGES: DateType[] = [
  {
    key: DateRangeEnum.CUSTOM,
    fromDate: undefined,
    toDate: undefined,
  },
  {
    key: DateRangeEnum.SINCE,
    fromDate: undefined,
    toDate: TODAY.add(1, 'day').hour(0).minute(0).second(0).millisecond(0).format(DATE_FORMAT),
  },
  {
    key: DateRangeEnum.TODAY,
    fromDate: TODAY.hour(0).minute(0).second(0).millisecond(0).format(DATE_FORMAT),
    toDate: TODAY.add(1, 'day').hour(0).minute(0).second(0).millisecond(0).format(DATE_FORMAT),
  },
  {
    key: DateRangeEnum.YESTERDAY,
    fromDate: TODAY.subtract(1, 'day').hour(0).minute(0).second(0).millisecond(0).format(DATE_FORMAT),
    toDate: TODAY.add(1, 'day').hour(0).minute(0).second(0).millisecond(0).format(DATE_FORMAT),
  },
  {
    key: DateRangeEnum.SEVEN_DAYS,
    fromDate: TODAY.subtract(7, 'day').hour(0).minute(0).second(0).format(DATE_FORMAT),
    toDate: TODAY.add(1, 'day').hour(0).minute(0).second(0).millisecond(0).format(DATE_FORMAT),
  },
  {
    key: DateRangeEnum.FOURTEEN_DAYS,
    fromDate: TODAY.subtract(14, 'day').hour(0).minute(0).second(0).format(DATE_FORMAT),
    toDate: TODAY.add(1, 'day').hour(0).minute(0).second(0).millisecond(0).format(DATE_FORMAT),
  },
  {
    key: DateRangeEnum.THIRTY_DAYS,
    fromDate: TODAY.subtract(30, 'day').hour(0).minute(0).second(0).format(DATE_FORMAT),
    toDate: TODAY.add(1, 'day').hour(0).minute(0).second(0).millisecond(0).format(DATE_FORMAT),
  },
  {
    key: DateRangeEnum.THREE_MONTH,
    fromDate: TODAY.subtract(3, 'month').hour(0).minute(0).second(0).format(DATE_FORMAT),
    toDate: TODAY.add(1, 'day').hour(0).minute(0).second(0).millisecond(0).format(DATE_FORMAT),
  },
  {
    key: DateRangeEnum.SIX_MONTH,
    fromDate: TODAY.subtract(6, 'month').hour(0).minute(0).second(0).format(DATE_FORMAT),
    toDate: TODAY.add(1, 'day').hour(0).minute(0).second(0).millisecond(0).format(DATE_FORMAT),
  },
  {
    key: DateRangeEnum.TWELVE_MONTH,
    fromDate: TODAY.subtract(12, 'month').hour(0).minute(0).second(0).format(DATE_FORMAT),
    toDate: TODAY.add(1, 'day').hour(0).minute(0).second(0).millisecond(0).format(DATE_FORMAT),
  },
];

export const addTimeDimensionsInProperties = (properties: DimensionDetailed[]): DimensionDetailed[] => {
  const tempProperties = [...properties];
  const timeDimensions = Object.keys(SingularSupportedTimeUnits).filter(
    (timeUnit) => timeUnit !== 'SECOND' && timeUnit !== 'MINUTE' && timeUnit !== 'SESSION',
  );
  timeDimensions.forEach((timeDimension) =>
    tempProperties.push({
      column: timeDimension,
      cached: false,
      dataType: PropertyDataTypes.TIMESTAMP,
    }),
  );
  return tempProperties;
};

export const getDimensionMathCountUsersOptions = (): DimensionMathOption => ({
  value: DimensionMathValues.COUNT_USERS,
  label: snakeCaseToTitleCase(DimensionMathValues.COUNT_USERS),
  children: [
    {
      value: DimensionMathCountUsers.DAU,
      label: 'Daily Active Users (DAU)',
    },
    //   {
    //     value: DimensionMathCountUsers.WAU,
    //     label: 'Weekly Active Users (DAU)'
    //   },
    //   {
    //     value: DimensionMathCountUsers.MAU,
    //     label: 'Monthly Active Users (DAU)'
    //   },
  ],
});

// Helper function to parse time strings to seconds
function parseTimeToSeconds(time: string): number {
  const match = time.match(/^([\d.]+)([hmds])$/);
  if (match) {
    const numericValue = parseFloat(match[1]);
    const unit = match[2];
    if (!Number.isNaN(numericValue)) {
      if (unit === 'h') {
        return numericValue * 3600;
      }
      if (unit === 'm') {
        return numericValue * 60;
      }
      if (unit === 'd') {
        return numericValue * 86400;
      }
      if (unit === 's') {
        return numericValue;
      }
    }
  }
  return 0; // Default to 0 seconds if parsing fails
}

export const getDimensionMathAggregateOptions = (): DimensionMathOption => ({
  value: DimensionMathValues.AGGREGATE_PROPERTIES,
  label: snakeCaseToTitleCase(DimensionMathValues.AGGREGATE_PROPERTIES),
  children: [
    {
      value: 'sum',
      label: 'Sum',
    },
    {
      value: 'avg',
      label: 'Average',
    },
    {
      value: '0.50',
      label: 'Median',
    },
    {
      value: 'unique',
      label: 'Distinct Count',
    },
    {
      value: '0.25',
      label: '25th Percentile',
    },
    {
      value: '0.75',
      label: '75th Percentile',
    },
    {
      value: '0.90',
      label: '90th Percentile',
    },
    {
      value: '0.99',
      label: '99th Percentile',
    },
    {
      value: 'min',
      label: 'Minimum',
    },
    {
      value: 'max',
      label: 'Maximum',
    },
  ],
});

export const getDimensionMathOptions = (): DimensionMathOption[] => [
  {
    value: DimensionMathValues.TOTAL,
    label: snakeCaseToTitleCase(DimensionMathValues.TOTAL),
  },
  {
    value: DimensionMathValues.UNIQUE,
    label: snakeCaseToTitleCase(DimensionMathValues.UNIQUE),
  },
  {
    value: DimensionMathValues.SESSION,
    label: snakeCaseToTitleCase(DimensionMathValues.SESSION),
  },
  getDimensionMathCountUsersOptions(),
  getDimensionMathAggregateOptions(),
];

export function sortRowsByValueColumn(data: DataVizRow[], sortOrder: 'asc' | 'desc'): DataVizRow[] {
  const sortedData = data.slice().sort((a, b) => {
    if (a.value !== undefined && b.value !== undefined) {
      if (typeof a.value === 'number' && typeof b.value === 'number') {
        return sortOrder === 'asc' ? a.value - b.value : b.value - a.value;
      }
    } else if (a.value !== undefined) {
      return sortOrder === 'asc' ? -1 : 1;
    } else if (b.value !== undefined) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });
  return sortedData;
}

export function insertAverageTotalInRow(
  data: DataVizRow[],
  compare?: string[],
  includeTotal = false,
  sortTableData = true,
): DataVizRow[] {
  const updatedData = data.map((item) => {
    const newData = { ...item };
    let sum = 0;
    let count = 0;

    let pastSum = 0;
    let pastCount = 0;

    Object.keys(item).forEach((key) => {
      if (dayjs(key).isValid() && typeof item[key] === 'number' && !isPastCompareString(key)) {
        sum += item[key] as number;
        count += 1;
      }
      if (compare && compare.length === 2 && isPastCompareString(key)) {
        const formattedKey = replacePastFlagFromCompareString(key);
        if (dayjs(formattedKey).isValid() && typeof item[key] === 'number') {
          pastSum += item[key] as number;
          pastCount += 1;
        }
      }
    });

    newData.Average = count > 0 ? Number((sum / count).toFixed(2)) : undefined;
    if (includeTotal) newData.Total = round(sum, 2) || undefined;

    if (compare && compare.length === 2) {
      newData['Past Average'] = pastCount > 0 ? Number((pastSum / pastCount).toFixed(2)) : undefined;
      if (includeTotal) newData['Past Total'] = round(pastSum, 2) || undefined;
    }

    return newData;
  });

  // Sort the array based on the 'Average' field
  if (sortTableData) {
    const compareFields = (a: any, b: any, field: string) => {
      if (a[field] !== undefined && b[field] !== undefined) {
        return (b[field] as number) - (a[field] as number);
      }
      if (b[field] !== undefined) return 1;
      if (a[field] !== undefined) return -1;
      return 0;
    };

    updatedData.sort((a, b) => {
      const result = compareFields(a, b, 'Past Average');
      return result !== 0 ? result : compareFields(a, b, 'Average');
    });
  }

  return updatedData;
}

export function insertAverageTotalInRowWithTime(data: DataVizRow[], compare: string[] | undefined): DataVizRow[] {
  const updatedData = data.map((item) => {
    const newData = { ...item };
    let totalSeconds = 0;
    let count = 0;

    let pastTotalSeconds = 0;
    let pastCount = 0;

    Object.keys(item).forEach((key) => {
      if (dayjs(replacePastFlagFromCompareString(key)).isValid()) {
        const value = item[key];

        if (typeof value === 'string') {
          const match = value.match(/^([\d.]+)([hmds])$/);
          if (match) {
            const numericValue = parseFloat(match[1]);
            const unit = match[2];

            if (!Number.isNaN(numericValue)) {
              // Convert everything to seconds before averaging
              let seconds = 0;
              if (unit === 'h') {
                seconds = numericValue * 3600;
              } else if (unit === 'm') {
                seconds = numericValue * 60;
              } else if (unit === 'd') {
                seconds = numericValue * 86400;
              } else if (unit === 's') {
                seconds = numericValue;
              }

              if (!isPastCompareString(key)) {
                totalSeconds += seconds;
                count += 1;
              } else if (compare && compare.length === 2) {
                const formattedKey = replacePastFlagFromCompareString(key);
                if (dayjs(formattedKey).isValid()) {
                  pastTotalSeconds += seconds;
                  pastCount += 1;
                }
              }
            }
          }
        }
      }
    });

    const formatTime = (seconds: number): string => {
      if (seconds >= 86400) {
        const days = (seconds / 86400).toFixed(2);
        return `${days}d`;
      }
      if (seconds >= 3600) {
        const hours = (seconds / 3600).toFixed(2);
        return `${hours}h`;
      }
      if (seconds >= 60) {
        const minutes = (seconds / 60).toFixed(2);
        return `${minutes}m`;
      }
      return `${seconds.toFixed(2)}s`;
    };

    newData.Average = count > 0 ? formatTime(totalSeconds / count) : undefined;
    // newData.Total = totalSeconds > 0 ? formatTime(totalSeconds) : undefined;

    if (compare && compare.length === 2) {
      newData['Past Average'] = pastCount > 0 ? formatTime(pastTotalSeconds / pastCount) : undefined;
      // newData['Past Total'] = pastTotalSeconds > 0 ? formatTime(pastTotalSeconds) : undefined;
    }

    return newData;
  });

  updatedData.sort((a, b) => {
    if (a.Average && b.Average) {
      // Convert 'Average' values to seconds for comparison
      const aSeconds = parseTimeToSeconds(a.Average as string);
      const bSeconds = parseTimeToSeconds(b.Average as string);

      return bSeconds - aSeconds;
    }
    if (a.Average) {
      return -1;
    }
    if (b.Average) {
      return 1;
    }
    return 0;
  });

  return updatedData;
}

export function insertAverageInRowWithPercentage(data: DataVizRow[], compare: string[] | undefined): DataVizRow[] {
  const updatedData = data.map((item) => {
    const newData = { ...item };
    let sum = 0;
    let count = 0;

    let pastSum = 0;
    let pastCount = 0;

    Object.keys(item).forEach((key) => {
      if (dayjs(replacePastFlagFromCompareString(key)).isValid()) {
        const value = item[key];

        if (typeof value === 'string' && value.endsWith('%')) {
          const numericValue = parseFloat(value);
          if (!Number.isNaN(numericValue)) {
            if (!isPastCompareString(key)) {
              sum += numericValue;
              count += 1;
            } else if (compare && compare.length === 2) {
              const formattedKey = replacePastFlagFromCompareString(key);
              if (dayjs(formattedKey).isValid()) {
                pastSum += numericValue;
                pastCount += 1;
              }
            }
          }
        }
      }
    });

    const formatPercentage = (value: number): string => `${value.toFixed(2)}%`;

    newData.Average = count > 0 ? formatPercentage(sum / count) : undefined;
    if (compare && compare.length === 2) {
      newData['Past Average'] = pastCount > 0 ? formatPercentage(pastSum / pastCount) : undefined;
    }

    return newData;
  });

  // Sort the array by 'Average' property in descending order
  updatedData.sort((a, b) => {
    if (a.Average && b.Average) {
      const aAverage = parseFloat(a.Average as string);
      const bAverage = parseFloat(b.Average as string);

      if (!Number.isNaN(aAverage) && !Number.isNaN(bAverage)) {
        return bAverage - aAverage;
      }
    } else if (a.Average && !b.Average) {
      return -1;
    } else if (!a.Average && b.Average) {
      return 1;
    }
    return 0;
  });

  return updatedData;
}

/* Do not change it from a for loop to strList.some it'll break */
export const hasEmptyOrBlankString = (strList: (string | null | undefined)[]) => {
  for (let i = 0; i < strList.length; i += 1) {
    const str = strList[i];
    if (str === undefined || str === null || str.trim() === '') {
      return true;
    }
  }
  return false;
};

export const getCommunicationTypeGraphColour = (communicationType: string): string => '4E5BA6';
// switch (communicationType.toLowerCase()) {
//   case 'notification':
//     return CommunicationTypeColours.PUSH_NOTIFICATION;
//   case 'push_notification':
//     return CommunicationTypeColours.PUSH_NOTIFICATION;
//   case 'email':
//     return CommunicationTypeColours.EMAIL;
//   case 'sms':
//     return CommunicationTypeColours.SMS;
//   default:
//     return '0086C9';
// }

export const getCommunicationStepfromEvent = (communicationStep: CommunicationEvents): string => {
  switch (communicationStep) {
    case CommunicationEvents.COMMUNICATION_SENT:
      return 'Communication Sent';
    case CommunicationEvents.COMMUNICATION_RECEIVED:
      return 'Communication Delivered';
    case CommunicationEvents.COMMUNICATION_VIEWED:
      return 'Communication Viewed';
    case CommunicationEvents.COMMUNICATION_CLICKED:
      return 'Communication Clicked';
    case CommunicationEvents.COMMUNICATION_DELIVERED:
      return 'Communication Delivered';
    default:
      return '';
  }
};

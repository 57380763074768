import LucidIconWrapper from '@src/client/components/lucid-icon-wrapper';
import { useUserInfoQuery } from '@src/client/hooks';
import { ErrorTags } from '@src/client/lib/analytics/events';
import { clearReportPerfMarkers } from '@src/client/lib/analytics/perfTracker';
import Tracker from '@src/client/lib/analytics/tracker';
import {
  getCohortsList,
  getCustomEvents,
  getProperties,
  getPropertiesByEventName,
  getValuesByCohortName,
  getValuesByEventName,
} from '@src/client/lib/api/queries/common';
import { QueryKeys } from '@src/client/lib/api/queries/keys';
import {
  CohortBasicItem,
  CustomEventDTO,
  DimensionDetailed,
  DimensionType,
  EventPropertiesApiResItem,
  ReportType,
} from '@src/client/lib/api/types/response';
import { getUseQueryOptions } from '@src/client/lib/api/utils';
import { isLengthyArray } from '@src/client/lib/utils';
import { useResetAllFlowsAtomValues } from '@src/client/modules/flows/hooks/useFlowsState';
import { useResetAllFunnelAtomValues } from '@src/client/modules/funnels/hooks';
import { useResetAllInsightsAtomValues } from '@src/client/modules/insights/hooks';
import { useResetAllRetentionAtomValues } from '@src/client/modules/retention/hooks/recoil_hooks';
import { getSdkItegrationsV2 } from '@src/client/modules/sdk-integration/api/queries';
import {
  integratedSdksState,
  isPISdkIntegratedState,
  isSdkLoadingState,
  sdkApiTokenState,
  sdkIntegrationErrorState,
} from '@src/client/modules/sdk-integration/atoms';
import { SdkInfoResV2 } from '@src/client/modules/sdk-integration/types';
import { activeWorkspaceState, userInfoState } from '@src/client/recoil/atoms';
import { EventsIcon } from '@src/client/ui-library/icons/NavbarIcons';
import { uniqBy } from 'lodash-es';
// import {
//   CohortDropdownIcon,
//   EventDropdownIcon,
//   EventPropertyDropdownIcon,
// } from '@src/client/ui-library/icons/ReportIcons';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { CustomEventType } from '../../modules/custom-events/types';
import { EventsAndCohorts, PropertiesAndCohorts } from './types';
import { addTimeDimensionsInProperties, getCustomEventDimensionType } from './uiHelpers';

export const usePropertiesWithCohorts = () => {
  const [propertiesAndCohortsList, setPropertiesAndCohortsList] = useState<PropertiesAndCohorts[]>([]);
  const [timestampProperties, setTimestampProperties] = useState<PropertiesAndCohorts[]>([]);
  const [properties, setProperties] = useState<DimensionDetailed[]>([]);

  const fetchProperties = useQuery([{ key: QueryKeys.GetDetailedDimensions }], getProperties, getUseQueryOptions());
  const fetchCohorts = useQuery([QueryKeys.GetCohorts], getValuesByCohortName, getUseQueryOptions());

  useEffect(() => {
    if (fetchCohorts.isSuccess && fetchProperties.isSuccess) {
      const tempProperties: PropertiesAndCohorts[] = fetchProperties.data
        .filter((i) => i.column !== '__time')
        .map((property) => ({
          label: property.column,
          value: property.column,
          type: DimensionType.PROPERTY,
          propertyCached: property.cached,
          icon: <LucidIconWrapper size={14} name="square-mouse-pointer" />,
        }));
      const tempCohorts: PropertiesAndCohorts[] = fetchCohorts.data.map(
        (cohortValue: CohortBasicItem): PropertiesAndCohorts => ({
          label: cohortValue.name,
          value: cohortValue.itemExternalId,
          type: DimensionType.COHORT,
          propertyCached: false,
          icon: <LucidIconWrapper size={14} name="user" />,
        }),
      );
      setPropertiesAndCohortsList(tempProperties.concat(tempCohorts));
    }
  }, [fetchCohorts.isSuccess, fetchProperties.isSuccess, fetchCohorts.data, fetchProperties.data]);

  useEffect(() => {
    if (fetchProperties.isSuccess) {
      const filteredPropertiesDat: DimensionDetailed[] = fetchProperties.data.filter((i) => i.column !== '__time');
      const propertiesWithTimeDimension = addTimeDimensionsInProperties(filteredPropertiesDat);

      setProperties(propertiesWithTimeDimension);
    }
  }, [fetchProperties.isSuccess, fetchProperties.data]);

  useEffect(() => {
    const timeDimensions = addTimeDimensionsInProperties([]);
    setTimestampProperties(
      timeDimensions.map(
        (timeDimension): PropertiesAndCohorts => ({
          label: timeDimension.column,
          value: timeDimension.column,
          type: DimensionType.TIME,
          propertyCached: timeDimension.cached,
          icon: <EventsIcon />,
        }),
      ),
    );
  }, []);

  return {
    isLoading: fetchProperties.isLoading || fetchCohorts.isLoading,
    propertiesAndCohortsList,
    properties,
    timestampProperties,
    propertiesCohortsAndTimestampProps: [...propertiesAndCohortsList, ...timestampProperties],
    cohorts: fetchCohorts.data,
  };
};

export const useDimensionsWithCohorts = (dimension: string) => {
  const [eventsAndCohortList, setEventsAndCohortList] = useState<EventsAndCohorts[] | []>([]);
  const fetchEvents = useQuery([QueryKeys.GetAllEvents], () => getValuesByEventName(dimension), getUseQueryOptions(2));
  const fetchCohorts = useQuery([QueryKeys.GetCohorts], getValuesByCohortName, getUseQueryOptions());

  useEffect(() => {
    if (fetchEvents.isSuccess && fetchCohorts.isSuccess) {
      const tempEvents = fetchEvents.data.map(
        (value: string): EventsAndCohorts => ({
          label: value,
          value,
          icon: <LucidIconWrapper size={14} name="mouse-pointer-click" />,
          resource_type: DimensionType.EVENT,
        }),
      );
      const tempCohorts = fetchCohorts.data.map(
        (cohortValue: CohortBasicItem): EventsAndCohorts => ({
          label: cohortValue.name,
          value: cohortValue.itemExternalId,
          icon: <LucidIconWrapper size={14} name="user" />,
          resource_type: DimensionType.COHORT,
        }),
      );
      setEventsAndCohortList(tempEvents.concat(tempCohorts));
    }
  }, [fetchCohorts.isSuccess, fetchEvents.isSuccess, fetchCohorts.data, fetchEvents.data]);

  return {
    isLoading: fetchEvents.isLoading || fetchCohorts.isLoading,
    eventsAndCohortList,
    eventsList: fetchEvents.data || [],
    cohortsList: fetchCohorts.data || [],
  };
};

/* Includes events, custom_events & cohorts */
export const useAllDimensions = (dimension: string) => {
  const [dimensionsList, setDimensionList] = useState<EventsAndCohorts[] | []>([]);
  const fetchEvents = useQuery([QueryKeys.GetAllEvents], () => getValuesByEventName(dimension), getUseQueryOptions(2));
  const fetchCohorts = useQuery(QueryKeys.GetCohorts, getValuesByCohortName, getUseQueryOptions());
  const fetchCustomEvents = useQuery(
    [QueryKeys.GetCustomEvents],
    () =>
      getCustomEvents({
        limit: 100,
        offset: 0,
        currentUserOnly: false,
      }),
    getUseQueryOptions(),
  );

  useEffect(() => {
    if (fetchEvents.isSuccess && fetchCohorts.isSuccess && fetchCustomEvents.isSuccess) {
      const tempEvents = fetchEvents.data.map(
        (value: string): EventsAndCohorts => ({
          label: value,
          value,
          icon: <LucidIconWrapper size={14} name="mouse-pointer-click" />,
          resource_type: DimensionType.EVENT,
        }),
      );
      const tempCohorts = fetchCohorts.data.map(
        (cohortValue: CohortBasicItem): EventsAndCohorts => ({
          label: cohortValue.name,
          value: cohortValue.itemExternalId,
          icon: <LucidIconWrapper size={14} name="user" />,
          resource_type: DimensionType.COHORT,
        }),
      );
      const tempCustomEvents = fetchCustomEvents.data
        .filter(({ type }) =>
          [CustomEventType.CUSTOM_EVENT, CustomEventType.VIRTUAL_EVENT, CustomEventType.EVENT_GROUP].includes(type),
        )
        .map(
          (customEvent: CustomEventDTO): EventsAndCohorts => ({
            label: customEvent.name,
            value: customEvent.itemExternalId,
            icon: <LucidIconWrapper size={14} name="mouse-pointer-click" />,
            resource_type: getCustomEventDimensionType(customEvent.type),
          }),
        );
      setDimensionList([...tempEvents, ...tempCustomEvents, ...tempCohorts]);
    }
  }, [
    fetchCohorts.isSuccess,
    fetchEvents.isSuccess,
    fetchCohorts.data,
    fetchEvents.data,
    fetchCustomEvents.data,
    fetchCustomEvents.isSuccess,
  ]);
  return {
    isLoading: fetchEvents.isLoading || fetchCohorts.isLoading || fetchCustomEvents.isLoading,
    dimensionsList,
    eventsList: fetchEvents.data || [],
    cohortsList: fetchCohorts.data || [],
    customEventsList: fetchCustomEvents.data || [],
  };
};

/* Includes events & custom_events */
export const useAllEvents = (dimension: string, type: CustomEventType | undefined) => {
  const [dimensionsList, setDimensionList] = useState<EventsAndCohorts[] | []>([]);
  const fetchEvents = useQuery([QueryKeys.GetAllEvents], () => getValuesByEventName(dimension), getUseQueryOptions(2));
  const [eventDimensionList, setEventDimensionList] = useState<EventsAndCohorts[] | []>([]);
  const fetchCustomEvents = useQuery(
    [QueryKeys.GetCustomEvents],
    () =>
      getCustomEvents({
        limit: 100,
        offset: 0,
        currentUserOnly: false,
        type,
      }),
    getUseQueryOptions(),
  );

  useEffect(() => {
    if (fetchEvents.isSuccess && fetchCustomEvents.isSuccess) {
      const tempEvents = fetchEvents.data.map(
        (value: string): EventsAndCohorts => ({
          label: value,
          value,
          icon: <LucidIconWrapper size={14} name="mouse-pointer-click" />,
          resource_type: DimensionType.EVENT,
        }),
      );
      const tempCustomEvents = fetchCustomEvents.data
        .filter((evt) =>
          [CustomEventType.CUSTOM_EVENT, CustomEventType.VIRTUAL_EVENT, CustomEventType.EVENT_GROUP].includes(evt.type),
        )
        .map(
          (customEvent: CustomEventDTO): EventsAndCohorts => ({
            label: customEvent.name,
            value: customEvent.itemExternalId,
            icon: <LucidIconWrapper size={14} name="mouse-pointer-click" />,
            resource_type: getCustomEventDimensionType(customEvent.type),
          }),
        );
      setDimensionList([...tempEvents, ...tempCustomEvents]);
      setEventDimensionList(tempEvents);
    }
  }, [fetchEvents.isSuccess, fetchEvents.data, fetchCustomEvents.data, fetchCustomEvents.isSuccess]);
  return {
    isLoading: fetchEvents.isLoading || fetchCustomEvents.isLoading,
    dimensionsList,
    eventsList: fetchEvents.data || [],
    eventDimensionsList: eventDimensionList,
    customEventsList: fetchCustomEvents.data || [],
  };
};

export const useAllCohorts = (): {
  isLoading: boolean;
  cohorts: EventsAndCohorts[];
} => {
  const [cohortList, setCohortList] = useState<EventsAndCohorts[] | []>([]);
  const fetchCohorts = useQuery([QueryKeys.GetCohorts], getCohortsList, getUseQueryOptions());

  useEffect(() => {
    if (fetchCohorts.isSuccess) {
      const tempCohorts = fetchCohorts.data.map((cohortValue: CohortBasicItem) => ({
        label: cohortValue.name,
        value: cohortValue.itemExternalId,
        icon: <LucidIconWrapper size={14} name="user" />,
        resource_type: DimensionType.COHORT,
      }));
      setCohortList(tempCohorts);
    }
  }, [fetchCohorts.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isLoading: fetchCohorts.isLoading,
    cohorts: cohortList,
  };
};

export const useAllProperties = () => {
  const [properties, setProperties] = useState<PropertiesAndCohorts[] | []>([]);
  const fetchProperties = useQuery([{ key: QueryKeys.GetDetailedDimensions }], getProperties, getUseQueryOptions());

  useEffect(() => {
    if (fetchProperties.isSuccess) {
      const tempProperties = fetchProperties.data
        .filter((i) => i.column !== '__time')
        .map((val: DimensionDetailed) => ({
          label: val.column,
          value: `${val.column}`,
          icon: <LucidIconWrapper size={14} name="square-mouse-pointer" />,
          type: val.dataType as DimensionType,
          propertyCached: val.cached,
        }));
      setProperties(tempProperties);
    }
  }, [fetchProperties.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading: fetchProperties.isLoading,
    properties,
  };
};

/* Includes events & custom_events */
export const useEvents = () => {
  const fetchEvents = useQuery(
    [QueryKeys.GetAllEvents],
    () => getValuesByEventName('event_name'),
    getUseQueryOptions(2),
  );

  return {
    isLoading: fetchEvents.isLoading,
    eventsList: (fetchEvents.data || []).map(
      (value: string): EventsAndCohorts => ({
        label: value,
        value,
        icon: <LucidIconWrapper size={14} name="mouse-pointer-click" />,
        resource_type: DimensionType.EVENT,
      }),
    ),
  };
};

/* Get all properties of given event/events. Do not use custom or virtual events */
export const useEventProperties = (eventNames: string[]) => {
  const [properties, setProperties] = useState<PropertiesAndCohorts[] | []>([]);
  const fetchEventProperties = useQuery(['EventProperties', eventNames], () => getPropertiesByEventName(eventNames), {
    enabled: isLengthyArray(eventNames),
  });

  useEffect(() => {
    if (fetchEventProperties.isSuccess) {
      const tempProperties = uniqBy(fetchEventProperties.data.data, (d) => d.propertyName)
        .filter((i) => i.propertyType !== 'TIMESTAMP')
        .map((val: EventPropertiesApiResItem) => ({
          label: val.propertyName,
          value: `${val.propertyName}`,
          icon: <LucidIconWrapper size={14} name="square-mouse-pointer" />,
          type: DimensionType.PROPERTY,
          propertyCached: false,
        }));
      setProperties(tempProperties);
    }
  }, [fetchEventProperties.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isLoading: fetchEventProperties.isLoading,
    properties,
  };
};

export const useReportStateReset = (reportType: ReportType) => {
  const [searchParams] = useSearchParams();
  const { itemExtId } = useParams<{ itemExtId: string }>();
  const resetInsightStore = useResetAllInsightsAtomValues();
  const resetFunnelStore = useResetAllFunnelAtomValues();
  const resetRetentionStore = useResetAllRetentionAtomValues();
  const resetFlowsStore = useResetAllFlowsAtomValues();

  const resetStore = () => {
    clearReportPerfMarkers();
    switch (reportType) {
      case ReportType.INSIGHT:
        resetInsightStore();
        break;
      case ReportType.FUNNEL:
        resetFunnelStore();
        break;
      case ReportType.RETENTION:
        resetRetentionStore();
        break;
      case ReportType.FLOW:
        resetFlowsStore();
        break;
      default:
        break;
    }
  };

  // new create report flow won't have itemExtId but if user came from report swithcer tab, it will have tab=true in query params
  useEffect(() => {
    if (!itemExtId && !searchParams.get('tab')) {
      resetStore();
    }
  }, [searchParams, itemExtId]); // eslint-disable-line react-hooks/exhaustive-deps

  // reset everything on unmount. reportSwitcherComponet will set from session if unmount is from reportSwitcherTab click
  useEffect(
    () => () => {
      resetStore();
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
};

export const useIsDefaultReport = () => {
  const [searchParams] = useSearchParams();

  return !!searchParams.get('isDefault');
};

export const useIsOnboardingCreatedWorkspace = () => {
  const activeWorkspace = useRecoilValue(activeWorkspaceState);
  if (!activeWorkspace) return false;

  return activeWorkspace.tenantId.startsWith('onb');
};

export const useCheckSdkIntegrationOnHomepage = () => {
  const userData = useRecoilValue(userInfoState);
  const [isSdkLoading, setIsSdkLoading] = useState(true);
  const [isSdkIntegrated, setIsSdkIntegrated] = useState(false);
  useUserInfoQuery();
  const checkSdkViaApiReq = useMutation(() => getSdkItegrationsV2({ interval: 7 }));
  const setIntegratedSdksState = useSetRecoilState(integratedSdksState);

  useEffect(() => {
    if (userData) {
      const bypassSdkCheck = ['supermoney', 'flash', 'scapia', 'slice', 'ekacare', 'udaan', 'dezerv'].includes(
        userData?.activeTenantId ?? '',
      );
      if (bypassSdkCheck) {
        setIsSdkIntegrated(true);
        setIsSdkLoading(false);
        return;
      }
      checkSdkViaApiReq
        .mutateAsync()
        .then((res) => {
          setIntegratedSdksState(res.data);
          setIsSdkIntegrated(isLengthyArray(res.data));
          setIsSdkLoading(false);
        })
        .catch((err) => {
          setIsSdkLoading(false);
          Tracker.trackError(err, ErrorTags.FETCH_SDK_INTEGRATIONS);
        });
    }
  }, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isSdkLoading,
    isSdkIntegrated,
  };
};

export const useIsPiSdkIntegrated = ({
  interval = 7,
}: {
  interval?: number;
}): { isSdkIntegrated: boolean; isSdkLoading: boolean } => {
  const [isSdkLoading, setIsSdkLoading] = useRecoilState(isSdkLoadingState);
  const [sdks, setSdks] = useRecoilState(integratedSdksState);
  const [apiToken, setApiToken] = useRecoilState(sdkApiTokenState);
  const isSdkIntegrated = useRecoilValue(isPISdkIntegratedState);
  const onError = useSetRecoilState(sdkIntegrationErrorState);
  useQuery(['sdkIntegrations', interval], () => getSdkItegrationsV2({ interval }), {
    ...getUseQueryOptions(0, 60 * 60 * 1000, !isSdkIntegrated),
    // enabled: !isSdkIntegrated,
    onSuccess: (res) => {
      setIsSdkLoading(false);
      if (apiToken !== res.apikey) setApiToken(res.apikey);
      const map = new Map<string, SdkInfoResV2>();
      [...sdks, ...res.data].forEach((item) => map.set(item.sdkType, item));
      setSdks(Array.from(map.values()));
    },
    onError: (err: Error) => {
      setIsSdkLoading(false);
      onError(err);
      Tracker.trackError(err, ErrorTags.FETCH_SDK_INTEGRATIONS);
    },
  });

  return { isSdkIntegrated, isSdkLoading };
};

export const useVirtualEventsList = () => {
  const { data, isLoading, error } = useQuery(
    [QueryKeys.GetCustomEvents],
    () =>
      getCustomEvents({
        limit: 100,
        offset: 0,
        currentUserOnly: false,
      }),
    getUseQueryOptions(),
  );

  return { data, isLoading, error };
};

export const useVirtualAllEventInfo = () => {
  const { data, isLoading, error } = useVirtualEventsList();

  return { virtualAllEvent: data ? data.find((d) => d.name === '$All Events') : undefined, isLoading, error };
};

export const useNewInsightComputeFns = () => {
  const userInfo = useRecoilValue(userInfoState);

  return ['scapia', 'percept', 'raptorise', 'udaan'].includes(userInfo?.activeTenantId ?? '');
};

export enum CustomEventEmitterKeys {
  showSessionExpiredDialog = 'showSessionExpiredDialog',
}

export type CustomEventEmitterDetails = {
  title?: string;
  description?: string;
  onCancel?: () => void;
  onOk?: () => void;
};

import { AuthenticationResult } from '@azure/msal-browser';
import { googleLogout } from '@react-oauth/google';
import Tracker from '@src/client/lib/analytics/tracker';
import { Cookie } from '@src/client/lib/api/constants';
import { OauthProvider } from '@src/client/lib/api/types/request';
import { LoginWithAuthCodeResponse } from '@src/client/lib/api/types/response';
import { getExpiryFromMsAuthToken, msalLogout } from '@src/client/lib/msal-utils';
import { clearActiveWorkspaceDetailsFromLocalStorage } from '@src/client/lib/utils';
import { Cookies } from 'react-cookie';

export const removeAuthCookies = () => {
  const cookies = new Cookies();
  cookies.remove(Cookie.id_token, {
    path: '/',
  });
  cookies.remove(Cookie.refresh_token, {
    path: '/',
  });
  cookies.remove(Cookie.auth_type, {
    path: '/',
  });
  cookies.remove(Cookie.id_token_expiry, {
    path: '/',
  });
};

export const logoutCleanup = () => {
  Tracker.logout();
  clearActiveWorkspaceDetailsFromLocalStorage();
};

export const authProviderLogout = async (authProvider: OauthProvider) => {
  if (authProvider === OauthProvider.google) {
    googleLogout();
  }
  if (authProvider === OauthProvider.microsoft) {
    await msalLogout();
  }
};

export const logoutHandler = async () => {
  const cookies = new Cookies();
  const authType = cookies.get(Cookie.auth_type);
  removeAuthCookies();
  logoutCleanup();
  await authProviderLogout(authType);
};

export const redirectToLogin = (replace = false) => {
  if (replace) window.location.replace('/login');
  else {
    window.location.href = '/login';
  }
};

export const setGoogleLoginAuthCookies = (loginResponse: LoginWithAuthCodeResponse) => {
  const cookies = new Cookies();
  const cookieExpiry = new Date(new Date().setSeconds(new Date().getSeconds() + loginResponse.expires_in_seconds));
  cookies.set(Cookie.id_token, loginResponse.id_token, {
    path: '/',
    sameSite: 'strict',
    expires: cookieExpiry,
  });
  cookies.set(Cookie.refresh_token, loginResponse.refresh_token, {
    path: '/',
    sameSite: 'strict',
    expires: new Date(new Date().setSeconds(new Date().getSeconds() + 86400 * 30)), // expires in 1 month
  });
  cookies.set(Cookie.id_token_expiry, cookieExpiry, {
    path: '/',
    sameSite: 'strict',
  });
  cookies.set(Cookie.auth_type, OauthProvider.google, {
    path: '/',
    sameSite: 'strict',
  });
};

export const setMicrosoftLoginAuthCookies = (msResponse: AuthenticationResult) => {
  const cookies = new Cookies();
  const expriryTime = getExpiryFromMsAuthToken(msResponse.idToken);
  const expiresOn = new Date(parseInt(expriryTime, 10) * 1000);
  const cookieExpiry = expiresOn !== null ? expiresOn : new Date(new Date().setSeconds(new Date().getSeconds() + 1800));
  cookies.set(Cookie.id_token, msResponse.idToken, {
    path: '/',
    sameSite: 'strict',
    expires: cookieExpiry,
  });
  cookies.set(Cookie.auth_type, OauthProvider.microsoft, {
    path: '/',
    sameSite: 'strict',
  });
  cookies.set(Cookie.id_token_expiry, cookieExpiry, {
    path: '/',
    sameSite: 'strict',
  });
};

import { RetentionSubtypeEnum } from '@src/client/helpers/reports/constants';
import { Select, SelectOptionsType } from '@src/client/ui-library/select';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';

import { dimensionsStateV2 } from '../dimension-filter/state';

export const retentionSubtypeState = atom<RetentionSubtypeEnum>({
  key: 'retentionSubtypeState',
  default: RetentionSubtypeEnum.repeat,
});

export default function RetentionSubtypeSelector() {
  const setDimensions = useSetRecoilState(dimensionsStateV2);
  const [subType, setSubtype] = useRecoilState(retentionSubtypeState);

  const retentionSubtypeOptions: SelectOptionsType[] = [
    { label: 'Retention Curve', value: RetentionSubtypeEnum.retention_table },
    { label: 'Repeat', value: RetentionSubtypeEnum.repeat },
  ];

  function handleChangeSubtype(newSubtype: RetentionSubtypeEnum) {
    if (newSubtype !== subType && newSubtype === RetentionSubtypeEnum.retention_table) {
      setDimensions((existingDimensions) => {
        if (existingDimensions.length <= 2) {
          return existingDimensions;
        }
        return [existingDimensions[0], existingDimensions[1]];
      });
    }
    setSubtype(newSubtype);
  }

  const convertToLabel = (retentionSubtype: RetentionSubtypeEnum): SelectOptionsType => {
    if (retentionSubtype === RetentionSubtypeEnum.retention_table) {
      return retentionSubtypeOptions[0];
    }
    return retentionSubtypeOptions[1];
  };

  return (
    <Select
      className="w-[170px]"
      value={convertToLabel(subType)}
      onChange={(value) => handleChangeSubtype((value as SelectOptionsType).value as RetentionSubtypeEnum)}
      options={retentionSubtypeOptions}
      selectClassNames={{ control: 'rounded', singleValue: 'font-semibold capitalize text-xs', menu: 'w-full' }}
    />
  );
}

import { atom } from 'recoil';

export const alertDialogOpenState = atom<boolean>({
  key: 'alertDialogOpenState',
  default: false,
});

export const reportSaveConfirmationDialogOpenState = atom<boolean>({
  key: 'reportSaveConfirmationDialogOpenState',
  default: false,
});

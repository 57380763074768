import { DataLockIcon } from '../../ui-library/icons/ReportIcons';

export default function PrivateDimensionError() {
  return (
    <div className="flex justify-center">
      <div className="grid grid-cols-1">
        <div>
          <DataLockIcon />
        </div>
        <div>
          <p className="text-sky-400 text-lg">Report contains private data</p>
        </div>
        <div>
          <p className="text-slate-400 hover:text-sky-400 text-base">
            This content is currently inaccessible due to containing private data. Please reach out to your
            administrator to request access.
          </p>
        </div>
      </div>
    </div>
  );
}

export const isPrivateDimensionError = (err: Error): boolean => err.message === 'Unprocessable Entity';

import { Button } from '@src/client/ui-library/button';
import { DeleteIcon } from '@src/client/ui-library/icons/DashboardIcons';
import { useRecoilState } from 'recoil';

import { groupFiltersState } from '../recoil/atoms';
import { EventTypeRow, FilterRowState, PropertyType } from '../types';
import AddFilterRowButton from './AddFilterRowButton';
import FilterRow from './FilterRow';
import GroupOperatorSelector from './GroupOperatorSelector';

interface GroupFilterProps {
  grpIndex: number;
}

function GroupFilterHeader({ deleteGroup }: { deleteGroup: () => void }) {
  return (
    <div className="items-start justify-between flex text-sm">
      <span>All Users</span>
      <Button variant="icon" onClick={deleteGroup}>
        <DeleteIcon />
      </Button>
    </div>
  );
}

function GroupFilter({ grpIndex }: GroupFilterProps) {
  const [groupFilters, setGroupFilters] = useRecoilState(groupFiltersState);

  groupFilters[grpIndex]?.filters.sort((a, b) => {
    // eslint-disable-next-line no-nested-ternary
    const aType = a.event.type === 'EVENT' ? 0 : a.event.type === 'COHORT' ? 1 : 2;
    // eslint-disable-next-line no-nested-ternary
    const bType = b.event.type === 'EVENT' ? 0 : b.event.type === 'COHORT' ? 1 : 2;
    return bType - aType;
  });

  // use this after backend is ready for multi group operator
  // const [groupOperator, setGroupOperator] = useMemo(() => {
  //   const sGO = (val: LogicalOperatorType) => {
  //     setGroupFilters((prev) => {
  //       const temp = [...prev];
  //       temp[grpIndex] = {
  //         ...temp[grpIndex],
  //         operator: val,
  //       };
  //       return temp;
  //     });
  //   };
  //   const gO = groupFilters[grpIndex]?.operator;
  //   return [gO, sGO];
  // }, [groupFilters, grpIndex, setGroupFilters]);

  const deleteGroup = () => {
    setGroupFilters((prev) => {
      const temp = [...prev];
      temp.splice(grpIndex, 1);
      return temp;
    });
  };
  const deleteFilterFromGroup = (index: number) => {
    setGroupFilters((prev) =>
      prev.map((group, i) => {
        if (i !== grpIndex) return group;
        const newGroupFilters = group.filters.filter((f, j) => j !== index);
        const nonEventFilters = newGroupFilters.filter((f) => f.event.type !== PropertyType.EVENT);
        const eventFilters = newGroupFilters
          .filter((f) => f.event.type === PropertyType.EVENT)
          .sort((a, _) => (a.event.dnd && a.event.dnd ? 1 : -1));
        const updatedFilters = [...nonEventFilters, ...eventFilters];
        const firstEventFilterIndex = updatedFilters.findIndex((f) => f.event.type === PropertyType.EVENT);
        if (firstEventFilterIndex !== -1) {
          updatedFilters[firstEventFilterIndex] = {
            ...updatedFilters[firstEventFilterIndex],
            event: { ...updatedFilters[firstEventFilterIndex].event, dnd: undefined },
          };
        }
        return { ...group, filters: updatedFilters };
      }),
    );
  };

  const addNewGroup = (defaultValue: EventTypeRow) => {
    setGroupFilters((prev) => {
      const temp = [...prev];
      const filter: FilterRowState = {
        operator: 'and',
        event: defaultValue,
        key: Math.random(),
      };
      temp[grpIndex] = {
        ...temp[grpIndex],
        filters: [...temp[grpIndex].filters, filter],
      };
      return temp;
    });
  };

  return (
    <div>
      {grpIndex !== 0 ? <GroupOperatorSelector /> : null}
      <div className="px-4 py-4">
        <div className="flex flex-grow flex-col">
          <GroupFilterHeader deleteGroup={deleteGroup} />
          <div className="flex flex-col space-y-2">
            {groupFilters[grpIndex]?.filters.map((fields, idx) => (
              <FilterRow key={fields.key} rowIndex={idx} grpIndex={grpIndex} remove={deleteFilterFromGroup} />
            ))}
          </div>
          <div className="flex mt-3">
            <AddFilterRowButton addNewGroup={addNewGroup} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupFilter;

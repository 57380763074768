import GenericErrorView from '@src/client/components/generic-error-view';
import { LogicalOperatorType } from '@src/client/helpers/reports/types';
import { Select, SelectOptionsType } from '@src/client/ui-library/select';
import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { groupFiltersState } from '../recoil/atoms';
import { EventTypeRow, PropertyType } from '../types';
import CohortFilterRow from './CohortFilterRow';
import EventFilterRow from './EventFilterRow';
import UserPropertyFilterRow from './UserPropertyFilterRow';

interface CohortFilterRowProps {
  remove: (index: number) => void;
  grpIndex: number;
  rowIndex: number;
}

export default function FilterRow({ grpIndex, rowIndex, remove }: CohortFilterRowProps) {
  const [groupFilters, setGroupFilters] = useRecoilState(groupFiltersState);

  const onChange = useCallback(
    (event: EventTypeRow) => {
      setGroupFilters((prev) => {
        const temp = [...prev];
        if (temp[grpIndex]) {
          const temp2 = [...temp[grpIndex].filters];
          temp2[rowIndex] = { ...temp2[rowIndex], event };
          temp[grpIndex] = { ...temp[grpIndex], filters: temp2 };
        }
        return temp;
      });
    },
    [grpIndex, rowIndex, setGroupFilters],
  );

  const [filterOperator, setFilterOperator, value] = useMemo(() => {
    const sFO = (val: LogicalOperatorType) => {
      setGroupFilters((prev) => {
        const temp = [...prev];
        if (temp[grpIndex]) {
          temp[grpIndex] = { ...temp[grpIndex], operator: val };
        }
        return temp;
      });
    };
    const gO = groupFilters[grpIndex]?.operator;
    const v = groupFilters[grpIndex]?.filters[rowIndex]?.event;
    return [gO, sFO, v];
  }, [groupFilters, grpIndex, rowIndex, setGroupFilters]);

  const onRowValueChange = useCallback((val: EventTypeRow) => onChange(val), [onChange]);

  const getGroupOperatorOptions = [
    { label: 'and', value: 'and' },
    {
      label: 'or',
      value: 'or',
      isDisabled: groupFilters[grpIndex].filters.some((f) => f.event.dnd),
      tooltip: `Cannot use 'or' when one of the filters have 'did not'`,
    },
  ];

  const groupOperatorSelector = (
    <Select
      className="w-20 bg-primary-light dark:bg-foreground-secondary rounded-xl shadow-none border-0"
      options={getGroupOperatorOptions}
      value={getGroupOperatorOptions.find((item: unknown) => (item as SelectOptionsType).value === filterOperator)}
      onChange={(val) => setFilterOperator((val as SelectOptionsType).value as LogicalOperatorType)}
    />
  );

  if (value && (value?.type as PropertyType) === PropertyType.COHORT) {
    return (
      <CohortFilterRow
        rowIndex={rowIndex}
        remove={remove}
        value={value}
        onChange={onRowValueChange}
        groupOperatorSelector={groupOperatorSelector}
      />
    );
  }
  if ((value?.type as PropertyType) === PropertyType.EVENT) {
    return (
      <EventFilterRow
        rowIndex={rowIndex}
        remove={remove}
        value={value}
        onChange={onRowValueChange}
        onGroupOperatorChange={setFilterOperator}
        groupOperatorSelector={groupOperatorSelector}
        enableDidNotFilter={
          groupFilters[grpIndex].filters.filter((f) => !f.event.dnd && f.event.type === PropertyType.EVENT).length > 1
        }
        isFirstEventRow={
          groupFilters[grpIndex].filters.findIndex((f) => f.event.type === PropertyType.EVENT) === rowIndex
        }
      />
    );
  }
  if (value && (value.type as PropertyType) === PropertyType.USER) {
    return (
      <UserPropertyFilterRow
        rowIndex={rowIndex}
        remove={remove}
        value={value}
        onChange={onRowValueChange}
        groupOperatorSelector={groupOperatorSelector}
      />
    );
  }

  return <GenericErrorView error={`Invalid type ${value?.type} selected. Kindly cancel the operation`} />;
}

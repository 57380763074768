/* eslint-disable import/prefer-default-export */
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const ActiveClassNames = 'text-foreground-selected flex items-center bg-foreground-secondary';

export const HoverableClassNames = 'bg-transparent hover:bg-foreground-secondary text-foreground';

export const HoverableClassNamesWithActiveState =
  'bg-transparent hover:bg-foreground-secondary text-foreground data-[state=open]:bg-foreground-secondary data-[state=open]:text-foreground-selected  dark:data-[state=open]:text-foreground-selected dark:data-[state=open]:bg-foreground-secondary';

export const HoverableClassNamesWithOnState =
  'bg-transparent hover:bg-foreground-secondary text-foreground data-[state=on]:bg-foreground-secondary data-[state=on]:text-foreground-selected dark:data-[state=on]:text-foreground-selected dark:data-[state=on]:bg-foreground-secondary';

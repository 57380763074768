import { ReactNode, useState } from 'react';

import { cn } from '../utils';
import { Tabs as TabsPrimitive, TabsContent, TabsList, TabsTrigger } from './TabsPrimitives';

interface Props {
  tabItems: Array<{
    key: string;
    label: ReactNode;
    children: ReactNode;
  }>;
  defaultActiveKey: string;
  onChange: (key: string) => void;
  tabListClassName?: string;
  tabContentClassName?: string;
  tabRootClassName?: string;
}

export default function Tabs({
  tabItems,
  defaultActiveKey,
  onChange,
  tabListClassName,
  tabContentClassName,
  tabRootClassName,
}: Props) {
  const [activeTab, setActiveTab] = useState<string>(defaultActiveKey);
  const onTabChange = (key: string) => {
    setActiveTab(key);
    onChange(key);
  };

  return (
    <TabsPrimitive value={activeTab} onValueChange={onTabChange} className={tabRootClassName ?? ''}>
      <TabsList className={cn('w-full flex border-0', tabListClassName)}>
        {tabItems.map((item) => (
          <TabsTrigger value={item.key} key={`${item.key}tab-trigger`} className="flex-1">
            {item.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {tabItems.map((item) => (
        <TabsContent value={item.key} key={`${item.key}tab-content`} className={tabContentClassName ?? ''}>
          {item.children}
        </TabsContent>
      ))}
    </TabsPrimitive>
  );
}

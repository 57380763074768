import { Switch } from '@src/client/ui-library/switch';
import { useTheme } from '@src/client/ui-library/theme-provider';
import { useCallback } from 'react';

export default function ThemeToggler() {
  const { theme, setTheme } = useTheme();

  const handleThemeChange = useCallback(
    (darkModeChecked: boolean) => {
      setTheme(darkModeChecked ? 'dark' : 'light');
    },
    [theme], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <Switch id="dark-mode" checked={theme === 'dark'} onCheckedChange={handleThemeChange} />;
}

import { HomeIcon, YourBoardsIcon, YourReportsIcon } from '../../ui-library/icons/HomepageIcons';
import { HomeNavItemType } from './types';

export const RECENTLY_VIEWED_PAGE_SIZE = 50;
export const SEARCH_PAGE_SIZE = 100;

export enum HomeScreenNavItemKey {
  Home = 'Home',
  YourReports = 'YourReports',
  DefaultDashboard = 'DefaultDashboard',
  YourBoards = 'YourBoards',
}

export const HomeScreenNavItems = [
  HomeScreenNavItemKey.Home,
  HomeScreenNavItemKey.YourReports,
  // HomeScreenNavItemKey.DefaultDashboard,
  HomeScreenNavItemKey.YourBoards,
];

export const HomeScreenNavItemData: Record<HomeScreenNavItemKey, HomeNavItemType> = {
  [HomeScreenNavItemKey.Home]: {
    title: 'Home',
    icon: <HomeIcon />,
    path: 'home',
  },
  [HomeScreenNavItemKey.YourReports]: {
    title: 'Your Reports',
    icon: <YourReportsIcon />,
    path: 'my-reports',
  },
  [HomeScreenNavItemKey.DefaultDashboard]: {
    title: 'Default Dashboard',
    icon: <YourReportsIcon />,
    path: 'insights-hub',
  },
  [HomeScreenNavItemKey.YourBoards]: {
    title: 'Your Boards',
    icon: <YourBoardsIcon />,
    path: 'dashboard',
  },
};

export const SampleReportGridImages = [
  '/images/v2/report-cards/line-graph-img-2.png',
  '/images/v2/report-cards/bar-graph-img.png',
  '/images/v2/report-cards/flow-graph-img.png',
  '/images/v2/report-cards/line-graph-img.png',
];

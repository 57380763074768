import { Button } from '@src/client/ui-library/button';
import { PlusIcon } from '@src/client/ui-library/icons/HomepageIcons';
import { Label } from '@src/client/ui-library/label';
import { useRecoilState } from 'recoil';

import { groupFiltersState } from '../recoil/atoms';
import GroupFilter from './GroupFilter';

export default function FilterSelectionArea() {
  const [groupFilters, setGroupFilters] = useRecoilState(groupFiltersState);

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col">
        <Label>Cohort Groups</Label>
        {groupFilters.length > 0 ? (
          <div className="mt-2 border border-border rounded-xl">
            {groupFilters.map((grp, idx) => (
              <GroupFilter key={grp.key} grpIndex={idx} />
            ))}
          </div>
        ) : null}
      </div>
      <div className="flex">
        <Button
          variant="outline"
          className="py-1 px-2"
          onClick={() => setGroupFilters([...groupFilters, { operator: 'and', filters: [], key: Math.random() }])}
        >
          <PlusIcon stroke="#155EEF" />
          <span className="mx-2">Group</span>
        </Button>
      </div>
    </div>
  );
}

import {
  FILTER_CONNECTOR_DROPDOWN_OPTIONS,
  FILTER_OPERATORS,
  FILTER_OPERATORS_DROPDOWN_OPTIONS,
  FILTER_OPERATORS_WITH_MULTI_VALUE_SUPPORT,
  FILTER_OPERATORS_WITH_RANGE_VALUES,
  FILTER_OPERATORS_WITH_STRING_INPUT,
  FILTER_OPERATORS_WITHOUT_VALUE,
  FilterOperatorEnums,
} from '@src/client/components/filters-and-selectors/global-property-filter/constants';
import { useAllProperties } from '@src/client/helpers/reports/hooks';
import { Filter, PropertiesAndCohorts } from '@src/client/helpers/reports/types';
import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { searchDimensionValues } from '@src/client/lib/api/queries/common';
import { convertToLabelValue, convertToValue } from '@src/client/lib/utils';
import { Button } from '@src/client/ui-library/button';
import { DeleteIcon } from '@src/client/ui-library/icons/DashboardIcons';
import { DebouncedInput } from '@src/client/ui-library/input';
import { Select, SelectOptionsType } from '@src/client/ui-library/select';
import AsyncCreatableSelect from '@src/client/ui-library/select/AsyncCreatableSelect';
import { debounce, isEmpty } from 'lodash-es';
import { matchSorter } from 'match-sorter';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { GroupBase, OptionsOrGroups } from 'react-select';

export default function InlineEventFiltersRow({
  filter,
  filterIndex,
  removeFilter,
  onChange,
}: {
  filter: Filter;
  filterIndex: number;
  removeFilter: (filterIdx: number) => void;
  onChange: (newFilter: Filter, filterIdx: number) => void;
}) {
  const { loading, properties } = useAllProperties();

  const propertyValuesSearch = useMutation(searchDimensionValues, {
    retry: 1,
    onSuccess: (_response) => {},
    onError: (err: Error, variables) => {
      Tracker.trackError(err, ErrorTags.DIMENSION_VALUES_SEARCH_ERROR, {
        dimension: variables.dimension,
        searchString: variables.searchString,
        limit: variables.limit,
        offset: variables.offset,
      });
    },
  });

  const loadPropertyValues = useCallback(
    (
      inputValue: string,
      cb: (options: SelectOptionsType[]) => void,
    ): Promise<
      OptionsOrGroups<PropertiesAndCohorts | SelectOptionsType, GroupBase<PropertiesAndCohorts | SelectOptionsType>>
    > | void => {
      if (!filter?.property) {
        cb([]);
        return;
      }
      propertyValuesSearch
        .mutateAsync({
          dimension: typeof filter?.property === 'string' ? filter?.property : '',
          limit: 100,
          searchString: inputValue.length < 1 ? '<all>' : inputValue,
          offset: 0,
        })
        .then((dimensionValuesResponse) => {
          const convertedValues = convertToLabelValue(dimensionValuesResponse);
          const dimensionVals = Array.isArray(convertedValues) ? convertedValues : [convertedValues];
          cb(matchSorter(dimensionVals.filter((val) => !isEmpty(val.value)) || [], inputValue, { keys: ['label'] }));
        })
        .catch((err: Error) => {
          Tracker.trackError(err, ErrorTags.DIMENSION_VALUES_SEARCH_ERROR, {
            dimension: filter?.property,
            searchString: inputValue,
            limit: 100,
            offset: 0,
          });
          cb([]);
        });
    },
    [filter?.values], // eslint-disable-line  react-hooks/exhaustive-deps
  );

  const debouncedLoadProperties = debounce(loadPropertyValues, 500);

  const handleFilterValueSelection = (val: unknown) => {
    const newFilter: Filter = { ...filter, values: convertToValue(val as SelectOptionsType | SelectOptionsType[]) };
    onChange(newFilter, filterIndex);
  };

  const handleFilterOperatorChange = (val: unknown) => {
    const newFilter: Filter = {
      ...filter,
      operator: (val as SelectOptionsType).value as FilterOperatorEnums,
      values: FILTER_OPERATORS_WITH_MULTI_VALUE_SUPPORT.includes(
        (val as SelectOptionsType).value as FilterOperatorEnums,
      )
        ? []
        : '',
    };
    onChange(newFilter, filterIndex);
  };

  const handlePropertySelection = (val: unknown) => {
    const newFilter = {
      ...filter,
      property: (val as SelectOptionsType).value,
      operator: FILTER_OPERATORS[0],
      values: [],
    };
    onChange(newFilter, filterIndex);
  };

  return (
    <div className="flex items-center justify-start gap-x-2">
      {filterIndex === 0 ? (
        <span>where</span>
      ) : (
        <Select
          options={FILTER_CONNECTOR_DROPDOWN_OPTIONS}
          selectClassNames={{
            control: 'w-20  py-0 !min-h-[24px]',
          }}
          value={{ label: filter.map! === '&' ? 'And' : 'Or', value: filter.map! }}
          onChange={(val) => {
            const newFilter = { ...filter, map: (val as SelectOptionsType).value };
            onChange(newFilter, filterIndex);
          }}
        />
      )}
      <Select
        className="min-w-200"
        isLoading={loading}
        value={filter.property ? convertToLabelValue(filter.property) : undefined}
        onChange={handlePropertySelection}
        options={properties}
        placeholder="Select a property..."
      />
      {filter.property ? (
        <div className="inline-flex items-center justify-start space-x-1 mt-1.5">
          <Select
            options={FILTER_OPERATORS_DROPDOWN_OPTIONS.filter(
              (f) =>
                !FILTER_OPERATORS_WITHOUT_VALUE.includes(f.value as FilterOperatorEnums) &&
                !FILTER_OPERATORS_WITH_RANGE_VALUES.includes(f.value as FilterOperatorEnums),
            )}
            value={{ label: filter.operator, value: filter.operator }}
            onChange={handleFilterOperatorChange}
            components={{ DropdownIndicator: null }}
            selectClassNames={{
              control: 'rounded-lg w-[80px] border-none bg-[#EFF4FF] dark:bg-[#697586] !min-h-[32px]',
              menu: '!w-[150px]',
              container: 'mr-2',
              placeholder: 'dark:text-[#CDD5DF]',
              singleValue: 'text-sm dark:text-[#CDD5DF]',
            }}
          />
          {FILTER_OPERATORS_WITH_STRING_INPUT.includes(filter.operator) ? (
            <DebouncedInput
              key={filter?.property.toString()}
              type="text"
              value={filter.values as string}
              onChange={(val) => handleFilterValueSelection({ label: val, value: val })}
              placeholder={filter.operator}
            />
          ) : (
            <AsyncCreatableSelect
              key={filter?.property.toString()}
              isMulti={FILTER_OPERATORS_WITH_MULTI_VALUE_SUPPORT.includes(filter.operator)}
              value={
                FILTER_OPERATORS_WITH_MULTI_VALUE_SUPPORT.includes(filter.operator)
                  ? (filter.values as string[]).map((f) => ({ value: f, label: f }))
                  : { label: filter.values as string, value: filter.values as string }
              }
              onChange={handleFilterValueSelection}
              components={{ DropdownIndicator: null }}
              loadOptions={debouncedLoadProperties}
              placeholder="Search for values"
              defaultOptions
              selectClassNames={{
                container: 'flex-1 min-w-[220px] max-w-[600px]',
                control: 'rounded-lg bg-[#EFF4FF] dark:bg-[#697586] !min-h-[32px]',
                placeholder: 'dark:text-[#CDD5DF]',
                singleValue: 'text-sm dark:text-[#CDD5DF]',
              }}
            />
          )}
        </div>
      ) : null}
      <Button variant="icon" onClick={() => removeFilter(filterIndex)}>
        <DeleteIcon />
      </Button>
    </div>
  );
}

import { ValueType } from '@src/client/helpers/reports/constants';
import { LineChartData } from '@src/client/helpers/reports/types';
import { isLengthyArray } from '@src/client/lib/utils';
import { useMemo, useRef } from 'react';
import { useResizeObserver } from 'usehooks-ts';

import LineChartSvg from './LineChartSvg';
import { getFormattedLineDataPoints } from './utils';

interface Props {
  lineChartData: LineChartData[];
  valueType?: ValueType;
  logarithmicScale?: boolean;
  chartContainerHeight?: number;
}

export default function D3TinyLineChart({
  lineChartData,
  valueType = ValueType.NUMBER,
  logarithmicScale,
  chartContainerHeight = 200,
}: Props) {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const allLineDataPoints = useMemo(() => getFormattedLineDataPoints(lineChartData, false), [lineChartData]);
  const { width: chartContainerWidth = 0 } = useResizeObserver({
    ref: chartContainerRef,
    box: 'border-box',
  });

  return (
    <div className="relative h-full px-4" ref={chartContainerRef}>
      {chartContainerWidth > 0 && isLengthyArray(allLineDataPoints) ? (
        <LineChartSvg
          lineData={allLineDataPoints}
          width={chartContainerWidth}
          height={chartContainerHeight}
          valueType={valueType}
          logarithmicScale={logarithmicScale}
          hideXAxis
          hideYAxis
          isTinyChart
        />
      ) : null}
    </div>
  );
}

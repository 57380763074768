import { workspaceIcons } from '@src/client/components/site-navbar/WorkspaceIcons';
import { UserInfo, Workspace } from '@src/client/lib/api/types/response';
import { Button } from '@src/client/ui-library/button';
import { useState } from 'react';

import { WorkspaceByOrg } from '../types';

export function SelectWorkspaceSection({
  userInfo,
  workspaces,
  onWsChange,
}: {
  userInfo?: UserInfo;
  workspaces: WorkspaceByOrg[];
  onWsChange: (ws: Workspace, redirect: string) => void;
}) {
  const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceByOrg>();

  return (
    <div className="flex flex-col p-8 pt-4 items-center">
      <div className="flex flex-col h-[225px] gap-4 overflow-y-scroll w-full">
        {workspaces.map((ws, index) => (
          <Button
            variant="outline"
            key={ws.name}
            className={`font-bold flex gap-3 justify-start cursor-pointer p-2 border rounded-lg ${
              ws === selectedWorkspace ? 'border border-primary' : ''
            }`}
            onClick={() => setSelectedWorkspace(ws)}
          >
            {ws.logoUrl && ws.logoUrl !== 'https://percept.udaan.io/images/pi_logo_color.svg' ? (
              <img src={ws.logoUrl} className="h-6 w-6" alt={`${ws?.name} logo`} />
            ) : (
              workspaceIcons[index % 3]
            )}
            {ws.name}
          </Button>
        ))}
      </div>
      {userInfo && selectedWorkspace && !userInfo.workspaces.some((ws) => ws.id === selectedWorkspace.workspaceId) ? (
        <div className="mt-2 p-2 rounded-lg text-foreground">
          Request access from the workspace owner.
          <p className="text-center font-semibold">{selectedWorkspace?.createdBy}</p>
        </div>
      ) : (
        <Button
          className="mt-4 w-full"
          disabled={selectedWorkspace === undefined}
          onClick={() => {
            if (selectedWorkspace && userInfo)
              onWsChange(
                {
                  id: selectedWorkspace.workspaceId,
                  logoUrl: selectedWorkspace.logoUrl,
                  name: selectedWorkspace.name,
                  tenantId: selectedWorkspace.tenantId,
                },
                `/${selectedWorkspace.workspaceId}/home`,
              );
          }}
        >
          Next →
        </Button>
      )}
    </div>
  );
}

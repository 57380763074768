// NOTE: Radix primittives don't support multi select right now. Using 'react-select' for now. Some other inspiration using 'cmdk' package and other radix primitives
// https://github.com/mxkaske/mxkaske.dev/blob/main/components/craft/fancy-multi-select.tsx
// https://craft.mxkaske.dev/post/fancy-multi-select

import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import ReactSelect, { components as ReactSelectComponents, createFilter } from 'react-select';

import { cn, HoverableClassNames } from '../utils';
import { CustomMultiValue, CustomOption, NoOptionsMessageSync } from './CustomSelectComponents';
import { SelectClassNames } from './types';
import { VirtualMenuList } from './VirtualMenuList';

export const Select = React.forwardRef<
  React.ElementRef<typeof ReactSelect>,
  React.ComponentPropsWithoutRef<typeof ReactSelect> & {
    selectClassNames?: SelectClassNames;
    useVirtualMenuList?: boolean;
  }
>(
  (
    {
      isMulti,
      autoFocus,
      value,
      isLoading,
      options,
      menuIsOpen,
      placeholder,
      selectClassNames,
      onChange,
      onBlur,
      components = {},
      useVirtualMenuList,
      ...props
    },
    ref,
  ) => {
    const [selectOptions, setSelectOptions] = useState(options);

    useEffect(() => {
      setSelectOptions(options);
    }, [options]);

    return (
      <ReactSelect
        ref={ref}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        autoFocus={autoFocus}
        openMenuOnFocus
        hideSelectedOptions={false}
        value={value}
        isLoading={isLoading}
        onChange={onChange}
        options={selectOptions}
        onInputChange={(inputValue) => {
          setSelectOptions(matchSorter(options || [], inputValue, { keys: ['label'] }));
        }}
        unstyled
        onBlur={onBlur}
        menuIsOpen={menuIsOpen}
        placeholder={placeholder}
        components={{
          Option: CustomOption,
          NoOptionsMessage: NoOptionsMessageSync,
          MultiValue: CustomMultiValue,
          MenuList: useVirtualMenuList ? VirtualMenuList : ReactSelectComponents.MenuList,
          ...components,
        }}
        filterOption={createFilter({ ignoreAccents: false })}
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 20 }),
        }}
        classNames={{
          control: (_state) =>
            cn('border border-border rounded px-2 py-0.5', HoverableClassNames, selectClassNames?.control),
          menu: (_state) =>
            cn(
              'border border-border bg-popover text-popover-foreground shadow-md p-2 mt-2 rounded z-20',
              selectClassNames?.menu,
            ),
          // option: (state) =>
          // cn(
          //   'cursor-default truncate select-none items-center rounded py-1.5 px-2 mb-1 text-sm outline-none bg-transparent hover:bg-primary-light hover:dark:bg-foreground-secondary focus:bg-primary-light focus:dark:bg-foreground-secondary',
          //   state.isSelected ? 'bg-primary-light dark:bg-foreground-secondary' : '',
          // ),
          singleValue: (_state) => cn('text-sm font-normal text-foreground-selected', selectClassNames?.singleValue),
          multiValue: (_state) =>
            cn(
              'bg-background border border-border dark:border-0 rounded px-2 py-0.5 select-none text-foreground-selected',
              selectClassNames?.multiValue,
            ),
          multiValueLabel: (_state) => 'text-xs text-foreground font-normal bg-transparent',
          multiValueRemove: (_state) => 'text-foreground',
          placeholder: (_state) => cn('text-sm font-medium text-foreground-light', selectClassNames?.placeholder ?? ''),
          indicatorsContainer: (_state) => cn('text-foreground-selected', selectClassNames?.indicatorsContainer),
          dropdownIndicator: (_state) => cn('', selectClassNames?.dropdownIndicator),
          loadingMessage: (_state) => 'text-sm font-semibold text-foreground',
          container: (state) =>
            cn(state.isDisabled ? 'opacity-50 !cursor-not-allowed' : '', selectClassNames?.container),
          menuList: (_state) => cn('overflow-x-hidden', selectClassNames?.menuList),
        }}
        {...props}
      />
    );
  },
);

Select.displayName = 'Select';

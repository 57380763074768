import { Button } from '@src/client/ui-library/button';
import { PlusIcon } from '@src/client/ui-library/icons/HomepageIcons';
import { Select } from '@src/client/ui-library/select';
import { useState } from 'react';

import { useAllCohortSelectDropdownOptions } from '../hooks';
import { CohortCreateDropdownOptionType, EventTypeRow, PropertyType } from '../types';

interface AddFilterRowButtonProps {
  addNewGroup: (defaultValue: EventTypeRow, insertIndex?: number | undefined) => void;
}

export default function AddFilterRowButton({ addNewGroup }: AddFilterRowButtonProps) {
  const [isButtonMode, setIsButtonMode] = useState<boolean>(true);

  const { loading, options } = useAllCohortSelectDropdownOptions();

  const onPropertySelect = (property: string, type: PropertyType) => {
    const filterRowValue: EventTypeRow = {
      type,
      property,
    };
    setIsButtonMode(true);
    if (filterRowValue.type === PropertyType.COHORT) addNewGroup(filterRowValue, 0);
    else if (filterRowValue.type === PropertyType.USER) addNewGroup(filterRowValue, 0);
    else if (filterRowValue.type === PropertyType.EVENT) addNewGroup(filterRowValue);
  };

  if (!isButtonMode) {
    return (
      <Select
        isLoading={loading}
        options={options}
        onBlur={() => setIsButtonMode(true)}
        value=""
        autoFocus
        onChange={(selectedValue, _) => {
          const selectedOption = selectedValue as CohortCreateDropdownOptionType;
          onPropertySelect(selectedOption.value, selectedOption.type);
        }}
        selectClassNames={{
          container: 'w-[420px]',
          control: 'h-9',
        }}
      />
    );
  }

  return (
    <Button className="py-1 px-2" variant="outline" onClick={() => setIsButtonMode(false)}>
      <PlusIcon stroke="#155EEF" />
      <span className="mx-2">Filter</span>
    </Button>
  );
}

import { isTodayInDateRange } from '@src/client/components/filters-and-selectors/date-range-selector/state';
import { retentionSubtypeState } from '@src/client/components/filters-and-selectors/retention-subtype-selector';
import { RetentionSubtypeEnum } from '@src/client/helpers/reports/constants';
import { getMaxNumberContainingElement } from '@src/client/helpers/reports/dataUtils';
import { toTitleCase } from '@src/client/lib/utils';
import { atom, selector } from 'recoil';

import { ChartData, DataVizRow } from '../../helpers/reports/types';

export const retentionNameState = atom<string>({
  key: 'retentionNameState',
  default: '',
});

export const retentionDescriptionState = atom<string>({
  key: 'retentionDescriptionState',
  default: '',
});

export const isRetentionLoadingState = atom<boolean>({
  key: 'isRetentionLoadingState',
  default: false,
});

export const showCountsInRetention = atom<boolean>({
  key: 'showCountsInRetention',
  default: true,
});

export const retentionRunIdState = atom<string>({
  key: 'retentionRunIdState',
  default: undefined,
});

export const retentionErrorState = atom<Error | unknown>({
  key: 'retentionErrorState',
  default: null,
});

export const retentionPvtDimnErrState = atom<Error | unknown>({
  key: 'retentionPvtDimnErrState',
  default: null,
});

export const retentionRowState = atom<DataVizRow[]>({
  key: 'retentionRowState',
  default: [],
});

export const retentionRowsSelectionState = atom<React.Key[]>({
  key: 'retentionRowsSelectionState',
  default: [],
});

export const retentionChartDataState = atom<ChartData>({
  key: 'retentionChartDataState',
  default: [],
});

export const onAndAfterState = atom<string>({
  key: 'onAndAfterState',
  default: 'on',
});

const retentionDateStringFormatting = (dateString: string): string => {
  const [year, month, day] = dateString.split('T')[0].split('-');
  const date = new Date(Number(year), Number(month) - 1, Number(day));
  // Format the date
  const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  return formattedDate;
};

export const retentionRowDataSelector = selector({
  key: 'retentionRowDataSelector',
  get: ({ get }) => {
    const tableData = get(retentionRowState);
    const showCounts = get(showCountsInRetention);
    const retentionSubtype = get(retentionSubtypeState);
    const isTodayInRange = get(isTodayInDateRange);

    if (retentionSubtype === RetentionSubtypeEnum.retention_table) {
      const data = showCounts
        ? tableData.find((dataRow) => dataRow.key === 'Retained User Count')!
        : tableData.find((dataRow) => dataRow.key === 'Retained User Percentage')!;
      let averageRow;
      const fiData: DataVizRow[] = data
        ? Object.keys(data)
            .filter((keyString) => !['color', 'event', 'key'].includes(keyString))
            .map((keyString: string) => {
              const rowTempData: any = data[keyString];
              const formattedDateString = ['average', 'total'].includes(keyString.toLowerCase())
                ? toTitleCase(keyString)
                : retentionDateStringFormatting(keyString);
              const rowData: any = {};
              const rowTempDataKeys = Object.keys(rowTempData);
              const maxPeriodKey = getMaxNumberContainingElement(rowTempDataKeys);
              rowTempDataKeys.forEach((keyStr) => {
                const addedSuffix =
                  keyStr === maxPeriodKey && formattedDateString !== 'Average' && isTodayInRange ? '*' : '';
                rowData[keyStr] =
                  keyStr.toLowerCase() !== 'total' && !showCounts
                    ? `${rowTempData[keyStr]}%${addedSuffix}`
                    : `${rowTempData[keyStr]}${addedSuffix}`;
              });
              if (formattedDateString === 'Average') {
                averageRow = { ...rowData, key: formattedDateString, event: formattedDateString, color: data.color };
              }
              return { ...rowData, key: formattedDateString, event: formattedDateString, color: data.color };
            })
            .filter((value) => value.key !== 'Average')
        : [];
      return averageRow ? [averageRow, ...fiData] : fiData;
    }

    if (showCounts) return tableData.filter((row) => !row.key.includes('Percentage'));
    return tableData.filter((row) => row.key.includes('Percentage'));
  },
});

export const maxRetentionValueSelector = selector({
  key: 'maxRetentionValueSelector',
  get: ({ get }) => {
    const countsView = get(showCountsInRetention);
    if (!countsView) return 100;
    const retentionRowData = get(retentionRowDataSelector);
    let maxValue = 0;

    retentionRowData.forEach((r) => {
      Object.keys(r).forEach((key) => {
        if (key !== 'total' && Number(r[key]) > maxValue) {
          maxValue = Number(r[key]);
        }
      });
    });

    return maxValue;
  },
});

import dayjs from 'dayjs';

import { ItemStatus } from '../types';

// eslint-disable-next-line import/prefer-default-export
export function adjustTimestamp(ts: dayjs.Dayjs, conversionTime: 'start' | 'end'): dayjs.Dayjs {
  let adjustedTs: dayjs.Dayjs;
  if (conversionTime === 'start') {
    adjustedTs = ts.startOf('day').utcOffset('+05:30');
  } else if (conversionTime === 'end') {
    adjustedTs = ts.endOf('day').utcOffset('+05:30');
  } else {
    throw new Error('Invalid conversionTime. Expected "start" or "end".');
  }
  return adjustedTs;
}

export const statusStrings: Record<ItemStatus, string> = {
  [ItemStatus.IN_PROGRESS]: 'Processing',
  [ItemStatus.PENDING]: 'Processing',
  [ItemStatus.FAILED]: 'Failed',
  [ItemStatus.ERROR]: 'Failed',
  [ItemStatus.REQUESTED]: 'Requested',
  [ItemStatus.APPROVED]: 'Approved',
  [ItemStatus.REJECTED]: 'Rejected',
  [ItemStatus.CANCELLED]: 'Cancelled',
};

export function getShowStatusBar(status: ItemStatus): boolean {
  return [ItemStatus.IN_PROGRESS, ItemStatus.PENDING, ItemStatus.ERROR, ItemStatus.FAILED].includes(status);
}

export function getStatusIsFailed(status: ItemStatus): boolean {
  return status === ItemStatus.FAILED || status === ItemStatus.ERROR;
}

export const initiateDownload = (downloadUrl: string) => {
  const link = document.createElement('a');
  link.href = downloadUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getCohortUpdateTsKey = (cohortId: string) => `PI_cohort_${cohortId}_update_ts`;

export const writeCohortUpdateTimestampToLocalstorage = (cohortExternalId: string) =>
  localStorage.setItem(getCohortUpdateTsKey(cohortExternalId), dayjs().toString());

export const removeCohortUpdateTimestampFromLocalstorage = (cohortExternalId: string) =>
  localStorage.removeItem(getCohortUpdateTsKey(cohortExternalId));

export const getCohortUpdateTimestampFromLocalstorage = (cohortExternalId: string) =>
  localStorage.getItem(getCohortUpdateTsKey(cohortExternalId));

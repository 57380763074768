import { useAllCohorts } from '@src/client/helpers/reports/hooks';
import { EventsAndCohorts } from '@src/client/helpers/reports/types';
import { isLengthyArray } from '@src/client/lib/utils';
import { Button } from '@src/client/ui-library/button';
import { DeleteIcon } from '@src/client/ui-library/icons/DashboardIcons';
import { Select } from '@src/client/ui-library/select';
import { ReactNode, useEffect, useState } from 'react';

import { EventTypeRow, PropertyType } from '../types';

interface CohortFilterRowProps {
  value: EventTypeRow;
  onChange: (value: EventTypeRow) => void;
  remove: (index: number) => void;
  rowIndex: number;
  groupOperatorSelector: ReactNode;
}

function CohortFilterRow({ value, onChange, remove, rowIndex, groupOperatorSelector }: CohortFilterRowProps) {
  const { isLoading, cohorts: cohortList } = useAllCohorts();

  const [selectedCohort, setSelectedCohort] = useState<EventsAndCohorts>();

  useEffect(() => {
    if (isLengthyArray(cohortList) && !selectedCohort && value.property) {
      if (typeof value.property === 'string' && value.property) {
        const property = cohortList.find((item) => item.value === value.property);
        setSelectedCohort(property);
      }
    }
  }, [value.property, cohortList]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCohortUpdate = (selCohort: unknown) => {
    const cohort = selCohort as EventsAndCohorts;
    setSelectedCohort(cohort);
    if (selCohort) {
      const tempValue: EventTypeRow = {
        type: PropertyType.COHORT,
        property: cohort.value,
      };
      onChange(tempValue);
    }
  };

  return (
    <div className="flex flex-row gap-x-2 gap-y-2 flex-wrap">
      <div className="flex justify-center items-center">
        {rowIndex === 0 ? <span className="w-20 text-center">where</span> : groupOperatorSelector}
      </div>
      <div>
        <Select
          isLoading={isLoading}
          options={cohortList}
          value={selectedCohort}
          onChange={handleCohortUpdate}
          selectClassNames={{
            container: 'w-[300px]',
          }}
        />
      </div>
      <div>
        <Button variant="icon" onClick={() => remove(rowIndex)}>
          <DeleteIcon />
        </Button>
      </div>
    </div>
  );
}

export default CohortFilterRow;

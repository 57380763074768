import LucidIconWrapper from '@src/client/components/lucid-icon-wrapper';
import { DateRangeEnum, EventPageDateRangeMap } from '@src/client/helpers/reports/constants';
import { getMappedHashCode } from '@src/client/helpers/reports/dataUtils';
import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { isLengthyArray } from '@src/client/lib/utils';
import { UserAvatarSvg } from '@src/client/ui-library/icons/UserAvatars';
import { Skeleton } from '@src/client/ui-library/skeleton';
import { formatDistanceToNow } from 'date-fns';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';

import { fetchUserProfilesInWorkspace } from '../../user-profiles/api/queries';

function RenderActiveUserCard({ userProfiles }: { userProfiles: Record<string, unknown>[] }) {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();

  return (
    <>
      {userProfiles.slice(0, 11).map((userProfile) => (
        <Link
          to={getLinkWithWorkspace(`user-profiles/${(userProfile as any).user_id}`)}
          className="min-w-40 max-w-420 col-1 hover:bg-foreground-secondary p-2 rounded-md border border-border"
          key={(userProfile as any).user_id}
        >
          <div className="flex items-center space-x-2">
            <div>
              <UserAvatarSvg avatarIndex={getMappedHashCode((userProfile as any).user_id, 5)} />
            </div>
            <div>
              <p className="text-xs font-medium text-foreground-selected ellipsis-text max-w-[80%]">
                {(userProfile as any).name ?? (userProfile as any).user_id}
              </p>
              <p className="text-xxs font-medium">
                {(userProfile as any).pi_city ? (
                  <span>
                    {(userProfile as any).pi_city} <span>&#183;</span>{' '}
                  </span>
                ) : null}
                <span>
                  Last active{' '}
                  {formatDistanceToNow(new Date((userProfile as any).__time).getTime(), { addSuffix: true })}
                </span>
              </p>
            </div>
          </div>
        </Link>
      ))}
      <Link
        to={getLinkWithWorkspace('user-profiles/list')}
        className="min-w-40  max-w-420 col-1 h-full border border-border rounded-md flex items-center justify-center space-x-2 hover:bg-foreground-secondary p-0.5"
      >
        <p className="text-sm font-medium">View all</p>
        <LucidIconWrapper name="circle-arrow-right" size={18} />
      </Link>
    </>
  );
}

export default function RecentUsersOverview() {
  const fetchUserProfilesReqNew = useMutation(fetchUserProfilesInWorkspace, {
    onError: (err: Error) => {
      Tracker.trackError(err, ErrorTags.USER_PROFILES_FETCH_ERROR);
    },
  });

  useEffect(() => {
    fetchUserProfilesReqNew.mutate({
      limit: 50,
      offset: 0,
      timeRange: {
        type: 'fixed',
        timeFilter: EventPageDateRangeMap[DateRangeEnum.TODAY],
      },
      filters: null,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex-1 border-b border-border rounded-md px-4 pb-10 pt-4 space-y-4">
      {fetchUserProfilesReqNew.isLoading ? (
        <div className="grid grid-cols-4 gap-2 flex-wrap items-stretch">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
            <Skeleton key={i} className="flex-1 h-20 col-1" />
          ))}
        </div>
      ) : isLengthyArray(fetchUserProfilesReqNew.data?.data?.userProfiles) ? (
        <>
          <p className="font-bold text-sm text-foreground-selected">Recently active users</p>

          <div className="grid grid-cols-4 gap-2 flex-wrap items-stretch">
            <RenderActiveUserCard userProfiles={fetchUserProfilesReqNew.data!.data.userProfiles ?? []} />
          </div>
        </>
      ) : null}
    </div>
  );
}

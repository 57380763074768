import { Button } from '@src/client/ui-library/button';
import { useTheme } from '@src/client/ui-library/theme-provider';
import { useUIVersion } from '@src/client/ui-library/theme-provider/uiProvider';
import { useCallback, useState } from 'react';

import { SearchIcon } from '../../ui-library/icons/NavbarIcons';
import LucidIconWrapper from '../lucid-icon-wrapper';
import GlobalSearchModal from './SearchModal';

export default function NavbarSearch({ isNavigationSidepanelOpen }: { isNavigationSidepanelOpen?: boolean }) {
  const [showSearchModal, setShowSearchModal] = useState(false);
  const openModal = useCallback(() => setShowSearchModal(true), []);
  const closeModal = useCallback(() => setShowSearchModal(false), []);
  const { uiVersion } = useUIVersion();
  const { theme } = useTheme();
  const strokeColor = theme === 'light' ? '#667085' : '#999999';

  if (uiVersion === 'v3') {
    return (
      <>
        {isNavigationSidepanelOpen ? (
          <div
            className="p-2 border border-border flex items-center bg-transparent w-full rounded-md space-x-2 hover:bg-foreground-secondary"
            onClick={openModal}
            role="button"
            tabIndex={0}
            onKeyDown={openModal}
            data-testid="global-search-button"
          >
            {/* <Search size={16} color={strokeColor} /> */}
            <LucidIconWrapper name="search" stroke={strokeColor} />
            <p className="text-md font-medium">Search</p>
          </div>
        ) : (
          <Button
            variant="icon"
            onClick={openModal}
            className="w-full rounded-md py-1 hover:bg-foreground-secondary"
            data-testid="global-search-button"
          >
            {/* <Search size={16} color={strokeColor} /> */}
            <LucidIconWrapper name="search" stroke={strokeColor} />
          </Button>
        )}
        {showSearchModal ? <GlobalSearchModal open={showSearchModal} onClose={closeModal} /> : null}
      </>
    );
  }

  return (
    <>
      <button
        type="button"
        className="p-2 flex items-center bg-neutral-dark w-[250px] rounded-xl text-gray-300 hover:ring-1"
        onClick={openModal}
      >
        <SearchIcon className="mr-2" stroke="#667085" />
        <p className="text-md font-semibold">Search</p>
      </button>
      {showSearchModal ? <GlobalSearchModal open={showSearchModal} onClose={closeModal} /> : null}
    </>
  );
}

import Layout from '@src/client/components/layout';
import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { ScreenNames } from '@src/client/routes/data';
import { Button } from '@src/client/ui-library/button';
import { RefereshIcon } from '@src/client/ui-library/icons/DashboardIcons';
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { FallbackProps } from 'react-error-boundary';

function AutoReloadPage() {
  const [countdown, setCountdown] = useState(5);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        // Reload the page after the countdown
        window.location.reload();
      }
    }, 1000);

    return () => clearTimeout(timerRef.current);
  }, [countdown]);

  return (
    <div>
      <p className="text-sm font-normal">
        Reloading in <span className="font-bold">{countdown}</span> seconds
      </p>
    </div>
  );
}

export default function AppErrorBoundaryFallback({ error, children }: FallbackProps & PropsWithChildren) {
  const isViteDynamicImportError = !!error.message?.includes('dynamically imported module');
  // const [isDynamicImportError, _setIsDynamicImportError] = useState<boolean>(isViteDynamicImportError);
  useEffect(() => {
    if (isViteDynamicImportError) {
      Tracker.trackError(error, ErrorTags.FAILED_DYNAMIC_IMPORT_ERROR);
      return;
    }
    Tracker.trackError(error, ErrorTags.UNHANDLED_RUNTIME_ERROR);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tryAgain = useCallback(() => {
    window.location.reload(); // Hack Alert should be handled within the app
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (isViteDynamicImportError === undefined) return null;
  if (isViteDynamicImportError) {
    return (
      <Layout screenName={ScreenNames.ErrorBoundaryFallbackScreen}>
        <div className="flex flex-col items-center h-layout-v2 m-auto max-w-[450px]">
          <img src="/images/v2/empty_result.png" alt="New app version found" className="mt-8 mb-4 max-w-[300px]" />
          <Button onClick={tryAgain} className="w-[220px] mb-4">
            <RefereshIcon stroke="#fff" />
            <span className="ml-2">Reload</span>
          </Button>
          <p className="text-sm font-normal">New app version is available</p>
          <AutoReloadPage />
        </div>
      </Layout>
    );
  }

  return (
    <Layout screenName={ScreenNames.ErrorBoundaryFallbackScreen}>
      <div className="flex flex-col items-center h-layout-v2 m-auto max-w-[450px]">
        <img src="/images/v2/errors/unhandled-error.svg" alt="Unhandled error" className="mt-8 mb-4 max-w-[300px]" />
        <Button onClick={tryAgain} className="w-[220px] mb-4">
          <RefereshIcon stroke="#fff" />
          <span className="ml-2">Retry</span>
        </Button>
        <div style={{ color: 'red' }}>{error.message}</div>
      </div>
    </Layout>
  );
}

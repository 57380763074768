import { fetchEndpoint, PlatformService } from '@src/client/lib/api/utils';

import { SdkIntegrationResponse, SdkIntegrationResponseV2 } from '../types';
import { SdkIntegrationEndpoints } from './endpoints';

// TODO: anmol.kataria@udaan.com
// "sdkTypes" is constant value. So no need to pass it.
// Declare value on service side and then use.
export const getSdkItegrations = async (sdkTypes: string[]) => {
  const data: SdkIntegrationResponse = await fetchEndpoint(
    SdkIntegrationEndpoints.getSdkItegrations,
    {},
    { sdkTypes },
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getSdkItegrationsV2 = async ({ interval = 7 }: { interval?: number }) => {
  const data: SdkIntegrationResponseV2 = await fetchEndpoint(
    SdkIntegrationEndpoints.getSdkItegrationsV2,
    {},
    { interval },
    {},
    'GET',
    PlatformService,
  );
  return data;
};

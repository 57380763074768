import { PluralSupportedTimeUnits } from '@src/client/lib/api/types/response';

import { SupportedFilterConditions, SupportedMathAggregation } from './types';

export const DEFAULT_COHORT_FETCH_COUNT = 50;

export const EVENT_PROPERTY_FILTER_TYPE_OPTIONS = [{ label: 'IS', value: SupportedFilterConditions.equal }];

export const EVENT_FILTER_TYPE_OPTIONS = [
  { label: 'equals', value: SupportedFilterConditions.equal },
  { label: 'not equal', value: SupportedFilterConditions.notEqual },
  { label: 'greater than', value: SupportedFilterConditions.greaterThan },
  { label: 'greater than or equal to', value: SupportedFilterConditions.greatherThanEqualTo },
  { label: 'less than', value: SupportedFilterConditions.lessThan },
  { label: 'less than or equal to', value: SupportedFilterConditions.lessThanEqualTo },
];

export const AGGREGATION_MATH_OPTIONS = [
  { label: 'total', value: SupportedMathAggregation.total },
  {
    label: 'distinct count',
    value: SupportedMathAggregation.distinctCount,
  },
];

export const TIME_WINDOW_OPTIONS = [
  {
    value: PluralSupportedTimeUnits.DAYS,
    label: 'days',
  },
  {
    value: PluralSupportedTimeUnits.WEEKS,
    label: 'weeks',
  },
  {
    value: PluralSupportedTimeUnits.MONTHS,
    label: 'months',
  },
];

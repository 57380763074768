import { atom, useResetRecoilState } from 'recoil';

import { FunnelTrendStep } from '../../../helpers/reports/constants';

export const funnelTrendStepState = atom<FunnelTrendStep>({
  key: 'funnelTrendStepStateV2',
  default: FunnelTrendStep.ALL_STEPS,
});

/** Helpers */

export const useResetFunnelTrendStepState = () => {
  const resetFunnelTrendStepState = useResetRecoilState(funnelTrendStepState);

  return () => {
    resetFunnelTrendStepState();
  };
};

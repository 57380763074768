import { GranularityEnum, ValueType } from '@src/client/helpers/reports/constants';
import { formatDate, numberToPeriod } from '@src/client/helpers/reports/dataUtils';
import { formatActualPercent, formatPercent, formatTime } from '@src/client/lib/utils';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { useCallback, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { granularityState } from '../../../filters-and-selectors/granularity-selector';
import { D3LineData, TooltipInfo } from './types';

dayjs.extend(isToday);

interface Props {
  tooltipInfo?: TooltipInfo;
  valueType?: ValueType;
  activeDataPoint?: D3LineData | null;
  chartWidth: number;
  chartHeight: number;
  notFormatDate?: boolean;
}

const getValueByValueTypeV2 = ({ valueType, lineData }: { valueType?: ValueType; lineData: D3LineData }) => {
  if (lineData && lineData.value === 0) {
    return 0;
  }
  if (!lineData || !lineData.value) return null;
  if (valueType === ValueType.TIME) {
    return formatTime(lineData.value);
  }
  if (valueType === ValueType.PERCENTAGE) {
    const formattedValue = formatPercent(lineData.value);
    return formattedValue;
  }
  if (valueType === ValueType.NUMBER) {
    const formattedValue = lineData.value.toFixed(2);
    return formattedValue;
  }
  if (valueType === ValueType.ACTUAL_PERCENTAGE) {
    const formattedValue = formatActualPercent(lineData.value);
    return formattedValue;
  }
  return lineData.value;
};

const isActiveTimeslot = (epochTimestamp: number, granularity: GranularityEnum): boolean => {
  switch (granularity) {
    case GranularityEnum.HOUR:
      return dayjs().diff(dayjs(epochTimestamp), 'hours') < 1;
    case GranularityEnum.DAY:
      return dayjs().diff(dayjs(epochTimestamp), 'day') < 1;
    case GranularityEnum.WEEK:
      return dayjs().diff(dayjs(epochTimestamp), 'week') < 1;
    case GranularityEnum.MONTH:
      return dayjs().diff(dayjs(epochTimestamp), 'month') < 1;
    case GranularityEnum.QUARTER:
      return dayjs().diff(dayjs(epochTimestamp), 'month') < 3;
    default:
      return false;
  }
};

export default function LineChartTooltip({
  tooltipInfo,
  activeDataPoint,
  valueType,
  chartWidth,
  chartHeight,
  notFormatDate,
}: Props) {
  const granularity = useRecoilValue(granularityState);
  const innerWrapperRef = useRef<HTMLDivElement>(null);
  const formatLabel = useCallback((val: number) => formatDate(granularity, val / 1000), [granularity]);

  if (tooltipInfo && (tooltipInfo?.i || tooltipInfo?.i === 0)) {
    const tooltipData = activeDataPoint;

    if (!tooltipData) return null;

    const transformX =
      (Number(tooltipInfo.x) ?? 0) + 200 > chartWidth ? Number(tooltipInfo.x) - 200 : Number(tooltipInfo?.x) + 8;

    const transformY =
      (Number(tooltipInfo.y) ?? 0) + 100 > chartHeight ? Number(tooltipInfo.y) - 100 : Number(tooltipInfo?.y) + 8;

    return (
      <foreignObject
        className="w-full h-full"
        style={{ transform: `translate(${transformX}px, ${transformY}px)`, pointerEvents: 'none' }}
      >
        <div className="border border-border rounded-lg bg-background text-foreground p-2 w-[240px] min-h-[40px]">
          <div className="flex justify-start items-start" ref={innerWrapperRef}>
            <div
              className="w-[4px] mr-2"
              style={{
                backgroundColor: `#${tooltipData?.color}`,
                height: innerWrapperRef?.current ? innerWrapperRef.current.clientHeight : 'auto',
              }}
            />
            <div>
              <p className="label text-xs  font-bold" style={{ wordBreak: 'break-all' }}>
                {tooltipData.key}
              </p>
              <div className="h-px bg-gray-200 my-2" />
              <p className="label text-xs text-gray-400">
                {/* TODO @prakul: notFormatDate does not mean we have to use numberToPeriod. Its better to pass this as an enum or take formatter in prop */}
                {notFormatDate ? numberToPeriod(tooltipData.date, granularity) : formatLabel(tooltipData.date)}{' '}
              </p>
              <p className="label text-xs  font-bold">{getValueByValueTypeV2({ valueType, lineData: tooltipData })}</p>
              {!notFormatDate && isActiveTimeslot(tooltipData.date, granularity) ? (
                <p className="text-xs text-warning mt-1">NOTE: Data is still flowing </p>
              ) : null}
            </div>
          </div>
        </div>
      </foreignObject>
    );
  }
  return null;
}

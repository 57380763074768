import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useIsWorkspaceCreationEnabled, useWorkspaceSelector } from '@src/client/hooks';
import { activeWorkspaceState, userInfoState } from '@src/client/recoil/atoms';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@src/client/ui-library/dropdown';
import { PlusIcon } from '@src/client/ui-library/icons/HomepageIcons';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { DEMO_WORKSPACE_ID } from './constants';
import { sortWorkspaces } from './utils';
import { workspaceIcons } from './WorkspaceIcons';

export default function WorkspaceSelector({ isOutline = false }: { isOutline?: boolean }) {
  const userInfo = useRecoilValue(userInfoState);
  const activeWorkspace = useRecoilValue(activeWorkspaceState);
  const handleWorkspaceSelection = useWorkspaceSelector();
  const navigate = useNavigate();
  const isWorkspaceCreationEnabled = useIsWorkspaceCreationEnabled();

  if (!userInfo?.workspaces || !userInfo?.activeWorkspaceId) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={`p-2 mr-3 flex gap-1 items-center rounded-x  ${isOutline ? 'border border-border' : 'text-gray-300 hover:text-gray-300 bg-neutral-dark hover:bg-neutral-dark dark:hover:bg-neutral-dark data-[state=open]:bg-neutral-dark dark:data-[state=open]:bg-neutral-dark'}`}
      >
        {activeWorkspace?.logoUrl &&
        activeWorkspace?.logoUrl !== 'https://percept.udaan.io/images/pi_logo_color.svg' ? (
          <img src={activeWorkspace.logoUrl} className="h-6 w-6" alt={`${activeWorkspace?.name} logo`} />
        ) : (
          workspaceIcons[
            (sortWorkspaces(userInfo?.workspaces ?? []).findIndex((ws) => ws.id === activeWorkspace?.id) + 3) % 3
          ]
        )}
        <div className="text-sm font-semibold mx-1">{activeWorkspace?.name}</div>
        <ChevronDownIcon className={`h-6 w-6 ${isOutline ? '' : 'text-gray-300'}`} />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-50 max-h-[600px] overflow-y-scroll">
        {sortWorkspaces(userInfo?.workspaces ?? [])
          .filter((w) => w.id !== DEMO_WORKSPACE_ID)
          .map((workspace, index) => (
            <DropdownMenuItem
              key={workspace.id}
              onClick={() => handleWorkspaceSelection(workspace)}
              className="cursor-pointer rounded-md"
            >
              <div className="mx-2 inline-flex items-center space-x-2">
                {workspace.logoUrl && workspace.logoUrl !== 'https://percept.udaan.io/images/pi_logo_color.svg' ? (
                  <img src={workspace.logoUrl} className="h-6 w-6" alt={`${workspace?.name} logo`} />
                ) : (
                  workspaceIcons[index % 3]
                )}
                <p>{workspace.name}</p>
              </div>
            </DropdownMenuItem>
          ))}
        {isWorkspaceCreationEnabled && (
          <DropdownMenuItem
            key="create-workspace"
            onClick={() => navigate('/create-workspace')}
            className="cursor-pointer rounded-md"
          >
            <div className="mx-2 inline-flex items-center space-x-2">
              <PlusIcon />
              <p>Create Workspace</p>
            </div>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

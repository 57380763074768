import { useTheme } from '@src/client/ui-library/theme-provider';
import { SVGProps } from 'react';

export const GotoLinkIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 6L14 2M14 2H10M14 2L8.66667 7.33333M6.66667 3.33333H5.2C4.0799 3.33333 3.51984 3.33333 3.09202 3.55132C2.71569 3.74307 2.40973 4.04903 2.21799 4.42535C2 4.85318 2 5.41323 2 6.53333V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H9.46667C10.5868 14 11.1468 14 11.5746 13.782C11.951 13.5903 12.2569 13.2843 12.4487 12.908C12.6667 12.4802 12.6667 11.9201 12.6667 10.8V9.33333"
      stroke="#155EEF"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export function SdkIcon(props: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2996_33329)">
        <path
          d="M21.5 43C21.3853 43 21.2671 42.9767 21.1596 42.932L5.93042 36.6611C5.59358 36.5232 5.375 36.1953 5.375 35.8334V23.2917C5.375 22.7972 5.77633 22.3959 6.27083 22.3959C6.76533 22.3959 7.16667 22.7954 7.16667 23.2917V35.2332L21.5 41.1349L35.8333 35.2332V23.2917C35.8333 22.7972 36.2347 22.3959 36.7292 22.3959C37.2237 22.3959 37.625 22.7954 37.625 23.2917V35.8334C37.625 36.1953 37.4064 36.5232 37.0696 36.6611L21.8404 42.932C21.7329 42.9767 21.6147 43 21.5 43Z"
          fill={theme === 'light' ? '#101323' : '#ffffff'}
        />
        <path
          d="M42.5788 19.8445L35.4121 15.3653C35.1487 15.1987 34.8137 15.1826 34.5378 15.3241L21.4998 21.8422L8.46364 15.3241C8.18414 15.1844 7.85089 15.1987 7.58751 15.3653L0.420844 19.8445C0.146719 20.0165 -0.0145312 20.3229 0.00159385 20.6454C0.0159272 20.9696 0.204052 21.2599 0.494302 21.405L14.8276 28.5717C14.9566 28.6344 15.0928 28.6666 15.229 28.6666C15.4261 28.6666 15.6213 28.6021 15.7826 28.4767L21.4998 23.9832L27.217 28.4767C27.3783 28.6021 27.5736 28.6666 27.7706 28.6666C27.9068 28.6666 28.043 28.6344 28.172 28.5717L42.5053 21.405C42.7956 21.2599 42.9855 20.9696 42.998 20.6454C43.0141 20.3229 42.8529 20.0165 42.5788 19.8445Z"
          fill={theme === 'light' ? '#4E5BA6' : '#ffffff'}
        />
        <path
          d="M12.9897 12.5416H9.85433C9.35983 12.5416 8.9585 12.1403 8.9585 11.6458C8.9585 11.1513 9.35983 10.75 9.85433 10.75H12.9897C13.7297 10.75 14.3335 10.1462 14.3335 9.40621C14.3335 8.66625 13.7297 8.06246 12.9897 8.06246H12.0939C10.365 8.06246 8.9585 6.656 8.9585 4.92704C8.9585 3.19808 10.365 1.79163 12.0939 1.79163H15.2293C15.7238 1.79163 16.1252 2.19117 16.1252 2.68746C16.1252 3.18375 15.7238 3.58329 15.2293 3.58329H12.0939C11.354 3.58329 10.7502 4.18708 10.7502 4.92704C10.7502 5.667 11.354 6.27079 12.0939 6.27079H12.9897C14.7187 6.27079 16.1252 7.67725 16.1252 9.40621C16.1252 11.1352 14.7187 12.5416 12.9897 12.5416Z"
          fill={theme === 'light' ? '#101323' : '#ffffff'}
        />
        <path
          d="M22.3957 12.5416H18.8123C18.3178 12.5416 17.9165 12.1403 17.9165 11.6458V2.68746C17.9165 2.19117 18.3178 1.79163 18.8123 1.79163H22.3957C23.8774 1.79163 25.0832 2.99742 25.0832 4.47913V9.85413C25.0832 11.3358 23.8774 12.5416 22.3957 12.5416ZM19.7082 10.75H22.3957C22.8884 10.75 23.2915 10.3468 23.2915 9.85413V4.47913C23.2915 3.98463 22.8884 3.58329 22.3957 3.58329H19.7082V10.75Z"
          fill={theme === 'light' ? '#101323' : '#ffffff'}
        />
        <path
          d="M27.7708 12.5416C27.2763 12.5416 26.875 12.1403 26.875 11.6458V2.68746C26.875 2.19117 27.2763 1.79163 27.7708 1.79163C28.2653 1.79163 28.6667 2.19117 28.6667 2.68746V11.6458C28.6667 12.1403 28.2653 12.5416 27.7708 12.5416Z"
          fill={theme === 'light' ? '#101323' : '#ffffff'}
        />
        <path
          d="M33.1458 12.5417C32.9434 12.5417 32.7391 12.4736 32.5725 12.3338L27.1975 7.85467C26.9915 7.68446 26.875 7.43184 26.875 7.16667C26.875 6.90151 26.9915 6.64888 27.1975 6.47867L32.5725 1.99951C32.9523 1.68238 33.5149 1.73255 33.8338 2.11238C34.151 2.49221 34.099 3.05659 33.721 3.37371L29.1701 7.16667L33.7192 10.9578C34.099 11.275 34.1492 11.8393 33.832 12.2192C33.6565 12.4306 33.402 12.5417 33.1458 12.5417Z"
          fill={theme === 'light' ? '#101323' : '#ffffff'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2996_33329">
          <rect width="43" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function IntegrationSuccessIcon(props: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="10" fill="#079455" />
      <path
        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={theme === 'light' ? '#ffffff' : '#1D2939'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CopyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.5 3H14.6C16.8402 3 17.9603 3 18.816 3.43597C19.5686 3.81947 20.1805 4.43139 20.564 5.18404C21 6.03969 21 7.15979 21 9.4V16.5M6.2 21H14.3C15.4201 21 15.9802 21 16.408 20.782C16.7843 20.5903 17.0903 20.2843 17.282 19.908C17.5 19.4802 17.5 18.9201 17.5 17.8V9.7C17.5 8.57989 17.5 8.01984 17.282 7.59202C17.0903 7.21569 16.7843 6.90973 16.408 6.71799C15.9802 6.5 15.4201 6.5 14.3 6.5H6.2C5.0799 6.5 4.51984 6.5 4.09202 6.71799C3.71569 6.90973 3.40973 7.21569 3.21799 7.59202C3 8.01984 3 8.57989 3 9.7V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21Z"
        stroke="#98A2B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CopySuccessIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M21 6L9 18L3 12" stroke="#4CAF50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function SdkMenuIcon(props: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_4079_33315)">
        <path
          d="M12 24C11.936 24 11.87 23.987 11.81 23.962L3.31 20.462C3.122 20.385 3 20.202 3 20V13C3 12.724 3.224 12.5 3.5 12.5C3.776 12.5 4 12.723 4 13V19.665L12 22.959L20 19.665V13C20 12.724 20.224 12.5 20.5 12.5C20.776 12.5 21 12.723 21 13V20C21 20.202 20.878 20.385 20.69 20.462L12.19 23.962C12.13 23.987 12.064 24 12 24Z"
          fill={theme === 'light' ? '#475467' : '#ffffff'}
        />
        <path
          d="M0.50025 11.5C0.500273 11.5 0.500299 11.4999 0.500324 11.4998L0.50012 11.4999L0.500231 11.5M0.50025 11.5L0.498923 11.4981L0.500089 11.4999L0.499712 11.4997C0.499598 11.4997 0.499745 11.4997 0.499968 11.4999L0.50015 11.5001C0.500139 11.5001 0.500159 11.5001 0.500231 11.5M0.50025 11.5L0.500231 11.5M12.2237 12.6382L19.5006 9.00023L23.4994 11.4995C23.4994 11.4995 23.4994 11.4995 23.4994 11.4995C23.4995 11.4996 23.4996 11.4996 23.4996 11.4997L23.4996 11.5002L15.5042 15.4979C15.5018 15.499 15.5001 15.4995 15.4991 15.4998C15.4988 15.4997 15.4985 15.4996 15.4983 15.4994C15.4982 15.4994 15.4981 15.4993 15.4981 15.4993L12.3091 12.9928L12.0001 12.75L11.6911 12.9928L8.50213 15.4993C8.50207 15.4993 8.50201 15.4994 8.50195 15.4994C8.50168 15.4996 8.50141 15.4997 8.50107 15.4998C8.50008 15.4995 8.49839 15.499 8.49598 15.4979L0.500591 11.5002L0.500566 11.4997C0.500632 11.4996 0.500707 11.4996 0.500793 11.4995C0.500812 11.4995 0.500831 11.4995 0.500851 11.4995L4.50003 8.99999L4.5005 9.00016L11.7765 12.6382L12.0001 12.75L12.2237 12.6382ZM23.5 11.5C23.5001 11.5002 23.5001 11.5002 23.5 11.5C23.4999 11.5 23.4999 11.4999 23.4999 11.4998L23.5 11.5Z"
          stroke={theme === 'light' ? '#475467' : '#ffffff'}
        />
        <path
          d="M7.25 7H5.5C5.224 7 5 6.776 5 6.5C5 6.224 5.224 6 5.5 6H7.25C7.663 6 8 5.663 8 5.25C8 4.837 7.663 4.5 7.25 4.5H6.75C5.785 4.5 5 3.715 5 2.75C5 1.785 5.785 1 6.75 1H8.5C8.776 1 9 1.223 9 1.5C9 1.777 8.776 2 8.5 2H6.75C6.337 2 6 2.337 6 2.75C6 3.163 6.337 3.5 6.75 3.5H7.25C8.215 3.5 9 4.285 9 5.25C9 6.215 8.215 7 7.25 7Z"
          fill={theme === 'light' ? '#475467' : '#ffffff'}
        />
        <path
          d="M12.5 7H10.5C10.224 7 10 6.776 10 6.5V1.5C10 1.223 10.224 1 10.5 1H12.5C13.327 1 14 1.673 14 2.5V5.5C14 6.327 13.327 7 12.5 7ZM11 6H12.5C12.775 6 13 5.775 13 5.5V2.5C13 2.224 12.775 2 12.5 2H11V6Z"
          fill={theme === 'light' ? '#475467' : '#ffffff'}
        />
        <path
          d="M15.5 7C15.224 7 15 6.776 15 6.5V1.5C15 1.223 15.224 1 15.5 1C15.776 1 16 1.223 16 1.5V6.5C16 6.776 15.776 7 15.5 7Z"
          fill={theme === 'light' ? '#475467' : '#ffffff'}
        />
        <path
          d="M18.5 7C18.387 7 18.273 6.962 18.18 6.884L15.18 4.384C15.065 4.289 15 4.148 15 4C15 3.852 15.065 3.711 15.18 3.616L18.18 1.116C18.392 0.939 18.706 0.967 18.884 1.179C19.061 1.391 19.032 1.706 18.821 1.883L16.281 4L18.82 6.116C19.032 6.293 19.06 6.608 18.883 6.82C18.785 6.938 18.643 7 18.5 7Z"
          fill={theme === 'light' ? '#475467' : '#ffffff'}
        />
      </g>
      <defs>
        <clipPath id="clip0_4079_33315">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function PerceptLogo(props: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg width="243" height="40" viewBox="0 0 243 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M169.911 27C169.039 27 168.436 26.8121 168.1 26.4365C167.778 26.0474 167.617 25.4972 167.617 24.7861V18.0439H165.604V16.3533H167.617V13.274L169.69 13.0325V16.3533H172.045V18.0439H169.69V24.4037C169.69 24.7526 169.757 24.9874 169.891 25.1081C170.025 25.2289 170.26 25.2893 170.596 25.2893H172.045V27H169.911Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path
        d="M154.746 27V12.3281H156.798V18.4665H156.919C157.228 17.7152 157.711 17.1516 158.368 16.776C159.026 16.3869 159.744 16.1923 160.522 16.1923C161.246 16.1923 161.897 16.3399 162.474 16.6351C163.051 16.9303 163.5 17.4066 163.822 18.064C164.158 18.7215 164.326 19.5936 164.326 20.6804V27H162.253V21.1031C162.253 20.0163 162.051 19.2112 161.649 18.6879C161.246 18.1647 160.622 17.903 159.777 17.903C159.173 17.903 158.643 18.0506 158.187 18.3458C157.744 18.641 157.402 19.0368 157.161 19.5332C156.919 20.0163 156.798 20.5664 156.798 21.1836V27H154.746Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path
        d="M146.924 30.5623C145.649 30.5623 144.622 30.2873 143.844 29.7372C143.066 29.187 142.623 28.4424 142.516 27.5032H144.528C144.609 27.9728 144.864 28.3417 145.293 28.6101C145.736 28.8919 146.293 29.0327 146.964 29.0327C147.916 29.0327 148.641 28.8181 149.137 28.3887C149.647 27.9728 149.902 27.2751 149.902 26.2956V24.2226H149.761C149.52 24.8666 149.09 25.3899 148.473 25.7924C147.856 26.1815 147.125 26.3761 146.279 26.3761C144.978 26.3761 143.938 25.9333 143.16 25.0478C142.382 24.1622 141.993 22.9077 141.993 21.2842C141.993 19.6473 142.388 18.386 143.18 17.5005C143.972 16.615 145.012 16.1722 146.3 16.1722C147.145 16.1722 147.869 16.3734 148.473 16.776C149.09 17.1651 149.52 17.6816 149.761 18.3257H149.902L149.942 16.3533H151.955V26.0138C151.955 27.1275 151.74 28.013 151.311 28.6705C150.895 29.3413 150.311 29.8244 149.56 30.1195C148.809 30.4147 147.93 30.5623 146.924 30.5623ZM146.984 24.766C147.601 24.766 148.124 24.6251 148.554 24.3434C148.983 24.0616 149.312 23.706 149.54 23.2767C149.768 22.8339 149.889 22.3777 149.902 21.9081L149.882 20.62C149.882 20.1504 149.768 19.701 149.54 19.2716C149.325 18.8422 149.003 18.4934 148.574 18.225C148.158 17.9433 147.635 17.8024 147.004 17.8024C146.118 17.8024 145.414 18.1043 144.891 18.7081C144.381 19.3118 144.126 20.1706 144.126 21.2842C144.126 22.4113 144.388 23.2767 144.911 23.8805C145.434 24.4708 146.125 24.766 146.984 24.766Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path
        d="M137.764 14.7231V12.3281H139.837V14.7231H137.764ZM137.764 27V16.3533H139.837V27H137.764Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path
        d="M130.9 27.2013C129.437 27.2013 128.31 26.8792 127.518 26.2352C126.74 25.5777 126.351 24.719 126.351 23.6591H128.404C128.404 24.3836 128.645 24.9136 129.128 25.249C129.611 25.571 130.222 25.732 130.96 25.732C131.725 25.732 132.302 25.5979 132.691 25.3295C133.093 25.0478 133.295 24.6587 133.295 24.1622C133.295 23.5718 133.093 23.1693 132.691 22.9547C132.288 22.74 131.637 22.5655 130.739 22.4314C129.933 22.3106 129.222 22.1496 128.605 21.9483C128.001 21.7471 127.525 21.4452 127.176 21.0427C126.827 20.6402 126.653 20.0766 126.653 19.3521C126.653 18.3324 127.042 17.5474 127.82 16.9973C128.612 16.4338 129.652 16.152 130.94 16.152C132.255 16.152 133.288 16.4539 134.039 17.0577C134.791 17.6481 135.173 18.4598 135.186 19.493H133.154C133.14 18.8489 132.932 18.3793 132.53 18.0841C132.127 17.7755 131.584 17.6212 130.9 17.6212C130.202 17.6212 129.665 17.7621 129.289 18.0439C128.914 18.3257 128.726 18.6946 128.726 19.1508C128.726 19.6741 128.934 20.0431 129.35 20.2578C129.779 20.4724 130.443 20.6402 131.342 20.7609C132.08 20.8548 132.758 21.0024 133.375 21.2037C133.992 21.3915 134.482 21.7001 134.844 22.1295C135.22 22.5454 135.408 23.1626 135.408 23.9811C135.408 25.0679 135.005 25.8796 134.2 26.4163C133.395 26.9396 132.295 27.2013 130.9 27.2013Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path
        d="M114.552 27V16.3533H116.525L116.605 18.4665H116.726C117.035 17.7152 117.518 17.1516 118.175 16.7759C118.833 16.3868 119.55 16.1923 120.329 16.1923C121.053 16.1923 121.704 16.3399 122.281 16.6351C122.858 16.9302 123.307 17.4066 123.629 18.064C123.965 18.7215 124.132 19.5936 124.132 20.6804V27H122.059V21.1031C122.059 20.0162 121.858 19.2112 121.456 18.6879C121.053 18.1646 120.429 17.903 119.584 17.903C118.98 17.903 118.45 18.0506 117.994 18.3458C117.551 18.641 117.209 19.0368 116.968 19.5332C116.726 20.0162 116.605 20.5664 116.605 21.1836V27H114.552Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path d="M109.116 27V12.3281H111.309V27H109.116Z" fill={theme === 'light' ? '#0D0D0D' : '#ffffff'} />
      <path
        d="M104.243 27C103.37 27 102.7 26.8725 102.23 26.6176C101.76 26.3627 101.438 26.0071 101.264 25.5509C101.089 25.0813 101.002 24.5446 101.002 23.9408V19.1307H99.1708V16.0514H101.002V13.2539L104.846 12.9319V16.0514H106.839V19.1307H104.846V23.2163C104.846 23.4981 104.92 23.6859 105.068 23.7798C105.215 23.8738 105.403 23.9207 105.631 23.9207H106.839V27H104.243Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path
        d="M87.0082 30.3611V16.0514H90.7114L90.8523 17.7219H91.0535C91.295 17.1584 91.6976 16.7089 92.2611 16.3734C92.8246 16.0246 93.4955 15.8502 94.2737 15.8502C95.5618 15.8502 96.5949 16.3131 97.3731 17.2389C98.1647 18.1647 98.5605 19.5936 98.5605 21.5257C98.5605 23.4578 98.1647 24.8868 97.3731 25.8126C96.5949 26.7384 95.5618 27.2013 94.2737 27.2013C93.4955 27.2013 92.8246 27.0335 92.2611 26.6981C91.6976 26.3493 91.295 25.8931 91.0535 25.3295H90.8523V30.3611H87.0082ZM92.7642 24.122C93.3278 24.122 93.7772 23.9274 94.1127 23.5383C94.4615 23.1358 94.636 22.4649 94.636 21.5257C94.636 20.5865 94.4615 19.9223 94.1127 19.5332C93.7772 19.1307 93.3278 18.9294 92.7642 18.9294C92.0934 18.9294 91.6103 19.1508 91.3152 19.5936C91.0334 20.0364 90.8925 20.5328 90.8925 21.0829V21.9685C90.8925 22.5186 91.0334 23.015 91.3152 23.4578C91.6103 23.9006 92.0934 24.122 92.7642 24.122Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path
        d="M80.0707 27.2013C78.8765 27.2013 77.8568 26.9732 77.0115 26.517C76.1796 26.0474 75.5423 25.3966 75.0995 24.5647C74.6568 23.7195 74.4354 22.74 74.4354 21.6263C74.4354 20.4322 74.6635 19.4058 75.1197 18.5471C75.5758 17.6749 76.2132 17.0108 77.0316 16.5546C77.8635 16.085 78.8296 15.8502 79.9298 15.8502C81.2581 15.8502 82.3449 16.1386 83.1902 16.7156C84.0355 17.2791 84.6393 18.0573 85.0015 19.0502C85.3772 20.0431 85.498 21.1701 85.3638 22.4314H78.2191C78.2056 23.0486 78.3667 23.5249 78.7021 23.8603C79.0509 24.1824 79.5071 24.3434 80.0707 24.3434C80.4732 24.3434 80.822 24.2629 81.1172 24.1018C81.4124 23.9274 81.6002 23.706 81.6807 23.4377H85.2833C85.2028 24.1891 84.9278 24.8465 84.4581 25.41C84.002 25.9736 83.3982 26.4163 82.6468 26.7384C81.8954 27.047 81.0367 27.2013 80.0707 27.2013ZM79.9298 18.6879C79.4468 18.6879 79.0509 18.8221 78.7423 19.0905C78.4472 19.3454 78.2794 19.6942 78.2392 20.137H81.5801C81.5533 19.6674 81.3856 19.3118 81.077 19.0703C80.7684 18.8154 80.386 18.6879 79.9298 18.6879Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path
        d="M68.257 27.2013C67.1299 27.2013 66.1505 26.9665 65.3186 26.4968C64.4867 26.0272 63.836 25.3631 63.3664 24.5044C62.9102 23.6457 62.6821 22.6461 62.6821 21.5056C62.6821 20.3517 62.9102 19.3521 63.3664 18.5068C63.836 17.6615 64.4934 17.0108 65.3387 16.5546C66.1974 16.085 67.2037 15.8502 68.3576 15.8502C69.3505 15.8502 70.2293 16.038 70.9941 16.4137C71.7589 16.776 72.3627 17.2858 72.8055 17.9433C73.2483 18.6007 73.4764 19.3655 73.4898 20.2376H69.7463C69.6792 19.7949 69.5115 19.4729 69.2432 19.2716C68.9882 19.0569 68.6662 18.9496 68.2771 18.9496C67.6868 18.9496 67.2574 19.1844 66.989 19.654C66.7207 20.1102 66.5865 20.7341 66.5865 21.5257C66.5865 22.4247 66.7408 23.0821 67.0494 23.4981C67.358 23.9006 67.7605 24.1018 68.257 24.1018C68.7266 24.1018 69.0822 23.9811 69.3237 23.7396C69.5786 23.4981 69.7329 23.1895 69.7866 22.8138H73.5099C73.4831 23.6993 73.2415 24.4708 72.7854 25.1283C72.3292 25.7857 71.712 26.2956 70.9338 26.6579C70.1555 27.0201 69.2633 27.2013 68.257 27.2013Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path
        d="M54.8536 27V16.0514H58.3354L58.4763 17.6414H58.6172C58.8587 16.9437 59.2277 16.4808 59.7241 16.2527C60.234 16.0246 60.811 15.9105 61.455 15.9105H62.0588V19.5131H61.3745C60.3279 19.5131 59.6168 19.7143 59.2411 20.1169C58.8789 20.506 58.6977 21.0762 58.6977 21.8276V27H54.8536Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path
        d="M47.9161 27.2013C46.722 27.2013 45.7023 26.9732 44.857 26.517C44.0251 26.0474 43.3878 25.3966 42.945 24.5647C42.5022 23.7195 42.2808 22.74 42.2808 21.6263C42.2808 20.4322 42.5089 19.4058 42.9651 18.5471C43.4213 17.6749 44.0586 17.0108 44.8771 16.5546C45.709 16.085 46.675 15.8502 47.7752 15.8502C49.1036 15.8502 50.1904 16.1386 51.0357 16.7156C51.881 17.2791 52.4847 18.0573 52.847 19.0502C53.2227 20.0431 53.3435 21.1701 53.2093 22.4314H46.0645C46.0511 23.0486 46.2121 23.5249 46.5476 23.8603C46.8964 24.1824 47.3526 24.3434 47.9161 24.3434C48.3186 24.3434 48.6675 24.2629 48.9627 24.1018C49.2579 23.9274 49.4457 23.706 49.5262 23.4377H53.1288C53.0483 24.1891 52.7732 24.8465 52.3036 25.41C51.8474 25.9736 51.2436 26.4163 50.4923 26.7384C49.7409 27.047 48.8822 27.2013 47.9161 27.2013ZM47.7752 18.6879C47.2922 18.6879 46.8964 18.8221 46.5878 19.0905C46.2926 19.3454 46.1249 19.6942 46.0847 20.137H49.4256C49.3987 19.6674 49.231 19.3118 48.9224 19.0703C48.6138 18.8154 48.2314 18.6879 47.7752 18.6879Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path
        d="M29.3887 27V12.3281H36.0504C37.1372 12.3281 38.0831 12.5226 38.8882 12.9118C39.6932 13.3009 40.3171 13.8644 40.7599 14.6023C41.2027 15.3403 41.4241 16.2393 41.4241 17.2992C41.4241 18.2921 41.2161 19.171 40.8002 19.9357C40.3842 20.6871 39.7737 21.2775 38.9687 21.7068C38.1771 22.1228 37.2043 22.3308 36.0504 22.3308H33.4743V27H29.3887ZM35.3259 15.7495H33.4743V19.2112H35.3259C35.621 19.2112 35.9162 19.1777 36.2114 19.1106C36.5066 19.0301 36.7548 18.8691 36.9561 18.6276C37.1708 18.3726 37.2781 17.9835 37.2781 17.4602C37.2781 16.9504 37.1708 16.5747 36.9561 16.3332C36.7548 16.0917 36.5066 15.9374 36.2114 15.8703C35.9162 15.7898 35.621 15.7495 35.3259 15.7495Z"
        fill={theme === 'light' ? '#0D0D0D' : '#ffffff'}
      />
      <path
        d="M4.02106 17.3218C4.02106 16.5143 4.67573 15.8596 5.48331 15.8596H8.95616C9.76374 15.8596 10.4184 16.5143 10.4184 17.3218V30.2993C10.4184 32.7221 8.45439 34.6861 6.03165 34.6861H5.48331C4.67573 34.6861 4.02106 34.0314 4.02106 33.2238V17.3218Z"
        fill="#155EEF"
      />
      <path
        d="M11.515 24.2676C11.515 25.0752 12.1697 25.7298 12.9773 25.7298H13.5256C15.9483 25.7298 17.9124 23.7658 17.9124 21.3431V17.3219C17.9124 16.5143 17.2577 15.8596 16.4501 15.8596H12.9773C12.1697 15.8596 11.515 16.5143 11.515 17.3219V24.2676Z"
        fill="#155EEF"
      />
      <path
        d="M11.3322 8.3656C12.1398 8.3656 12.7945 9.02027 12.7945 9.82785L12.7945 13.3007C12.7945 14.1083 12.1398 14.763 11.3322 14.763L2.74151 14.763C1.93394 14.763 1.27926 14.1083 1.27926 13.3007L1.27926 12.7524C1.27926 10.3296 3.24328 8.3656 5.66602 8.3656L11.3322 8.3656Z"
        fill="#155EEF"
      />
      <path
        d="M18.8264 14.763C19.634 14.763 20.2887 14.1083 20.2887 13.3007L20.2887 12.7524C20.2887 10.3296 18.3247 8.36562 15.9019 8.36562L15.3536 8.36562C14.546 8.36562 13.8913 9.02029 13.8913 9.82787L13.8913 13.3007C13.8913 14.1083 14.546 14.763 15.3536 14.763L18.8264 14.763Z"
        fill="#155EEF"
      />
    </svg>
  );
}

import LucidIconWrapper from '@src/client/components/lucid-icon-wrapper';
import { ReportTypeDisplyInfo } from '@src/client/helpers/constants';
import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { getRecentlyViewed } from '@src/client/lib/api/queries/common';
import { QueryKeys } from '@src/client/lib/api/queries/keys';
import { RecentlyViewedItemResponse } from '@src/client/lib/api/types/response';
import { getUseQueryOptions } from '@src/client/lib/api/utils';
import { getNavigationPath, isLengthyArray } from '@src/client/lib/utils';
import { Skeleton } from '@src/client/ui-library/skeleton';
import { cn } from '@src/client/ui-library/utils';
import { formatDistanceToNow } from 'date-fns';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import NoReportsPlaceholder from './NoReportsPlaceholder';

function RenderRecentlyVieweditemsCard({ data }: { data: RecentlyViewedItemResponse[] }) {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();

  return (
    <>
      {data.slice(0, 11).map((item, index) => (
        <Link
          key={item.itemExternalId}
          to={getLinkWithWorkspace(getNavigationPath(item.itemType, item.reportType, item.itemExternalId))}
          className="min-w-40 max-w-420 col-1 hover:bg-foreground-secondary p-2 rounded-md border border-border"
        >
          {/* <img src={SampleReportGridImages[index % 4]} alt="random graph icon" className="w-full mb-4" /> */}
          <div className="inline-flex items-center space-x-2 mb-2">
            <img
              className="h-8 w-8"
              src={
                item.reportType && ReportTypeDisplyInfo[item.reportType]
                  ? ReportTypeDisplyInfo[item.reportType].imageSrc
                  : '/images/v2/dashboard-small.png'
              }
              alt="report icon"
            />
            <div>
              <p className="text-xs font-medium text-foreground-selected">{item.name}</p>
              <p className="text-xxs font-medium">
                Last opened {formatDistanceToNow(item.lastOpenedAt, { addSuffix: true })}
              </p>
            </div>
          </div>
        </Link>
      ))}
      <Link
        to={getLinkWithWorkspace('all-reports')}
        className="min-w-40  max-w-420 col-1 h-full border border-border rounded-md flex items-center justify-center space-x-2 hover:bg-foreground-secondary p-0.5"
      >
        <p className="text-sm font-medium">View all</p>
        <LucidIconWrapper name="circle-arrow-right" size={18} />
      </Link>
    </>
  );
}
export default function RecentlyViewedReportOverview() {
  const { data, isLoading } = useQuery(
    [QueryKeys.GetRecentlyViewed],
    () =>
      getRecentlyViewed({
        // configStatus: ItemStatus.APPROVED,
        limit: 10,
        offset: 0,
        reportType: [],
        itemType: [],
        createdBy: null,
      }),
    {
      ...getUseQueryOptions(),
    },
  );

  return (
    <div className={cn('w-full px-4 pt-4 pb-10', isLengthyArray(data) ? 'border-b border-border' : '')}>
      {isLoading ? (
        <div className="grid grid-cols-4 gap-2 flex-wrap items-stretch">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
            <Skeleton key={i} className="flex-1 h-20 col-1" />
          ))}
        </div>
      ) : isLengthyArray(data) ? (
        <>
          <p className="font-bold text-sm mb-4 text-foreground-selected">Recently viewed reports</p>
          <div className="grid grid-cols-4 gap-2 flex-wrap items-stretch">
            <RenderRecentlyVieweditemsCard data={data!} />
          </div>
        </>
      ) : (
        <NoReportsPlaceholder showReportPills />
      )}
    </div>
  );
}

import { ReloadIcon } from '@radix-ui/react-icons';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { CloseIcon } from '../icons/ReportIcons';
import { cn } from '../utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary: 'bg-primary-dark text-primary-foreground hover:bg-primary/90',
        secondary: 'bg-primary-light text-primary-dark hover:bg-primary-light/80',
        danger: 'bg-danger text-danger-foreground hover:bg-danger/90',
        outline:
          'border border-border bg-transparent text-foreground hover:text-primary hover:bg-primary-light dark:hover:bg-foreground-secondary dark:hover:text-white',
        ghost: 'bg-transparent text-foreground hover:bg-primary hover:primary-foreground hover:text-white',
        link: 'bg-transparent text-primary underline-offset-4 hover:underline',
        icon: 'bg-transparent hover:bg-foreground-secondary p-1 rounded-full',
      },
      size: {
        sm: 'py-2 px-3.5',
        md: 'py-2.5 px-4',
        lg: 'py-2 px-5',
        xl: 'py-3 px-5',
        xxl: 'py-4 px-7',
      },
      disabled: {
        true: 'cursor-not-allowed opacity-50',
        false: 'cursor-pointer',
      },
      loading: {
        true: 'cursor-progress',
        false: 'cursor-pointer',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'sm',
    },
    compoundVariants: [
      {
        variant: 'icon',
        size: 'sm',
        className: 'p-1',
      },
    ],
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  disabled?: boolean;
  loading?: boolean;
  hideContentWhileLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size = 'sm',
      disabled = false,
      loading = false,
      hideContentWhileLoading = false,
      children,
      title,
      ...props
    },
    ref,
  ) => (
    <button
      type="button"
      disabled={disabled || loading}
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref}
      title={title}
      {...props}
    >
      {loading ? <ReloadIcon className={`h-4 w-4 animate-spin ${hideContentWhileLoading ? '' : 'mr-2'}`} /> : null}
      {loading ? (hideContentWhileLoading ? null : children) : children}
    </button>
  ),
);
Button.displayName = 'Button';

interface ClearableButtonProps extends ButtonProps {
  onClear?: () => void;
}

const ClearableButton = React.forwardRef<HTMLButtonElement, ClearableButtonProps>(
  (
    {
      className,
      variant,
      size = 'sm',
      disabled = false,
      loading = false,
      children,
      hideContentWhileLoading = false,
      onClear,
      ...props
    },
    ref,
  ) => (
    <button
      type="button"
      disabled={disabled}
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref}
      {...props}
    >
      {loading ? <ReloadIcon className={` h-4 w-4 animate-spin ${hideContentWhileLoading ? '' : 'mr-2'}`} /> : null}
      {loading ? (hideContentWhileLoading ? null : children) : children}
      <div className="flex-grow" />
      {onClear && (
        <div
          tabIndex={0}
          aria-label="On Clear"
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            onClear();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
            onClear();
          }}
        >
          <CloseIcon />
        </div>
      )}
    </button>
  ),
);
ClearableButton.displayName = 'ClearableButton';

export { Button, ClearableButton, buttonVariants };

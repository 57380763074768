import { isLengthyArray } from '@src/client/lib/utils';
import { isEqual } from 'lodash-es';

import { Breakdown } from '../../../helpers/reports/types';

export const hasBreakdownValueChanged = (
  breakdowns: Breakdown[] | undefined,
  previousBreakdowns: Breakdown[] | undefined,
): boolean =>
  (isLengthyArray(breakdowns) &&
    breakdowns?.every((d) => d.property && d.property !== '') &&
    !isEqual(breakdowns, previousBreakdowns)) ||
  (!isLengthyArray(breakdowns) && isLengthyArray(previousBreakdowns));

export enum GranularityEnum {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
}

export enum RetentionSubtypeEnum {
  retention_table = 'retention_table',
  repeat = 'repeat',
}

export enum ChartType {
  LINE = 'Line',
  BAR = 'Bar',
  PIE = 'Pie',
  METRIC = 'Metric',
  TABLE = 'Table',
}

export enum DateRangeEnum {
  CUSTOM = 'custom',
  SINCE = 'since',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  SEVEN_DAYS = '7d',
  FOURTEEN_DAYS = '14d',
  THIRTY_DAYS = '30d',
  THREE_MONTH = '3m',
  SIX_MONTH = '6m',
  TWELVE_MONTH = '12m',
  // Allows each report to use its own date on initial load.
  // Use case: On dashboard pages, this setting enables each report to display data
  // based on its predefined date. If the user selects a specific date range, it overrides
  // this setting. Re-selecting `DEFAULT` will revert each report back to its own initial date.
  DEFAULT = 'default',
}

export enum EventPageDateRangeEnum {
  CUSTOM = 'custom',
  SINCE = 'since',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  SEVEN_DAYS = 'D7',
  FOURTEEN_DAYS = 'D14',
  THIRTY_DAYS = 'D30',
  THREE_MONTH = 'M3',
  SIX_MONTH = 'M6',
  TWELVE_MONTH = 'M12',
}
export type ValidDateRangeEnum = Exclude<DateRangeEnum, DateRangeEnum.DEFAULT>;

export const EventPageDateRangeMap: Record<ValidDateRangeEnum, EventPageDateRangeEnum> = {
  [DateRangeEnum.CUSTOM]: EventPageDateRangeEnum.CUSTOM,
  [DateRangeEnum.SINCE]: EventPageDateRangeEnum.SINCE,
  [DateRangeEnum.TODAY]: EventPageDateRangeEnum.TODAY,
  [DateRangeEnum.YESTERDAY]: EventPageDateRangeEnum.YESTERDAY,
  [DateRangeEnum.SEVEN_DAYS]: EventPageDateRangeEnum.SEVEN_DAYS,
  [DateRangeEnum.FOURTEEN_DAYS]: EventPageDateRangeEnum.FOURTEEN_DAYS,
  [DateRangeEnum.THIRTY_DAYS]: EventPageDateRangeEnum.THIRTY_DAYS,
  [DateRangeEnum.THREE_MONTH]: EventPageDateRangeEnum.THREE_MONTH,
  [DateRangeEnum.SIX_MONTH]: EventPageDateRangeEnum.SIX_MONTH,
  [DateRangeEnum.TWELVE_MONTH]: EventPageDateRangeEnum.TWELVE_MONTH,
};

export enum CompareType {
  TIME_PERIOD = 'time_period',
}

export enum CompareTimePeriodSubTypes {
  PERVIOUS_DAY = 'previous_day',
  PERVIOUS_WEEK = 'previous_week',
  PERVIOUS_MONTH = 'previous_month',
  PREVIOUS_QUARTER = 'previous_quarter',
  CUSTOM = 'custom',
}

export enum CompareTimePeriodCustomSubTypes {
  CUSTOM_START = 'custom_start',
  CUSTOM_END = 'custom_end',
}

export const INITIAL_POLLING_INTERVAL = 1000;
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export enum DimensionMathValues {
  TOTAL = 'total',
  UNIQUE = 'unique',
  SESSION = 'session',
  COUNT_USERS = 'count_users',
  AGGREGATE_PROPERTIES = 'aggregate_properties',
}

export enum DimensionMathCountUsers {
  DAU = 'dau',
  WAU = 'wau',
  MAU = 'mau',
}

export const AGGREGATE = 'aggregate';

export const PERCENTILE = 'percentile';

export const GRAPH_COLORS = [
  '528BFF',
  '22CCEE',
  '8098F9',
  'A48AFB',
  '2ED3B7',
  '3CCB7F',
  '86CB3C',
  '85E13A',
  'E8903E',
  'FF9C66',
  'FAC515',
  'E478FA',
  'F670C7',
  'FD6F8E',
  '7D89B0',
];

export const MAX_GRAPH_COLORS = GRAPH_COLORS.length;

export const MAX_FUNNEL_STEP_GROUP = 10;

export enum FunnelCountTypes {
  UNIQUES = 'unique',
  TOTALS = 'total',
  SESSION = 'session',
}

export enum FunnelOrderOptions {
  ANY_ORDER = 'ANY_ORDER',
  FIXED_ORDER = 'FIXED_ORDER',
}

export const FunnelOrderToggleOptions = [
  {
    label: 'Fixed Order',
    value: FunnelOrderOptions.FIXED_ORDER,
  },
  {
    label: 'Any Order',
    value: FunnelOrderOptions.ANY_ORDER,
  },
];

export enum FunnelMeasureTypes {
  TRENDS = 'TRENDS',
  CONVERSION = 'conversion',
}

export type FunnelMetricsKeys = 'avg_time_from_prev_step' | 'percent_from_prev_step' | 'total';

export const FUNNEL_METRICS: Record<FunnelMetricsKeys, string> = {
  avg_time_from_prev_step: 'Average Time from previous step',
  // avg_time_from_first_step: 'Average Time From Start',
  percent_from_prev_step: 'Conversion from previous step',
  // percent_from_first_step: 'Conversion from first step',
  total: 'Count',
};

export const FUNNEL_PERCENTAGE_METRICS: string[] = ['percent_from_first_step', 'percent_from_prev_step'];
export const FUNNEL_TIME_METRICS: string[] = ['avg_time_from_prev_step', 'avg_time_from_first_step'];

export enum FunnelType {
  STEPS = 'steps',
  TRENDS = 'trends',
  FUNNEL_DROPOFF = 'funnel_dropoff',
}

export enum FunnelTrendType {
  CONVERSION = 'conversion',
  TIME_TO_CONVERT = 'time_to_convert',
  TOP_OF_FUNNEL = 'top_of_funnel',
  BOTTOM_OF_FUNNEL = 'bottom_of_funnel',
}

export enum ViewMode {
  LINEAR = 'linear',
  LOGARITHMIC = 'logarithmic',
  CUMULATIVE = 'cumulative',
}

export enum FunnelTrendStep {
  ALL_STEPS = 'all_steps',
}

export enum PropertyAttribution {
  LAST_TOUCH = 'last_touch',
  FIRST_TOUCH = 'first_touch',
  PER_ATTRIBUTE = 'per_attribute',
}

export enum ValueType {
  TIME,
  PERCENTAGE,
  NUMBER,
  ACTUAL_PERCENTAGE,
}

export enum FlowsEventFilterType {
  INCLUDE_EVENTS = 'INCLUDE_EVENTS',
  EXCLUDE_EVENTS = 'EXCLUDE_EVENTS',
}

export enum ReportRunStatusType {
  INITIATED = 'INITIATED',
  QUERY_BUILT = 'QUERY_BUILT',
  RESULT_AGGREGATED = 'RESULT_AGGREGATED',
  FINISHED = 'FINISHED',
  ERRORED = 'ERRORED',
  PICKED_FOR_COMMUNICATION = 'PICKED_FOR_COMMUNICATION',
  COMMUNICATION_SENT = 'COMMUNICATION_SENT',
}

export const DATETIME_FORMAT_WITH_TIMEZONE = 'YYYY-MM-DDTHH:mm:ssZ';

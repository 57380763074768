import { Formula } from '@src/client/helpers/reports/types';
import { atom, useResetRecoilState } from 'recoil';

export const formulasState = atom<Formula[]>({
  key: 'formulasStateKey',
  default: [],
});

/* Helpers */

export const useResetFormulasState = () => {
  const resetFormulas = useResetRecoilState(formulasState);

  return () => {
    resetFormulas();
  };
};

import { LineChartData } from '@src/client/helpers/reports/types';

import TinyAreaChart from '../charts/d3/area-chart/TinyAreaChart';
import D3TinyLineChart from '../charts/d3/line-chart/TinyLineChart';

export default function MetricLineChartRenderer({
  lineChartData,
  chartDisplayType,
}: {
  lineChartData: LineChartData[];
  chartDisplayType: 'area' | 'line';
}) {
  if (chartDisplayType === 'line') {
    return (
      <div>
        <D3TinyLineChart lineChartData={lineChartData} chartContainerHeight={100} />
      </div>
    );
  }
  return (
    <div>
      <TinyAreaChart lineChartData={lineChartData} chartHeight={100} hideTooltip />
    </div>
  );
}

import { useIsSupermoneyTenant } from '@src/client/hooks';

import MetricOverview from './components/MetricOverview';
import RecentlyViewedReportOverview from './components/RecentlyViewedReportsOverview';
import RecentUsersOverview from './components/RecentUsersOverview';
import UserGreeting from './components/UserGreeting';

export default function HomeV3() {
  const isSupermoneyTenant = useIsSupermoneyTenant();
  return (
    <div className="pt-2 pb-20 space-y-2">
      <UserGreeting />
      {isSupermoneyTenant ? null : <MetricOverview />}
      <RecentlyViewedReportOverview />
      <RecentUsersOverview />
      {/* <LiveEventsOverview /> */}
    </div>
  );
}

import { SVGProps } from 'react';

const UserAvatarFillColors = [
  { path1: '#fff', path2: '#ECFDFF', path3: '#06AED4', path4: '#ECFDFF', path5: '#06AED4' },
  { path1: '#fff', path2: '#EEF4FF', path3: '#6172F3', path4: '#EEF4FF', path5: '#6172F3' },
  { path1: '#fff', path2: '#FFF4ED', path3: '#FF4405', path4: '#FFF4ED', path5: '#FF4405' },
  { path1: '#fff', path2: '#FDF4FF', path3: '#BA24D5', path4: '#FDF4FF', path5: '#BA24D5' },
  { path1: '#fff', path2: '#FFF1F3', path3: '#E31B54', path4: '#FFF1F3', path5: '#E31B54' },
  { path1: '#fff', path2: '#DFDFDF', path3: '#BEBEBE', path4: '#DFDFDF', path5: '#BEBEBE' },
];

const UserAvatarFillColorsLength = UserAvatarFillColors.length;

interface Props extends SVGProps<SVGSVGElement> {
  avatarIndex: number;
}

export function UserAvatarSvg({ avatarIndex, ...props }: Props) {
  const pathColors = UserAvatarFillColors[avatarIndex % UserAvatarFillColorsLength];
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={36} fill="none" {...props}>
      <path
        fill={pathColors.path1}
        d="M17.5 2.96c8.313 0 15.04 6.728 15.04 15.04 0 8.313-6.727 15.04-15.04 15.04-8.313 0-15.04-6.727-15.04-15.04 0-8.313 6.728-15.04 15.04-15.04Z"
      />
      <path
        fill={pathColors.path2}
        d="M17.5 2.96c-.547 0-1.094.028-1.613.083-.082 0-.164.027-.219.027 7.41.957 13.125 7.274 13.125 14.93s-5.715 13.973-13.125 14.93c.602.082 1.23.11 1.86.11 8.312 0 15.038-6.727 15.038-15.04C32.54 9.687 25.812 2.96 17.5 2.96Z"
      />
      <path
        fill={pathColors.path3}
        d="M17.5 33.86C8.75 33.86 1.64 26.75 1.64 18S8.75 2.14 17.5 2.14 33.36 9.25 33.36 18 26.25 33.86 17.5 33.86Zm0-30.079C9.652 3.781 3.281 10.152 3.281 18c0 7.848 6.371 14.219 14.219 14.219 7.848 0 14.219-6.371 14.219-14.219 0-7.848-6.371-14.219-14.219-14.219Z"
      />
      <path
        fill={pathColors.path4}
        d="M17.5 6.926c-5.879 0-10.664 4.758-10.664 10.664 0 2.16.656 4.183 1.75 5.851 1.914-2.898 5.168-4.785 8.887-4.785 3.718 0 7 1.914 8.886 4.785 1.121-1.668 1.75-3.691 1.75-5.851.055-5.906-4.73-10.664-10.609-10.664Zm0 9.379a3.98 3.98 0 0 1-3.992-3.992A3.98 3.98 0 0 1 17.5 8.32a3.98 3.98 0 0 1 3.992 3.992 3.997 3.997 0 0 1-3.992 3.993Z"
      />
      <path
        fill={pathColors.path5}
        d="M17.337 17.016c-2.489-.11-4.403-2.106-4.403-4.567a4.604 4.604 0 0 1 4.594-4.594 4.586 4.586 0 0 1 4.594 4.594c0 2.38-1.86 4.375-4.239 4.567-.109 0-.191 0-.273-.028-.11.028-.191.028-.273.028Zm.163-7.52a2.958 2.958 0 0 0-2.953 2.953 2.907 2.907 0 0 0 2.817 2.926c.055 0 .11 0 .191.027.055-.027.11-.027.192-.027a2.926 2.926 0 0 0 2.707-2.926c0-1.64-1.34-2.953-2.954-2.953Zm8.368 14.274a.855.855 0 0 1-.684-.356 9.18 9.18 0 0 0-15.367 0 .835.835 0 0 1-1.148.246.835.835 0 0 1-.247-1.148c2.051-3.063 5.414-4.895 9.078-4.895 3.665 0 7.028 1.832 9.051 4.867.246.383.137.875-.246 1.149a.756.756 0 0 1-.437.137Z"
      />
    </svg>
  );
}

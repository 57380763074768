import { SVGProps } from 'react';

export function PerceptCoinIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none" {...props}>
      <path
        d="M0 23.5C0 10.5234 10.5234 0 23.5 0C36.4766 0 47 10.5234 47 23.5C47 36.4766 36.4766 47 23.5 47C10.5234 47 0 36.4766 0 23.5Z"
        fill="#E5AA17"
      />
      <path
        d="M3.78876 23.4905C3.78876 12.6113 12.6113 3.78876 23.4905 3.78876C34.3697 3.78876 43.1922 12.6113 43.1922 23.4905C43.1922 34.3697 34.3697 43.1922 23.4905 43.1922C12.6113 43.1922 3.78876 34.3697 3.78876 23.4905Z"
        stroke="white"
        strokeWidth="1.51552"
      />
      <path
        d="M16.8917 19.1461C16.8917 18.3408 17.5445 17.688 18.3498 17.688H21.8128C22.6181 17.688 23.2709 18.3408 23.2709 19.1461V32.0866C23.2709 34.5025 21.3124 36.4609 18.8966 36.4609H18.3498C17.5445 36.4609 16.8917 35.8081 16.8917 35.0028V19.1461Z"
        fill="white"
      />
      <path
        d="M24.3644 26.0722C24.3644 26.8775 25.0172 27.5303 25.8225 27.5303H26.3693C28.7851 27.5303 30.7435 25.5718 30.7435 23.156V19.1463C30.7435 18.341 30.0907 17.6882 29.2854 17.6882H25.8225C25.0172 17.6882 24.3644 18.341 24.3644 19.1463V26.0722Z"
        fill="white"
      />
      <path
        d="M24.1822 10.2153C24.9874 10.2153 25.6403 10.8681 25.6403 11.6734L25.6403 15.1364C25.6403 15.9417 24.9874 16.5945 24.1822 16.5945L15.6159 16.5945C14.8106 16.5945 14.1578 15.9417 14.1578 15.1364L14.1578 14.5896C14.1578 12.1738 16.1162 10.2153 18.5321 10.2153L24.1822 10.2153Z"
        fill="white"
      />
      <path
        d="M31.6548 16.5945C32.4601 16.5945 33.1129 15.9417 33.1129 15.1364L33.1129 14.5896C33.1129 12.1738 31.1545 10.2153 28.7386 10.2153L28.1919 10.2153C27.3866 10.2153 26.7338 10.8681 26.7338 11.6734L26.7338 15.1364C26.7338 15.9417 27.3866 16.5945 28.1919 16.5945L31.6548 16.5945Z"
        fill="white"
      />
    </svg>
  );
}

export function WalletIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" fill="#EAECF5" />
      <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" stroke="#EAECF0" />
      <path
        d="M28.5 26H28.51M15 17V31C15 32.1046 15.8954 33 17 33H31C32.1046 33 33 32.1046 33 31V21C33 19.8954 32.1046 19 31 19L17 19C15.8954 19 15 18.1046 15 17ZM15 17C15 15.8954 15.8954 15 17 15H29M29 26C29 26.2761 28.7761 26.5 28.5 26.5C28.2239 26.5 28 26.2761 28 26C28 25.7239 28.2239 25.5 28.5 25.5C28.7761 25.5 29 25.7239 29 26Z"
        stroke="#363F72"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

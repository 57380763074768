import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { Button } from '@src/client/ui-library/button';
import { FileDownloadIcon } from '@src/client/ui-library/icons/ReportIcons';
import { useToast } from '@src/client/ui-library/toast/use-toast';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { downloadCohortCsv } from '../api/mutations';
import { cohortMetaState } from '../recoil/atoms';
import { DownloadItemResponse } from '../types';
import { initiateDownload } from '../utils/cohortUtils';

export default function StaticCohortViewArea() {
  const { itemExtId } = useParams<{ itemExtId: string }>();
  const { toast } = useToast();

  const downloadCsvRequest = useMutation(downloadCohortCsv, {
    onSuccess: (response: DownloadItemResponse) => {
      initiateDownload(response.url);
      toast({
        variant: 'success',
        title: 'Download cohort file successfully',
        description: '',
      });
    },
    onError: (error: Error, variables) => {
      // eslint-disable-next-line no-console
      toast({
        variant: 'danger',
        title: 'Unable to download cohort file',
        description: 'There was an error while downloading cohort file',
      });
      Tracker.trackError(error, ErrorTags.COHORT_FILE_DOWNLOAD_ERROR, {
        itemExtId: variables.itemExtId,
      });
    },
    retry: 0,
  });

  const downloadCohortFile = () => {
    if (itemExtId != null) {
      downloadCsvRequest.mutateAsync({
        itemExtId,
      });
    }
  };

  const cohortMeta = useRecoilValue(cohortMetaState);
  return (
    <div className="border border-border rounded-xl p-2 pb-0">
      <div className="flex flex-wrap items-center gap-2">
        {cohortMeta.firstTen?.split(',').map((val) => (
          <div
            key={val + Math.random().toString()}
            className="border border-border rounded-lg py-1 px-1.5 flex gap-2 text-gray-500"
          >
            {val.trim()}
          </div>
        ))}
        {cohortMeta.count &&
          cohortMeta.count > 10 &&
          `+${cohortMeta.count - 10} User ID${cohortMeta.count !== 1 ? "'s" : ''}`}
      </div>
      <Button
        variant="link"
        className="px-0 flex gap-1 mt-3 text-lg hover:no-underline"
        onClick={downloadCohortFile}
        loading={downloadCsvRequest.isLoading}
      >
        <FileDownloadIcon />
        <p className="font-bold mr-2 dark:text-white">Download</p>
        <p className="font-semibold text-gray-700 dark:text-gray-400">cohort.csv</p>
      </Button>
    </div>
  );
}
function getLinkWithWorkspace(arg0: string): any {
  throw new Error('Function not implemented.');
}

import { isLengthyArray } from '@src/client/lib/utils';
import { atom, selector } from 'recoil';

import { SdkInfoResV2 } from './types';

export const sdkApiTokenState = atom<string>({
  key: 'sdkApiTokenState',
  default: '',
});

export const sdkIntegrationErrorState = atom<Error | unknown>({
  key: 'sdkIntegrationErrorState',
  default: undefined,
});

export const integratedSdksState = atom<SdkInfoResV2[]>({
  key: 'integratedSdksState',
  default: [],
});

export const isSdkLoadingState = atom<boolean>({
  key: 'isSdkLoadingState',
  default: true,
});

export const isPISdkIntegratedState = selector({
  key: 'isPISdkIntegratedState',
  get: ({ get }) => {
    const integratedSdks = get(integratedSdksState);
    const isLoading = get(isSdkLoadingState);

    return !isLoading && isLengthyArray(integratedSdks);
  },
});

import { atom, useResetRecoilState } from 'recoil';

import { FunnelTrendType } from '../../../helpers/reports/constants';

export const funnelTrendTypeState = atom<FunnelTrendType>({
  key: 'funnelTrendTypeStateV2',
  default: FunnelTrendType.CONVERSION,
});

/** Helpers */

export const useResetFunnelTrendTypeState = () => {
  const resetFunnelTrendTypeState = useResetRecoilState(funnelTrendTypeState);

  return () => {
    resetFunnelTrendTypeState();
  };
};

import { atom } from 'recoil';

import { ReportFilterOptionType } from '.';

export const activeReportTypeFiltersState = atom<Array<ReportFilterOptionType>>({
  key: 'activeReportTypeFiltersState',
  default: [],
});

export const searchActiveReportTypeFiltersState = atom<Array<ReportFilterOptionType>>({
  key: 'searchActiveReportTypeFiltersState',
  default: [],
});

import { MetricCardType } from './constants';
import MetricCard from './MetricCard';

interface Props {
  virtualAllEventId: string;
}

export default function EventVolumeCard({ virtualAllEventId }: Props) {
  return (
    <MetricCard virtualAllEventId={virtualAllEventId} metricType={MetricCardType.EventVolume} chartDisplayType="area" />
  );
}

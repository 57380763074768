import {
  useHandleViewUsersClick,
  useShowViewUsers,
} from '@src/client/components/common-report-components/reportUserViewHooks';
import { ChartType } from '@src/client/helpers/reports/constants';
import { ReportType } from '@src/client/modules/alerts/types';
import { PageType } from '@src/client/routes/types';
import { UsersIconV3 } from '@src/client/ui-library/icons/v3';
import React, { useRef } from 'react';

import { D3LineData } from './types';

interface DropdownMenuProps {
  activeDataPoint: D3LineData | null;
  x: number;
  y: number;
  chartWidth: number;
  chartHeight: number;
  pageType?: PageType;
}

export default function DropdownMenu({
  activeDataPoint,
  x,
  y,
  chartWidth,
  chartHeight,
  pageType = undefined,
}: DropdownMenuProps) {
  const showViewUsers = useShowViewUsers();
  const handleViewUsersClick = useHandleViewUsersClick({
    selectedTooltipData: activeDataPoint as D3LineData,
    pageType,
    chartType: ChartType.LINE,
    reportType: ReportType.INSIGHT,
  });
  const innerWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownWidth = 160;
  const dropdownHeight = 40;

  // Set Position of dropdown menu
  const potentialTop = y - dropdownHeight - 25;
  const potentialBottom = y + 10;

  const transformY =
    potentialTop > 0
      ? Math.max(0, potentialTop)
      : potentialBottom + dropdownHeight < chartHeight
        ? potentialBottom
        : y - dropdownHeight / 2;

  const transformX = x + dropdownWidth > chartWidth ? x - dropdownWidth - 10 : x + 10;

  const handleClick = () => {
    if (showViewUsers) {
      handleViewUsersClick();
    }
  };

  return (
    <foreignObject
      className="w-full h-full"
      style={{ transform: `translate(${transformX}px, ${transformY}px)`, pointerEvents: 'none' }}
      onClick={(event) => event.stopPropagation()}
    >
      <div
        className="border border-border rounded-lg bg-background text-foreground p-2 w-[160px] min-h-[40px]"
        style={{ pointerEvents: 'auto' }}
      >
        <div className="flex flex-col justify-start items-start" ref={innerWrapperRef}>
          <div
            className={`flex items-center w-full text-sm rounded px-2 py-1 bg-slate-100 dark:bg-slate-700 mb-1"
              ${!showViewUsers ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            role="button"
            tabIndex={0}
            onClick={handleClick}
            onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && handleClick()}
          >
            <UsersIconV3 className="mr-2" />
            <span>View Users</span>
          </div>
          {!showViewUsers && <p className="text-xs text-warning">View users is not available</p>}
        </div>
      </div>
    </foreignObject>
  );
}

import { MetricCardType } from './constants';
import MetricCard from './MetricCard';

interface Props {
  virtualAllEventId: string;
}

export default function DAUCard({ virtualAllEventId }: Props) {
  return <MetricCard virtualAllEventId={virtualAllEventId} metricType={MetricCardType.DAU} chartDisplayType="area" />;
}

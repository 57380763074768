/* eslint-disable no-param-reassign */
import { FlattenLineChartData, LineChartData, LineChartDataPoint } from '@src/client/helpers/reports/types';
import dayjs from 'dayjs';

import { D3LineData } from './types';

export const DEFAULT_MARGIN = { top: 20, right: 20, bottom: 20, left: 30 };

export const getAllDataPoints = (lineChartData: LineChartData[]): LineChartDataPoint[] => {
  const dataPoints = lineChartData.reduce((accm, item) => [...accm, ...item.data], [] as LineChartDataPoint[]);
  return dataPoints;
};

export const getFlattenLineData = (lineChartData: LineChartData[]): FlattenLineChartData[] => {
  const dataPoints = lineChartData.reduce((accm, item) => {
    accm = [...accm, ...item.data.map((d) => ({ ...d, key: item.key, color: item.color, series: item.series }))];
    return accm;
  }, [] as FlattenLineChartData[]);
  return dataPoints;
};

export const getFormattedLineDataPoints = (lineChartData: LineChartData[], useLinear = false): D3LineData[] => {
  const dataPoints = lineChartData
    .reduce((accm, item) => {
      accm = [
        ...accm,
        ...item.data
          .filter((i) => i.date === 0 || i.date || i.compareDate)
          .map((d) => {
            if (useLinear) {
              return {
                value: d.value ?? 0,
                date: d.date,
                compareDate: d.compareDate,
                key: item.key,
                color: item.color,
                series: item.series,
              };
            }
            return {
              value: d.value ?? 0,
              date: !d.date && d.compareDate ? dayjs.unix(d.compareDate).valueOf() : dayjs.unix(d.date).valueOf(),
              compareDate: d.compareDate ? dayjs.unix(d.compareDate).valueOf() : dayjs.unix(d.date).valueOf(),
              key: item.key,
              color: item.color,
              series: item.series,
            };
          }),
      ];
      return accm;
    }, [] as D3LineData[])
    .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1));
  return dataPoints;
};

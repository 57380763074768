/* eslint-disable import/no-extraneous-dependencies */
import {
  AuthenticationResult,
  BrowserCacheLocation,
  Configuration,
  EndSessionPopupRequest,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import { Buffer } from 'buffer';

import { ErrorTags } from './analytics/events';
import Tracker from './analytics/tracker';

const { VITE_MICOSOFT_CLIENT_ID, VITE_MICOSOFT_TENANT_ID } = import.meta.env;
const msalConfig: Configuration = {
  auth: {
    clientId: VITE_MICOSOFT_CLIENT_ID,
    // authority: `https://login.microsoftonline.com/${VITE_MICOSOFT_TENANT_ID}`,
    authority: `https://login.microsoftonline.com/common`,
    redirectUri: '/blank.html',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    allowRedirectInIframe: true,
  },
};

let msalInstance: PublicClientApplication;

export function generateMsalInstance() {
  msalInstance = new PublicClientApplication(msalConfig);
  return msalInstance;
}

export const getExpiryFromMsAuthToken = (idToken: string): string =>
  JSON.parse(Buffer.from(idToken.split('.')[1], 'base64').toString()).exp;

export const isLoginScreen = () => window.location.pathname === '/login';

export const acquireAccessToken = (): Promise<AuthenticationResult | void> => {
  if (!msalInstance) {
    if (!isLoginScreen()) {
      window.location.replace('/login');
    }
    console.log('MSAL instance not found');
    return Promise.reject(new Error('MSAL instance not found.'));
  }

  if (msalInstance && msalInstance.getActiveAccount() == null) {
    console.log('MSAL instance found but no active account found');
  }

  const accounts = msalInstance.getAllAccounts();
  const activeAccount = msalInstance.getActiveAccount() || accounts[0];

  return msalInstance
    .acquireTokenSilent({
      scopes: ['User.Read'],
      account: activeAccount || accounts[0],
      forceRefresh: true,
    })
    .catch((error) => {
      console.log('error while refreshing token', error);
      Tracker.trackError(error, ErrorTags.REFRESH_TOKEN_ERROR);
      if (activeAccount !== null && error instanceof InteractionRequiredAuthError) {
        return msalInstance
          .acquireTokenRedirect({
            scopes: ['User.Read'],
            account: activeAccount,
            loginHint: activeAccount.username,
          })
          .catch((err2) => {
            console.error('error while refreshing token with popup', err2);
            Tracker.trackError(error, ErrorTags.REFRESH_TOKEN_ERROR);
            if (!isLoginScreen()) {
              window.location.replace('/login');
            }
            return Promise.reject(err2);
          });
      }
      if (activeAccount) {
        Tracker.trackError(Error('Error with active account.'), ErrorTags.AUTHORIZE_ERROR);
      }
      console.log(activeAccount ? 'Error with active account.' : 'No active account found.');
      if (!isLoginScreen()) {
        window.location.replace('/login');
      }
      return Promise.reject(error);
    });
};

export const msalLogout = async () => {
  if (msalInstance) {
    const accounts = msalInstance.getAllAccounts();
    const activeAccount = msalInstance.getActiveAccount() || accounts[0];
    const request: EndSessionPopupRequest = {
      account: activeAccount,
      // mainWindowRedirectUri: '/login',
      postLogoutRedirectUri: '/login',
      // logoutHint: activeAccount.idTokenClaims?.login_hint,
    };
    await msalInstance.logoutRedirect(request);
  }
  return null;
};

import { atom, useResetRecoilState } from 'recoil';

import { FunnelType } from '../../../helpers/reports/constants';

export const funnelTypeState = atom<FunnelType>({
  key: 'funnelTypeStateV2',
  default: FunnelType.STEPS,
});

/** Helpers */

export const useResetFunnelTypeState = () => {
  const resetFunnelTypeState = useResetRecoilState(funnelTypeState);

  return () => {
    resetFunnelTypeState();
  };
};

import { getProperties, getValuesByCohortName, getValuesByEventName } from '@src/client/lib/api/queries/common';
import { QueryKeys } from '@src/client/lib/api/queries/keys';
import { CohortBasicItem, DimensionDetailed } from '@src/client/lib/api/types/response';
import { getUseQueryOptions } from '@src/client/lib/api/utils';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { CohortDropdownIcon, EventDropdownIcon, EventPropertyDropdownIcon } from '../../ui-library/icons/ReportIcons';
import { CohortCreateDropdownOptionType, PropertyType } from './types';

// options -> Events & Propeties & Cohorts
export const useAllCohortSelectDropdownOptions = (): {
  loading: boolean;
  options: Array<CohortCreateDropdownOptionType>;
  eventsList: Array<CohortCreateDropdownOptionType>;
  cohortsList: Array<CohortCreateDropdownOptionType>;
  propertiesList: Array<CohortCreateDropdownOptionType>;
} => {
  const [optionsList, setOptionsList] = useState<CohortCreateDropdownOptionType[]>([]);
  const [propertiesList, setPropertieslist] = useState<CohortCreateDropdownOptionType[]>([]);
  const [eventsList, setEventslist] = useState<CohortCreateDropdownOptionType[]>([]);
  const [cohortsList, setCohortslist] = useState<CohortCreateDropdownOptionType[]>([]);
  const fetchProperties = useQuery([{ key: QueryKeys.GetDetailedDimensions }], getProperties, getUseQueryOptions());
  const fetchCohorts = useQuery([QueryKeys.GetCohorts], getValuesByCohortName, getUseQueryOptions());
  const fetchEvents = useQuery(
    [QueryKeys.GetAllEvents],
    () => getValuesByEventName('event_name'),
    getUseQueryOptions(),
  );

  useEffect(() => {
    if (fetchEvents.isSuccess && fetchCohorts.isSuccess && fetchProperties.isSuccess) {
      const tempEvents = fetchEvents.data.map(
        (value: string): CohortCreateDropdownOptionType => ({
          label: value,
          value,
          icon: <EventDropdownIcon />,
          type: PropertyType.EVENT,
        }),
      );
      const tempCohorts = fetchCohorts.data.map(
        (cohortValue: CohortBasicItem): CohortCreateDropdownOptionType => ({
          label: cohortValue.name,
          value: cohortValue.itemExternalId,
          icon: <CohortDropdownIcon />,
          type: PropertyType.COHORT,
        }),
      );
      const tempEventProperties = fetchProperties.data
        .filter((i) => i.column !== '__time')
        .map(
          (prop: DimensionDetailed): CohortCreateDropdownOptionType => ({
            label: prop.column,
            value: prop.column,
            icon: <EventPropertyDropdownIcon />,
            type: PropertyType.USER,
          }),
        );
      setEventslist([...tempEvents]);
      setCohortslist([...tempCohorts]);
      setPropertieslist([...tempEventProperties]);
      setOptionsList([...tempEvents, ...tempCohorts, ...tempEventProperties]);
    }
  }, [
    fetchCohorts.isSuccess,
    fetchCohorts.data,
    fetchEvents.isSuccess,
    fetchEvents.data,
    fetchProperties.data,
    fetchProperties.isSuccess,
  ]);

  return {
    loading: fetchCohorts.isLoading || fetchEvents.isLoading || fetchProperties.isLoading,
    options: optionsList,
    eventsList,
    cohortsList,
    propertiesList,
  };
};

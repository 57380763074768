import { FunnelStep } from '@src/client/helpers/reports/types';
import { isLengthyArray } from '@src/client/lib/utils';
import { atom, selector } from 'recoil';

/** Helpers */
export const getEmptyFunnelStepsState = (): FunnelStep[] => [
  {
    alias: `1`,
    first_time_filter: false,
    event: undefined,
    filter: [],
    step_label: '',
    is_excluded: false,
  },
  {
    alias: `2`,
    first_time_filter: false,
    event: undefined,
    filter: [],
    step_label: '',
    is_excluded: false,
  },
];

export const funnelStepsState = atom<FunnelStep[]>({
  key: 'funnelStepsState',
  default: getEmptyFunnelStepsState(),
});

export const isCompareEnabledInAnyStepSelector = selector({
  key: 'isCompareEnabledInAnyStepSelector',
  get: ({ get }) => {
    const funnelSteps = get(funnelStepsState);
    return funnelSteps.some((step) => isLengthyArray(step.compare));
  },
});

export const validFunnelStepsState = selector({
  key: 'validFunnelStepsState',
  get: ({ get }) => {
    const funnelSteps = get(funnelStepsState);

    const validFunnelSteps = funnelSteps.filter((item) => {
      if (isLengthyArray(item.compare)) {
        return item.compare?.every(
          (compareItem) => compareItem.event && compareItem.event !== '' && !compareItem.is_excluded,
        );
      }
      return item.event && item.event !== '' && !item.is_excluded;
    });
    return validFunnelSteps;
  },
});

import { EventNames } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { ApiError } from '@src/client/lib/api/errors';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '../../ui-library/button';
import { cn } from '../../ui-library/utils';

interface Props {
  error: ApiError | Error | unknown;
  className?: string;
  imageClassName?: string;
  style?: CSSProperties;
  retry?: () => void;
}

const getErrorImage = (error: ApiError | Error | unknown): string => {
  if (error instanceof ApiError) {
    switch (error.status) {
      case 403:
        return '/images/v2/errors/403.svg';
      case 404:
        return '/images/v2/errors/404.svg';
      case 429:
        return '/images/v2/errors/429.svg';
      case 422:
        return '/images/v2/errors/403.svg';
      case 500:
        return '/images/v2/errors/500.svg';
      case 503:
        return '/images/v2/errors/503.svg';
      case 504:
        return '/images/v2/errors/504.svg';
      default:
        return '/images/v2/errors/429.svg';
    }
  }
  return '/images/v2/errors/429.svg';
};

export default function ApiErrorView({ error, retry, style = {}, className = '', imageClassName = '' }: Props) {
  const [details, setDetails] = useState<string>();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const errorTraceId = useRef(`pi-error-${uuidv4()}`);

  const trackAndSetDetails = (cause: string) => {
    Tracker.trackEvent(
      EventNames.API_ERROR,
      {},
      {
        apiErrorDetails: cause.slice(0, 500),
        errorTraceId: errorTraceId.current,
        apiErrorStatus: (error as ApiError).status,
        apiErrorStatusText: (error as ApiError).statusText,
      },
    );
    setDetails(
      `Something went wrong. We are looking into it. Please reach out to PerceptInsight support and share this id for faster resolution: ${errorTraceId.current}`,
    );
  };

  useEffect(() => {
    const handleDetailsRetrieval = async () => {
      const res = await (error as ApiError).details;
      trackAndSetDetails(res);
    };
    if (error instanceof ApiError) {
      handleDetailsRetrieval();
      return;
    }
    if ((error as Error)?.cause) {
      trackAndSetDetails((error as Error)?.cause as string);
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={cn('flex flex-col  m-auto items-center', className)} style={{ ...style }}>
      <img src={getErrorImage(error)} alt="Error" className={cn('mb-6 mx-auto w-[100px]', imageClassName)} />
      {retry ? <Button onClick={retry}>Retry</Button> : null}
      {error !== null && (error as ApiError).status === 422 ? (
        <div className="grid grid-cols-1 text-center">
          <p className=" text-base text-gray-400">Report contains private data</p>
          <p className="text-xs text-gray-500">
            This content is currently inaccessible due to containing private data. Please reach out to your
            administrator to request access.
          </p>
        </div>
      ) : details ? (
        <>
          <Button variant="link" className="text-danger opacity-80" onClick={() => setShowDetails(!showDetails)}>
            {showDetails ? 'Hide' : 'Show'} Details
          </Button>
          {showDetails ? (
            <p className=" text-center text-xs text-danger opacity-70 max-w-[420px]">
              {details.replace('[QUERY CONSTRUCTOR] [500]', '')}
            </p>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

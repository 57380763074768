import { DataGovernanceEndpoints } from '@src/client/lib/api/constants';
import { fetchEndpoint, PlatformService, UserGovService } from '@src/client/lib/api/utils';

import { CreateWorkspaceRequest, CreateWorkspaceResponse, WorkspaceByOrg } from '../types';
import { OnboardingEndpoints } from './endpoints';

export const createWorkspace = async (payload: CreateWorkspaceRequest) => {
  const data: CreateWorkspaceResponse = await fetchEndpoint(
    OnboardingEndpoints.createWorkspace,
    {},
    {},
    payload,
    'POST',
    PlatformService,
  );
  return data;
};

export const getWorkspacesByOrg = async () => {
  const data: WorkspaceByOrg[] = await fetchEndpoint(
    OnboardingEndpoints.getWorkspaceByOrg,
    {},
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getIsWorkspaceAvailable = async () => {
  const data: boolean = await fetchEndpoint(
    DataGovernanceEndpoints.isWorkspaceAvailable,
    {},
    {},
    {},
    'GET',
    UserGovService,
  );
  return data;
};

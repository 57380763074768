/* eslint-disable import/prefer-default-export */
export enum CohortEndpoints {
  cohortList = '/cohort/all',
  createCohort = '/cohort/item',
  initiateRun = '/cohort/run',
  cohortSummariesList = '/cohort',
  cohortByItemExtId = '/cohort/{item_ext_id}',
  basicList = '/cohort/list',
  cohortCount = '/cohort/count',
  cohortUserCount = '/cohort/{item_ext_id}/count',
  uploadCsvFile = '/cohort/item/file',
  downloadCsvFile = '/cohort/{item_ext_id}/download',
  createUsersCohort = '/cohort/item/users',
  downloadCohortUserStream = '/cohort/{item_ext_id}/download/stream',
}

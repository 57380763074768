import {
  customDateRangeState,
  dateRangeState,
} from '@src/client/components/filters-and-selectors/date-range-selector/state';
import { dimensionsStateV2 } from '@src/client/components/filters-and-selectors/dimension-filter/state';
import {
  globalFiltersStateV2,
  validGlobalFiltersState,
} from '@src/client/components/filters-and-selectors/global-property-filter/state';
import { granularityState } from '@src/client/components/filters-and-selectors/granularity-selector';
import { retentionSubtypeState } from '@src/client/components/filters-and-selectors/retention-subtype-selector';
import { DateRangeEnum, RetentionSubtypeEnum } from '@src/client/helpers/reports/constants';
import { RetentionQueryBuilder } from '@src/client/helpers/reports/types';
import { isLengthyArray } from '@src/client/lib/utils';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  isRetentionLoadingState,
  onAndAfterState,
  retentionChartDataState,
  retentionDescriptionState,
  retentionErrorState,
  retentionNameState,
  retentionPvtDimnErrState,
  retentionRowsSelectionState,
  retentionRowState,
  retentionRunIdState,
  showCountsInRetention,
} from '../state';

export const useResetAllRetentionAtomValues = () => {
  const resetDimensionState = useResetRecoilState(dimensionsStateV2);
  const resetGranularityState = useResetRecoilState(granularityState);
  const setDateRangeState = useSetRecoilState(dateRangeState);
  const resetCustomDateRange = useResetRecoilState(customDateRangeState);
  const resetRetentionName = useResetRecoilState(retentionNameState);
  const resetRetentionDescription = useResetRecoilState(retentionDescriptionState);
  const resetRetentionRunId = useResetRecoilState(retentionRunIdState);
  const resetRetentionConfigLoadingState = useResetRecoilState(isRetentionLoadingState);
  const resetRetentionRows = useResetRecoilState(retentionRowState);
  const resetRetentionSelectedRows = useResetRecoilState(retentionRowsSelectionState);
  const resetChartData = useResetRecoilState(retentionChartDataState);
  const resetRetentionError = useResetRecoilState(retentionErrorState);
  const resetGlobalFiltersState = useResetRecoilState(globalFiltersStateV2);
  const resetShowCounts = useResetRecoilState(showCountsInRetention);
  const resetPvtDimnErrorState = useResetRecoilState(retentionPvtDimnErrState);
  const resetRetentionSubtypeState = useResetRecoilState(retentionSubtypeState);
  const resetOnAndAfter = useResetRecoilState(onAndAfterState);

  return () => {
    setDateRangeState(DateRangeEnum.YESTERDAY);
    resetRetentionRunId();
    resetRetentionConfigLoadingState();
    resetRetentionSelectedRows();
    resetRetentionRows();
    resetChartData();
    resetDimensionState();
    resetGlobalFiltersState();
    resetGranularityState();
    resetRetentionSubtypeState();
    resetCustomDateRange();
    resetRetentionName();
    resetRetentionDescription();
    resetRetentionError();
    resetShowCounts();
    resetOnAndAfter();
    resetPvtDimnErrorState();
  };
};

export const useSetAllRetentionAtomValues = () => {
  const setReportName = useSetRecoilState(retentionNameState);
  const setReportDescription = useSetRecoilState(retentionDescriptionState);
  const setDimensionState = useSetRecoilState(dimensionsStateV2);
  const setGranularityState = useSetRecoilState(granularityState);
  const setDateRangeState = useSetRecoilState(dateRangeState);
  const setCustomDateRange = useSetRecoilState(customDateRangeState);
  const setGlobalFiltersState = useSetRecoilState(globalFiltersStateV2);
  const setRetentionSubtypeState = useSetRecoilState(retentionSubtypeState);
  const setOnAndAfterEnabledState = useSetRecoilState(onAndAfterState);

  return (data: RetentionQueryBuilder) => {
    setReportName(data.retentionTitle);
    setReportDescription(data.retentionDescription);
    setDimensionState(data.dimensions);
    setGranularityState(data.granularity);
    setGlobalFiltersState(data['global-filters'] || []);
    setDateRangeState(data.dateRange);
    if (data.dateRange === DateRangeEnum.CUSTOM && isLengthyArray(data.customDateRange)) {
      setCustomDateRange(data.customDateRange!);
    }
    if (data.dateRange === DateRangeEnum.SINCE && isLengthyArray(data.sinceDateRange)) {
      setCustomDateRange(data.sinceDateRange!);
    }
    if (data.reportSubtype) {
      setRetentionSubtypeState(data.reportSubtype);
    } else {
      setRetentionSubtypeState(RetentionSubtypeEnum.repeat);
    }
    if (data.onAndAfterEnabled) {
      setOnAndAfterEnabledState('onAndAfter');
    } else {
      setOnAndAfterEnabledState('on');
    }
  };
};

export const useGetRetentionQueryBuilderData = (): RetentionQueryBuilder => {
  const dimensions = useRecoilValue(dimensionsStateV2);
  const globalFilters = useRecoilValue(validGlobalFiltersState);
  const granularity = useRecoilValue(granularityState);
  const dateRange = useRecoilValue(dateRangeState);
  const retentionTitle = useRecoilValue(retentionNameState);
  const retentionDescription = useRecoilValue(retentionDescriptionState);
  const customDateRange = useRecoilValue(customDateRangeState);
  const reportSubtype = useRecoilValue(retentionSubtypeState);
  const onAndAfter = useRecoilValue(onAndAfterState);

  const onAndAfterEnabled = onAndAfter === 'onAndAfter';

  return {
    retentionTitle,
    retentionDescription,
    dimensions,
    granularity,
    dateRange,
    customDateRange,
    sinceDateRange: customDateRange,
    'global-filters': globalFilters,
    reportSubtype,
    onAndAfterEnabled,
  };
};

export const useGetAllRetentionAtomValues = (): RetentionQueryBuilder => {
  const dimensions = useRecoilValue(dimensionsStateV2);
  const globalFilters = useRecoilValue(validGlobalFiltersState);
  const granularity = useRecoilValue(granularityState);
  const dateRange = useRecoilValue(dateRangeState);
  const retentionTitle = useRecoilValue(retentionNameState);
  const retentionDescription = useRecoilValue(retentionDescriptionState);
  const customDateRange = useRecoilValue(customDateRangeState);
  const reportSubtype = useRecoilValue(retentionSubtypeState);

  const onAndAfter = useRecoilValue(onAndAfterState);

  const onAndAfterEnabled = onAndAfter === 'onAndAfter';

  return {
    retentionTitle,
    retentionDescription,
    dimensions,
    'global-filters': globalFilters,
    granularity,
    dateRange,
    customDateRange,
    reportSubtype,
    onAndAfterEnabled,
  };
};

import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { getDashboardSummariesByUser } from '@src/client/lib/api/queries/common';
import { QueryKeys } from '@src/client/lib/api/queries/keys';
import { ItemStatus } from '@src/client/lib/api/types/request';
import { DropdownMenuSeparator } from '@src/client/ui-library/dropdown';
import { BoardsIconV3 } from '@src/client/ui-library/icons/v3';
import { Skeleton } from '@src/client/ui-library/skeleton';
import { cn } from '@src/client/ui-library/utils';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import LucidIconWrapper from '../lucid-icon-wrapper';
import { SidepanelActiveNavClassNames, SidepanelNavLinkClassNames } from './constants';
import SidepanelNavLink from './SidepanelNavLink';

export default function DashboardNavLinksV3({ currentPathname }: { currentPathname: string }) {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useQuery(
    [QueryKeys.GetDashboardSummariesByUser, true],
    () =>
      getDashboardSummariesByUser({
        offset: 0,
        configStatus: ItemStatus.APPROVED,
        limit: 100,
        currentUserOnly: true,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  return isLoading ? (
    <>
      {[...Array(5).keys()].map((i) => (
        <Skeleton key={i} className="h-6 w-full mb-3" />
      ))}
    </>
  ) : (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={() => navigate(getLinkWithWorkspace(`dashboard`))}
        onKeyDown={() => navigate(getLinkWithWorkspace(`dashboard`))}
        className={cn(
          'p-2',
          SidepanelNavLinkClassNames,
          location.pathname.split('/').length === 3 && location.pathname.split('/')[2] === 'dashboard'
            ? SidepanelActiveNavClassNames
            : '',
        )}
      >
        <LucidIconWrapper name="plus" />
        <div className="ml-3 text-sm font-semibold">Create new</div>
      </div>
      {(data ?? []).length > 0 ? <DropdownMenuSeparator className="px-2 my-2" /> : null}
      {(data ?? []).map((dashboardData, index) => (
        <div key={dashboardData.configId}>
          <SidepanelNavLink
            isNavigationSidepanelOpen
            path={`dashboard/${dashboardData.itemExternalId}`}
            text={dashboardData.name}
            icon={<BoardsIconV3 active={currentPathname.includes(dashboardData.itemExternalId)} />}
          />
          {index !== data!.length - 1 ? <DropdownMenuSeparator className="px-2 my-2" /> : null}
        </div>
      ))}
    </>
  );
}

import { isPastCompareString, replacePastFlagFromCompareString } from '@src/client/helpers/reports/dataUtils';
import { PageType } from '@src/client/routes/types';
import { useTheme } from '@src/client/ui-library/theme-provider';
import * as d3 from 'd3';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { isDateCompareEnabledSelector } from '../../../filters-and-selectors/compare-selector/atoms';
import { D3LineData } from './types';

interface Props {
  handleDotClick?: () => void;
  activeDataPoint?: D3LineData | null;
  lineData: D3LineData[];
  activeLine?: string;
  xScale: d3.ScaleTime<number, number, never> | d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleLinear<number, number, never>;
  showDots?: boolean;
  isTinyChart?: boolean;
  pageType?: PageType;
}

const activeLineMatcher = (lineKey: string, activeLineKey: string) =>
  // TODO: Ensure the comparison line and main line aren't active at the same time.
  // Issue: Since the data table doesn't have separate rows for the comparison and main line,
  // both are currently shown as active.
  replacePastFlagFromCompareString(lineKey) === replacePastFlagFromCompareString(activeLineKey);

export default function LinePath({
  isTinyChart,
  activeDataPoint,
  lineData,
  activeLine,
  xScale,
  yScale,
  showDots,
  handleDotClick,
  pageType = undefined,
}: Props) {
  const isCompareEnabled = useRecoilValue(isDateCompareEnabledSelector);
  const { theme } = useTheme();

  const lineBuilder = useMemo(
    () =>
      d3
        .line<D3LineData>()
        .x((d) => xScale(isCompareEnabled ? d.compareDate! : d.date))
        .y((d) => yScale(d.value)),
    [isCompareEnabled, xScale, yScale],
  );

  const linePath = useMemo(() => lineBuilder(lineData), [lineBuilder, lineData]);

  if (!linePath) {
    return null;
  }

  const activePoint = lineData.find((d) => d === activeDataPoint);

  return (
    <g>
      <motion.path
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{
          pathLength: { delay: 0, type: 'tween', duration: 2, bounce: 0 },
        }}
        d={linePath}
        fill="none"
        opacity={activeLine ? (activeLineMatcher(lineData[0].key, activeLine) ? 1 : 0.2) : 1}
        stroke={`#${lineData[0].color}`}
        strokeWidth={activeLine ? (activeLineMatcher(lineData[0].key, activeLine) ? 3.8 : 2.4) : 2.4}
        id={lineData[0].key}
      />

      {lineData.length === 1
        ? lineData.map((d, i) =>
            d.date && d.value ? (
              <circle key={d.date} r="3" cx={xScale(d.date)} cy={yScale(d.value)} fill={`#${lineData[0].color}`} />
            ) : null,
          )
        : null}

      {/* Adding extra line here for dashed line animation */}
      {isPastCompareString(lineData[0].key) ? (
        <>
          <path
            stroke={theme === 'light' ? '#fff' : '#202939'}
            strokeDasharray="5px"
            strokeDashoffset="0"
            strokeWidth={activeLine ? (activeLineMatcher(lineData[0].key, activeLine) ? 3 : 2.4) : 2.4}
            style={{ fill: 'none', fillRule: 'evenodd', strokeLinejoin: 'round' }}
            d={linePath}
          />
          {activePoint && handleDotClick && pageType === PageType.INSIGHT && (
            <g key={activePoint.compareDate || activePoint.date}>
              <motion.circle
                r={9}
                cx={xScale(isCompareEnabled ? activePoint.compareDate! : activePoint.date)}
                cy={yScale(activePoint.value)}
                fill={theme === 'light' ? '#fff' : '#202939'}
                strokeWidth={1.5}
                strokeOpacity={0.5}
                stroke={`#${activePoint.color}`}
                onClick={() => handleDotClick()}
                className="cursor-pointer"
              />
              <motion.circle
                r={6}
                cx={xScale(isCompareEnabled ? activePoint.compareDate! : activePoint.date)}
                cy={yScale(activePoint.value)}
                fill={`#${activePoint.color}`}
                strokeWidth={1}
                stroke={theme === 'light' ? '#fff' : '#202939'}
                opacity={activeLine ? (activeLineMatcher(activePoint.key, activeLine) ? 1 : 0.4) : 1}
                onClick={() => handleDotClick()}
                className="cursor-pointer"
              />
            </g>
          )}
        </>
      ) : null}

      {/* Note: @jeevesh.g@udaan.com
      Currently only open for insight report */}
      {activePoint && !isPastCompareString(lineData[0].key) && handleDotClick && pageType === PageType.INSIGHT && (
        <g key={activePoint.date}>
          <motion.circle
            r={9}
            cx={xScale(activePoint.date)}
            cy={yScale(activePoint.value)}
            fill={theme === 'light' ? '#fff' : '#202939'}
            strokeWidth={1.5}
            strokeOpacity={0.5}
            stroke={`#${activePoint.color}`}
            onClick={() => handleDotClick()}
            className="cursor-pointer"
          />
          <motion.circle
            r={6}
            cx={xScale(activePoint.date)}
            cy={yScale(activePoint.value)}
            fill={`#${activePoint.color}`}
            stroke={theme === 'light' ? '#fff' : '#202939'}
            onClick={() => handleDotClick()}
            className="cursor-pointer"
          />
        </g>
      )}
    </g>
  );
}

import { useIsOnboardingCreatedWorkspace } from '@src/client/helpers/reports/hooks';
import { useIsDemoWorkspace, useLogout, useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { EventNames } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { SdkMenuIcon } from '@src/client/modules/sdk-integration/components/Icons';
import { userInfoState } from '@src/client/recoil/atoms';
import { Avatar, AvatarFallback, AvatarImage } from '@src/client/ui-library/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@src/client/ui-library/dropdown';
import { useTheme } from '@src/client/ui-library/theme-provider';
import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { LogoutIcon, SettingsIcon } from '../../ui-library/icons/NavbarIcons';
import { EventDropdownIcon } from '../../ui-library/icons/ReportIcons';
import LucidIconWrapper from '../lucid-icon-wrapper';
import { ELEVATED_ACCESS_ROLES } from './constants';
import ThemeToggler from './ThemeToggler';
import UIVersionToggler from './UIVersionToggler';

const elevatedAccessItems = [
  {
    label: 'Users and Teams',
    link: 'settings/manage-users',
    eventName: EventNames.MANAGE_USERS_CLICKED,
    icon: <LucidIconWrapper name="users" />,
  },
  {
    label: 'Data Governance',
    link: 'settings/data-governance',
    eventName: EventNames.DATA_GOVERNANCE_CLICKED,
    icon: <LucidIconWrapper name="shield-ellipsis" />,
  },
  {
    label: 'Engage Settings',
    link: 'engage-settings',
    eventName: EventNames.ENGAGE_SETTINGS_CLICKED,
    icon: <LucidIconWrapper name="captions" />,
  },
  {
    label: 'Workspace Settings',
    link: 'workspace-settings',
    eventName: EventNames.WORKSPACE_SETTINGS_CLICKED,
    icon: <LucidIconWrapper name="briefcase" />,
  },
];

function DropdownItem({
  label,
  link,
  eventName,
  icon,
}: {
  label: string;
  link: string;
  eventName: EventNames;
  icon: ReactNode;
}) {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  return (
    <div>
      <DropdownMenuLabel className="flex items-center justify-between mb-2 hover:bg-gray-50 dark:hover:bg-foreground-secondary">
        <Link
          to={getLinkWithWorkspace(link)}
          onClick={() => Tracker.trackEvent(eventName)}
          className="inline-flex items-center"
        >
          <div className="px-2 w-8">{icon}</div>
          <div className="ml-4 text-sm font-semibold">{label}</div>
        </Link>
      </DropdownMenuLabel>
      <DropdownMenuSeparator className="px-2 my-2" />
    </div>
  );
}

export default function SettingsMenu({
  restricted = false,
  isOutline = false,
}: {
  restricted?: boolean;
  isOutline?: boolean;
}) {
  const [userName, setUserName] = useState('');
  const [iconUrl, setIconUrl] = useState('');
  const userInfo = useRecoilValue(userInfoState);
  const isElevatedAccess = userInfo && ELEVATED_ACCESS_ROLES.includes(userInfo.roleName);
  const isDemoWorkspace = useIsDemoWorkspace();
  const themeState = useTheme();
  const isWsCreatedViaOnboardlingFlow = useIsOnboardingCreatedWorkspace();
  const { handleLogout } = useLogout();

  useEffect(() => {
    if (userInfo !== undefined) {
      setUserName(`${userInfo.firstName} ${userInfo.lastName}` || '');
      setIconUrl(userInfo.profileUrl || '');
    }
  }, [userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={`bg-transparent mx-3 rounded-full p-1 ${isOutline ? 'border border-border' : 'outline-none focus:outline-none hover:bg-neutral-dark dark:hover:bg-neutral-dark data-[state=open]:text-gray-300 data-[state=open]:bg-neutral-dark dark:data-[state=open]:text-gray-300 dark:data-[state=open]:bg-neutral-dark'}`}
      >
        <SettingsIcon
          className="pl-0.5 pt-[1px]"
          stroke={isOutline ? (themeState.theme === 'dark' ? 'white' : 'black') : undefined}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel className="flex items-center">
          <Avatar>
            <AvatarImage src={iconUrl} />
            <AvatarFallback>{userName?.[0] ?? ''}</AvatarFallback>
          </Avatar>
          <div className="ml-2 text-sm font-semibold">{userName}</div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator className="px-2 my-2" />
        {!restricted && (
          <DropdownItem
            label="Custom Events"
            link="custom-events/list"
            eventName={EventNames.CUSTOM_EVENTS_PAGE_CLICKED}
            icon={<EventDropdownIcon />}
          />
        )}
        {!isDemoWorkspace && !restricted && (
          <DropdownItem
            label="Sdk Integrations"
            link="sdk-integrations"
            eventName={EventNames.SDK_INTEGRATIONS_CLICKED}
            icon={<SdkMenuIcon />}
          />
        )}
        {isElevatedAccess && !restricted && (
          <section>
            {elevatedAccessItems.map(({ label, link, eventName, icon }) => (
              <DropdownItem key={link} label={label} link={link} eventName={eventName} icon={icon} />
            ))}
          </section>
        )}
        <DropdownMenuLabel className="flex items-center justify-between mb-2">
          <p className="text-xs font-semibold">Dark Mode</p>
          <ThemeToggler />
        </DropdownMenuLabel>
        {isWsCreatedViaOnboardlingFlow ? null : (
          <DropdownMenuLabel className="flex items-center justify-between mb-2">
            <p className="text-xs font-semibold">New UI</p>
            <UIVersionToggler />
          </DropdownMenuLabel>
        )}
        <DropdownMenuLabel
          onClick={handleLogout}
          className="flex items-center justify-between cursor-pointer rounded-md bg-transparent hover:bg-gray-50 dark:hover:bg-foreground-secondary"
        >
          <p className="text-xs font-semibold">Logout</p>
          <LogoutIcon />
        </DropdownMenuLabel>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

import { CustomCompareStateType } from '@src/client/components/filters-and-selectors/compare-selector/atoms';
import { Dayjs } from 'dayjs';

import { ChartType, DateRangeEnum, FunnelTrendType, FunnelType, GranularityEnum } from '../reports/constants';
import { Breakdown, DataVizRowAndChart, Formula, FunnelDataVizRowAndChart, FunnelStep } from '../reports/types';

export enum ReportWrokerType {
  INSIGHT_WORKER = 'INSIGHT_WORKER',
  FUNNEL_WORKER = 'FUNNEL_WORKER',
}
export enum InsightWorkerAction {
  generateTableRowsAndChartData = 'generateTableRowsAndChartData',
}

export interface QueryBuilderTimestampInfo {
  dateRange: DateRangeEnum;
  customDateRange?: [Dayjs, Dayjs] | [];
}

export interface InsightWorkerMessageData {
  actionType: InsightWorkerAction;
  reqId: string;
  shouldUseNewComputeInsightFns?: boolean;
  actionData: {
    breakdowns: Breakdown[];
    formulas: Formula[];
    data: any;
    compare: string[] | undefined;
    chartType: ChartType;
    granularity: GranularityEnum;
    customCompareData: CustomCompareStateType | undefined;
    timestampData: QueryBuilderTimestampInfo | undefined;
  };
}

export interface InsightWorkerResponse {
  actionType: InsightWorkerAction;
  reqId: string;
  success: boolean;
  result?: DataVizRowAndChart;
  error?: string;
}

export enum FunnelWorkerAction {
  generateTableRowsAndChartData = 'generateTableRowsAndChartData',
}

export interface FunnelWorkerMessageData {
  actionType: FunnelWorkerAction;
  reqId: string;
  actionData: {
    breakdowns: Breakdown[];
    steps: FunnelStep[];
    data: any;
    funnelType: FunnelType;
    funnelTrendType: FunnelTrendType;
    compare: string[] | undefined;
    granularity: GranularityEnum | undefined;
    customCompareData: CustomCompareStateType | undefined;
    timestampData: QueryBuilderTimestampInfo | undefined;
  };
}

export interface FunnelWorkerResponse {
  actionType: FunnelWorkerAction;
  reqId: string;
  success: boolean;
  result?: FunnelDataVizRowAndChart;
  error?: string;
}

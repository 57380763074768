import { LogicalOperatorType } from '@src/client/helpers/reports/types';
import { SelectOptionsType } from '@src/client/ui-library/select';
import { atom } from 'recoil';

import { CohortMetaState, CohortSummary, GroupFilter } from '../types';

export const activeItemCohortState = atom<CohortSummary | null>({
  key: 'activeItemCohortState',
  default: null,
});

export const cohortMetaState = atom<CohortMetaState>({
  key: 'cohortMetaState',
  default: {
    cohortTitle: '',
    cohortDescription: '',
  },
});

export const groupFiltersState = atom<GroupFilter[]>({
  key: 'groupFiltersState',
  default: [],
  dangerouslyAllowMutability: true,
});

export const selectedUsersState = atom<SelectOptionsType[]>({
  key: 'selectedUsersState',
  default: [],
  dangerouslyAllowMutability: true,
});

export const csvFileState = atom<File | undefined>({
  key: 'csvFileState',
  default: undefined,
  dangerouslyAllowMutability: true,
});

export const csvFileOnErrorState = atom<boolean>({
  key: 'csvFileOnErrorState',
  default: false,
  dangerouslyAllowMutability: true,
});

export const groupOperatorState = atom<LogicalOperatorType>({
  key: 'groupOperatorState',
  default: 'and',
});

export const cohortPrivateDimensionErrorState = atom<Error | unknown>({
  key: 'cohortPrivateDimensionErrorState',
  default: null,
});

export const cohortCountFetchMapState = atom<Map<string, number | undefined>>({
  key: 'cohortCountFetchMapState',
  default: new Map(),
});

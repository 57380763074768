import * as d3 from 'd3';
import { useMemo } from 'react';

import { D3LineData } from '../line-chart/types';

interface Props {
  lineData: D3LineData[];
  xScale: d3.ScaleTime<number, number, never> | d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleLinear<number, number, never>;
}

export default function AreaBuilder({ lineData, xScale, yScale }: Props) {
  const areabuilder = useMemo(
    () =>
      d3
        .area<D3LineData>()
        .x((d) => xScale(d.date))
        .y1((d) => yScale(d.value))
        .y0(yScale(0)),
    // .curve(d3.curveNatural), // d3.curveCatmullRom.alpha(0.5)
    [xScale, yScale],
  );

  const areaPath = useMemo(() => areabuilder(lineData), [areabuilder, lineData]);

  if (!areaPath) {
    return null;
  }

  return (
    <g>
      <path
        d={areaPath}
        opacity={0.5}
        stroke="none"
        fill={`#${lineData[0].color}`}
        // fill="#9a6fb0"
        fillOpacity={0.3}
      />
    </g>
  );
}

import { useUserInfoQuery } from '@src/client/hooks';
import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { updateLastActiveWorkspaceId } from '@src/client/lib/api/mutations/common';
import { UserInfo, Workspace } from '@src/client/lib/api/types/response';
import { ReactElement, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { saveActiveWorkspaceDetailsInLocalStorage } from '../../lib/utils';
import { activeWorkspaceState } from '../../recoil/atoms';
import { Button } from '../../ui-library/button';
import { AnimatedPILoader } from '../../ui-library/loaders';

interface Props {
  routeAccessSelector: string;
  children: ReactElement;
}

function UnauthorizedAccess({ children, routeAccessSelector }: Props) {
  const navigate = useNavigate();
  const { workspaceId: workspaceIdInUrl } = useParams<{ workspaceId: string }>();
  const { userInfo, isLoading, refetchUserInfo } = useUserInfoQuery({ logoutOnError: true });
  const [showChildren, setShowChildren] = useState(false);
  const [showAccessDenied, setShowAccessDenied] = useState(false);
  const [activeWorkspace, setActiveWorkspace] = useRecoilState(activeWorkspaceState);
  const updateActiveWorkspaceRequest = useMutation(updateLastActiveWorkspaceId);

  const updateWorkspaceToUseOnClient = (wsToUse: Workspace) => {
    setActiveWorkspace(wsToUse);
    saveActiveWorkspaceDetailsInLocalStorage(wsToUse.id, wsToUse.tenantId);
  };

  const asyncActiceWorspaceMutation = (wsToUse: Workspace, changingFrom?: string) =>
    updateActiveWorkspaceRequest
      .mutateAsync({ lastActiveWorkspaceId: wsToUse.id })
      .then((_res) => {
        updateWorkspaceToUseOnClient(wsToUse);
      })
      .catch((error) => {
        Tracker.trackError(error, ErrorTags.ACTIVE_WORKSPACE_UPDATE_ERROR, {
          workspaceToUpdate: wsToUse.id,
          changingFrom,
        });
      });

  const doActiveWorkspaceValidation = async (data: UserInfo) => {
    // Workspace id in url
    let workspaceDataIndex = data.workspaces.findIndex((ws) => ws.id === workspaceIdInUrl);
    const serverActiveWorkspaceId = data.activeWorkspaceId;

    // url workspace id found in workspaces list
    if (workspaceDataIndex > -1) {
      const wsToUse = data?.workspaces[workspaceDataIndex];
      if (serverActiveWorkspaceId !== workspaceIdInUrl) {
        // Update remote active workspace id
        await asyncActiceWorspaceMutation(wsToUse, 'URL');
        refetchUserInfo();
        return;
      }
      // update local active workspace info and resume workflow
      updateWorkspaceToUseOnClient(wsToUse);
      setShowChildren(true);
      setShowAccessDenied(false);
    } else {
      // url workspace id not found in workspaces list

      // use the remote active workspace info if available
      workspaceDataIndex = data?.workspaces?.findIndex((ws) => ws.id === serverActiveWorkspaceId);
      if (workspaceDataIndex > -1) {
        // use remote active workspace info only if it matches with one of the available workspaces
        const wsToUse = data.workspaces[workspaceDataIndex];
        updateWorkspaceToUseOnClient(wsToUse);
        setShowAccessDenied(true);
        setShowChildren(false);
      } else {
        // maybe the remote workspace ingo is corrupt as data not found in workspace info list. use the first one from available data
        const wsToUse = data?.workspaces[0];
        await asyncActiceWorspaceMutation(wsToUse, 'DEFAULT_WORKSPACE_LIST');
        refetchUserInfo();
      }
    }
  };

  useEffect(() => {
    if (!isLoading && userInfo) {
      if (!userInfo?.accessScopes?.includes(routeAccessSelector)) {
        setShowAccessDenied(true);
        setShowChildren(false);
        return;
      }
      doActiveWorkspaceValidation(userInfo);
    }
  }, [isLoading, userInfo, routeAccessSelector, workspaceIdInUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  if (showChildren) return children;

  if (showAccessDenied)
    return (
      <div className="flex h-screen bg-background">
        <div className="m-auto text-center">
          <img src="/images/v2/errors/403.svg" alt="Unauthorized" />
          <Button variant="primary" onClick={() => navigate(`/${activeWorkspace?.id}/home`)}>
            Go back home
          </Button>
        </div>
      </div>
    );

  return (
    <div className="h-full flex items-center justify-center bg-background" style={{ height: '100vh' }}>
      <AnimatedPILoader />
    </div>
  );
}

export default UnauthorizedAccess;

import { Switch } from '@src/client/ui-library/switch';
import { useUIVersion } from '@src/client/ui-library/theme-provider/uiProvider';
import { useCallback } from 'react';

export default function UIVersionToggler() {
  const { uiVersion, setUIVersion } = useUIVersion();

  const handleUiVersionChange = useCallback(
    (newVersionSelected: boolean) => {
      const version = newVersionSelected ? 'v3' : 'v2';
      setUIVersion(version);
    },
    [uiVersion], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <Switch id="new-ui" checked={uiVersion === 'v3'} onCheckedChange={handleUiVersionChange} />;
}

import { CalendarIcon } from '@radix-ui/react-icons';
import { PluralSupportedTimeUnits } from '@src/client/lib/api/types/response';
import { Button } from '@src/client/ui-library/button';
import { Calendar, CalendarDateRange } from '@src/client/ui-library/calendar';
import { Input } from '@src/client/ui-library/input';
import { Popover, PopoverContent, PopoverTrigger } from '@src/client/ui-library/popover';
import { Select, SelectOptionsType } from '@src/client/ui-library/select';
import Tabs from '@src/client/ui-library/tabs';
import dayjs from 'dayjs';
import { useState } from 'react';

import { TIME_WINDOW_OPTIONS } from '../constants';
import { TimeWindowTypes } from '../types';

interface Props {
  timeWindowType: TimeWindowTypes;
  timeWindowValue: number;
  timeWindowUnit: PluralSupportedTimeUnits.DAYS | PluralSupportedTimeUnits.MONTHS | PluralSupportedTimeUnits.WEEKS;
  timeWindowFromTimestamp: dayjs.Dayjs;
  timeWindowToTimestamp: dayjs.Dayjs;
  onTimeWindowTabChange: (key: string) => void;
  selectTimeWindowValue: (val: number) => void;
  selectTimeWindowUnit: (
    val: PluralSupportedTimeUnits.DAYS | PluralSupportedTimeUnits.MONTHS | PluralSupportedTimeUnits.WEEKS,
  ) => void;
  selectTimeWindowFromTimestamp: (val: dayjs.Dayjs) => void;
  selectTimeWindowToTimestamp: (val: dayjs.Dayjs) => void;
}

export default function EventFilterTimeWindowSelector({
  onTimeWindowTabChange,
  selectTimeWindowUnit,
  selectTimeWindowValue,
  selectTimeWindowFromTimestamp,
  selectTimeWindowToTimestamp,
  timeWindowFromTimestamp,
  timeWindowToTimestamp,
  timeWindowUnit,
  timeWindowValue,
  timeWindowType,
}: Props) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const getDisabledDates = (current: Date) => current && current.valueOf() > Date.now();

  const timeWindowTabItems = [
    {
      key: TimeWindowTypes.Last.toString(),
      label: (
        <div className="flex items-center space-x-1">
          <CalendarIcon /> <span>Last</span>
        </div>
      ),
      children: (
        <div className="flex items-center justify-between">
          <Input
            type="number"
            value={timeWindowValue}
            min={1}
            placeholder="Enter a value"
            onChange={(e) =>
              /^\d+$/.test(e.target.value) ? selectTimeWindowValue(Number(e.target.value)) : selectTimeWindowValue(1)
            }
            className="w-full mr-2"
          />
          <Select
            value={TIME_WINDOW_OPTIONS.find((item) => item.value === timeWindowUnit)}
            onChange={(val) => {
              const selectedValue = (val as SelectOptionsType).value as
                | PluralSupportedTimeUnits.DAYS
                | PluralSupportedTimeUnits.MONTHS
                | PluralSupportedTimeUnits.WEEKS;
              selectTimeWindowUnit(selectedValue);
            }}
            options={TIME_WINDOW_OPTIONS}
            selectClassNames={{ control: 'w-[120px]' }}
          />
        </div>
      ),
    },
    {
      key: TimeWindowTypes.Fixed.toString(),
      label: (
        <div className="flex items-center space-x-1">
          <CalendarIcon /> <span>Fixed</span>
        </div>
      ),
      children: (
        <div className="flex justify-center">
          <Calendar
            mode="range"
            numberOfMonths={1}
            disabled={getDisabledDates}
            defaultMonth={timeWindowToTimestamp.toDate()}
            selected={{ from: timeWindowFromTimestamp.toDate(), to: timeWindowToTimestamp.toDate() }}
            onSelect={(range: CalendarDateRange | undefined, selectedDay: Date) => {
              if (!range) {
                selectTimeWindowFromTimestamp(dayjs(selectedDay));
                selectTimeWindowToTimestamp(dayjs(selectedDay));
                return;
              }
              selectTimeWindowFromTimestamp(dayjs(range.from));
              selectTimeWindowToTimestamp(dayjs(range.to ?? range.from));
            }}
          />
        </div>
      ),
    },
    {
      key: TimeWindowTypes.Since.toString(),
      label: (
        <div className="flex items-center space-x-1">
          <CalendarIcon /> <span>Since</span>
        </div>
      ),
      children: (
        <div className="flex justify-center">
          <Calendar
            mode="single"
            selected={timeWindowFromTimestamp.toDate()}
            defaultMonth={timeWindowFromTimestamp.toDate()}
            disabled={getDisabledDates}
            onSelect={(day: Date | undefined, _selectedDay: Date) => {
              if (day) {
                selectTimeWindowFromTimestamp(dayjs(day));
              }
            }}
          />
        </div>
      ),
    },
  ];

  const handlePopoverStateChange = (open: boolean) => {
    setPopoverOpen(open);
  };

  return (
    <Popover open={popoverOpen} onOpenChange={handlePopoverStateChange}>
      <PopoverTrigger asChild onClick={() => setPopoverOpen(!popoverOpen)}>
        <Button variant="outline" className="h-[40px] min-w-300 rounded-xl">
          {timeWindowType === TimeWindowTypes.Last && `Last ${timeWindowValue} ${timeWindowUnit}`}
          {timeWindowType === TimeWindowTypes.Fixed &&
            `${timeWindowFromTimestamp.format('MMMM D, YYYY')}${
              timeWindowFromTimestamp.isSame(timeWindowToTimestamp)
                ? ''
                : ` - ${timeWindowToTimestamp.format('MMMM D, YYYY')}`
            }`}
          {timeWindowType === TimeWindowTypes.Since && `Since ${timeWindowFromTimestamp.format('MMMM D, YYYY')}`}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto min-w-[300px]">
        <Tabs
          defaultActiveKey={timeWindowType.toString()}
          tabItems={timeWindowTabItems}
          onChange={onTimeWindowTabChange}
        />
      </PopoverContent>
    </Popover>
  );
}

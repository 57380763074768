import { ChartType } from '@src/client/helpers/reports/constants';
import { LineChartData, LineChartDataPoint } from '@src/client/helpers/reports/types';
import { CreateReportRequest } from '@src/client/lib/api/types/request';
import { NonTimeseriesDataApiRes, ReportType, TimeseriesDataApiRes } from '@src/client/lib/api/types/response';
import { DefaultDashboardGraphColor } from '@src/client/modules/dashboard/constants';
import dayjs from 'dayjs';
import { round } from 'lodash-es';

import { MetricCardType, MetricDataApiRes } from './constants';

export const getMetricChangePercentage = (
  original: TimeseriesDataApiRes | NonTimeseriesDataApiRes,
  compare: TimeseriesDataApiRes | NonTimeseriesDataApiRes,
) => {
  const change = original.total - compare.total;
  const changePercentage = compare.total > 0 ? round((change / compare.total) * 100, 1) : 0;

  return changePercentage;
};

export const getLineChartDataForMetricCard = (data: MetricDataApiRes, metricType: MetricCardType): LineChartData[] => {
  if (data?.original['$All Events-A']) {
    const dataPoints: LineChartDataPoint[] = [];

    Object.keys(data.original['$All Events-A']).forEach((key) => {
      if (dayjs(key).isValid()) {
        dataPoints.push({
          date: dayjs(key).unix(),
          value: data.original['$All Events-A'][key],
        });
      }
    });
    return [
      {
        key: metricType,
        series: metricType,
        color: DefaultDashboardGraphColor,
        data: dataPoints.sort((a, b) => b.date - a.date),
      },
    ];
  }

  return [];
};

export const getConfigForMetricsCard = (
  metricType: MetricCardType,
  reportType: ReportType,
  chartType: ChartType,
  virtualAllEventId: string,
): CreateReportRequest => {
  const config: CreateReportRequest = {
    isSaved: false,
    itemDescription: '',
    itemName: '',
    itemScope: 'PUBLIC',
    reportType,
    params: {
      additional_metrics: {
        metrics_list: ['average'],
      },
      chart_props: {
        chart_type: chartType,
        view_mode: 'linear',
      },
      dimension: [],
      timestamp_props: {
        date_range_type: '7d',
        granularity: 'day',
      },
      compare: {
        sub_type: 'previous_week',
        type: 'time_period',
      },
    },
  };

  if (metricType === MetricCardType.EventVolume) {
    config.params.dimension = [
      {
        alias: 'A',
        dimension_label: '$All Events',
        math: 'total',
        name: virtualAllEventId,
        resource_type: 'VIRTUAL_EVENT',
      },
    ];
  }

  if (metricType === MetricCardType.MAU) {
    config.params.dimension = [
      {
        aggregate_properties: {
          aggregate: 'unique',
          column_name: 'unique_id',
        },
        alias: 'A',
        dimension_label: '$All Events',
        math: 'aggregate',
        name: virtualAllEventId,
        resource_type: 'VIRTUAL_EVENT',
      },
    ];
  }

  if (metricType === MetricCardType.DAU) {
    config.params.dimension = [
      {
        aggregate_properties: {
          aggregate: 'unique',
          column_name: 'unique_id',
        },
        alias: 'A',
        dimension_label: '$All Events',
        math: 'aggregate',
        name: virtualAllEventId,
        resource_type: 'VIRTUAL_EVENT',
      },
    ];
  }

  if (metricType === MetricCardType.NewUsers) {
    config.params.dimension = [
      {
        aggregate_properties: {
          aggregate: 'unique',
          column_name: 'unique_id',
        },
        alias: 'A',
        dimension_label: '$All Events',
        math: 'aggregate',
        name: 'App Opened',
        resource_type: 'EVENT',
        first_time_filter: true,
      },
    ];
  }

  return config;
};

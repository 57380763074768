import { isLengthyArray } from '@src/client/lib/utils';
import { atom, selector } from 'recoil';

import { ChartData, DataVizRow, LineChartData } from '../../helpers/reports/types';

export const insightNameState = atom<string>({
  key: 'insightNameState',
  default: '',
});

export const insightDescriptionState = atom<string>({
  key: 'insightDescriptionState',
  default: '',
});

export const isInsightsLoadingState = atom<boolean>({
  key: 'isInsightsLoadingState',
  default: false,
});

export const insightsRunIdState = atom<string>({
  key: 'insightsRunIdState',
  default: undefined,
});

export const insightsErrorState = atom<Error | unknown>({
  key: 'insightsErrorState',
  default: null,
});

export const insightReportRowState = atom<DataVizRow[]>({
  key: 'insightReportRowState',
  default: [],
});

export const insightRowsSelectionState = atom<React.Key[]>({
  key: 'insightRowsSelectionState',
  default: [],
});

export const insightChartDataState = atom<ChartData>({
  key: 'insightChartDataState',
  default: [],
});

export const insightTableDataPageIndexState = atom<number>({
  key: 'insightTableDataPageIndexState',
  default: 1,
});

export const insightPrivateDimensionErrorState = atom<Error | unknown>({
  key: 'insightPrivateDimensionErrorState',
  default: null,
});

export const insightsRawApiResponseState = atom<Record<string, unknown> | null>({
  key: 'insightsRawApiResponseState',
  default: null,
});

export const isInsightSavedState = atom<boolean>({
  key: 'isInsightSavedState',
  default: true,
});

export const areSelectedRowsDirtyState = atom<boolean>({
  key: 'areSelectedRowsDirtyState',
  default: false,
});

export const formattedLineChartDataState = selector({
  key: 'formattedLineChartDataState',
  get: ({ get }) => {
    const chartData = get(insightChartDataState);
    const selectedRowKeys = get(insightRowsSelectionState);
    return ((chartData || []) as LineChartData[])
      .filter((d) => (isLengthyArray(selectedRowKeys) ? selectedRowKeys.includes(d.key) : false))
      .reduce(
        (accm: LineChartData[], item: LineChartData) =>
          isLengthyArray(item.data)
            ? [
                ...accm,
                {
                  series: item.series,
                  color: item.color,
                  key: item.key,
                  data: [...item.data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
                },
              ]
            : [...accm],
        [],
      );
  },
});

export const isInsightChartDataEmptySelector = selector({
  key: 'isInsightChartDataEmptySelector',
  get: ({ get }) => {
    const chartData = get(insightChartDataState);

    return !isLengthyArray(chartData);
  },
});

export const canLoadMoreInsightTableDataSelector = selector({
  key: 'canLoadMoreInsightTableDataSelector',
  get: ({ get }) => {
    const tableRowData = get(insightReportRowState);
    const tableDataPageIndex = get(insightTableDataPageIndexState);

    return tableRowData.length > tableDataPageIndex * 100;
  },
});

export const getInsightTableDataSelector = selector({
  key: 'getInsightTableDataSelector',
  get: ({ get }) => {
    const tableRowData = get(insightReportRowState);
    const tableDataPageIndex = get(insightTableDataPageIndexState);

    return isLengthyArray(tableRowData) ? tableRowData.slice(0, tableDataPageIndex * 100) : [];
  },
});

import { LineChartData } from '@src/client/helpers/reports/types';
import { isLengthyArray } from '@src/client/lib/utils';
import { useMemo, useRef } from 'react';
import { useResizeObserver } from 'usehooks-ts';

import { getFormattedLineDataPoints } from '../line-chart/utils';
import AreaChartSvg from './AreaChartSvg';

interface Props {
  lineChartData: LineChartData[];
  chartHeight?: number;
  hideTooltip?: boolean;
}

export default function TinyAreaChart({ lineChartData, chartHeight, hideTooltip }: Props) {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const allLineDataPoints = useMemo(() => getFormattedLineDataPoints(lineChartData, false), [lineChartData]);
  const { width: chartContainerWidth = 0 } = useResizeObserver({
    ref: chartContainerRef,
    box: 'border-box',
  });

  return (
    <div className="relative h-full px-4" ref={chartContainerRef}>
      {chartContainerWidth > 0 && isLengthyArray(allLineDataPoints) ? (
        <AreaChartSvg
          lineData={allLineDataPoints}
          width={chartContainerWidth}
          height={chartHeight ?? 200}
          hideTooltip={hideTooltip}
        />
      ) : null}
    </div>
  );
}

import { ReportTypes } from '@src/client/helpers/constants';
import { ReportType } from '@src/client/lib/api/types/response';
import { DropdownMenuSeparator } from '@src/client/ui-library/dropdown';
import { FlowIconV3, FunnelIconV3, InsightIconV3, RetentionIconV3 } from '@src/client/ui-library/icons/v3';
import { useEffect, useMemo } from 'react';

import NestedNavigationMenu from './NestedNavigationMenu';
import SidepanelNavLink from './SidepanelNavLink';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';

export default function CreateReportsMenu({
  isNavigationSidepanelOpen,
  currentPathname,
  toggleSidepanel,
}: {
  isNavigationSidepanelOpen: boolean;
  currentPathname: string;
  toggleSidepanel: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const ReportTypeDisplyInfo = useMemo(
    () => ({
      [ReportType.INSIGHT]: {
        title: 'Insights',
        path: 'insights',
        description: 'Deep dive into behavior trends and key event analytics over time.',
        imageSrc: '/images/v2/insights-small.png',
        color: '#FFFAEB',
        icon: (
          <InsightIconV3 active={currentPathname.includes('insights') && !currentPathname.includes('insights-hub')} />
        ),
        tooltipIcon: <InsightIconV3 active />,
      },
      [ReportType.FUNNEL]: {
        title: 'Funnels',
        path: 'funnels',
        description: 'Track, visualize and engage in the user journey, including points of drop-off.',
        imageSrc: '/images/v2/funnels-small.png',
        color: '#FFFBFA',
        icon: <FunnelIconV3 active={currentPathname.includes('funnels')} />,
        tooltipIcon: <FunnelIconV3 active />,
      },
      [ReportType.FLOW]: {
        title: 'Flows',
        path: 'flows',
        description: 'Analyze user pathways from entry point to final action.',
        imageSrc: '/images/v2/flows-small.png',
        color: '#F6FEF9',
        icon: <FlowIconV3 active={currentPathname.includes('flows')} />,
        tooltipIcon: <FlowIconV3 active />,
      },
      [ReportType.RETENTION]: {
        title: 'Retention',
        path: 'retention',
        description: 'Measure user retention and repeats by tracking repeat visits and engagement frequency.',
        imageSrc: '/images/v2/retention-small.png',
        color: '#ECFDFF',
        icon: <RetentionIconV3 active={currentPathname.includes('retention')} />,
        tooltipIcon: <RetentionIconV3 active />,
      },
    }),
    [currentPathname],
  );
  const isCreateReportsPageOpen = useMemo(
    () =>
      (currentPathname.includes(ReportTypeDisplyInfo[ReportType.INSIGHT].path) ||
        currentPathname.includes(ReportTypeDisplyInfo[ReportType.FUNNEL].path) ||
        currentPathname.includes(ReportTypeDisplyInfo[ReportType.FLOW].path) ||
        currentPathname.includes(ReportTypeDisplyInfo[ReportType.RETENTION].path)) &&
      !currentPathname.includes('insights-hub'),
    [currentPathname], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (isCreateReportsPageOpen) {
      toggleSidepanel(false);
    }
  }, [isCreateReportsPageOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NestedNavigationMenu
      isNavigationSidepanelOpen={isNavigationSidepanelOpen}
      triggerText="Create Reports"
      isActive={isCreateReportsPageOpen}
      triggerIcon={<InsightIconV3 active={isCreateReportsPageOpen} />}
      tooltipContent={
        <SidepanelNavlinkTooltip
          icon={ReportTypeDisplyInfo[ReportType.INSIGHT].tooltipIcon}
          description={
            isNavigationSidepanelOpen
              ? 'Comprehensive reports to analyse user behaviour, and understand retention, drop offs'
              : 'Create Reports'
          }
        />
      }
    >
      {ReportTypes.map((reportType, idx) => {
        const reportDisplayData = ReportTypeDisplyInfo[reportType];
        return (
          <div key={reportDisplayData.title + reportDisplayData.path} className="w-full">
            <SidepanelNavLink
              isNavigationSidepanelOpen
              path={reportDisplayData.path}
              text={reportDisplayData.title}
              icon={reportDisplayData.icon}
              // reloadPage={true}
              tooltipContent={
                <SidepanelNavlinkTooltip
                  icon={reportDisplayData.tooltipIcon}
                  description={reportDisplayData.description}
                />
              }
            />
            {idx !== ReportTypes.length - 1 && <DropdownMenuSeparator className="px-2 my-2" />}
          </div>
        );
      })}
    </NestedNavigationMenu>
  );
}

import { useAllProperties } from '@src/client/helpers/reports/hooks';
import { PropertiesAndCohorts } from '@src/client/helpers/reports/types';
import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { searchDimensionValues } from '@src/client/lib/api/queries/common';
import { convertToLabelValue, isLengthyArray } from '@src/client/lib/utils';
import { Button } from '@src/client/ui-library/button';
import { DeleteIcon } from '@src/client/ui-library/icons/DashboardIcons';
import { Select, SelectOptionsType } from '@src/client/ui-library/select';
import AsyncCreatableSelect from '@src/client/ui-library/select/AsyncCreatableSelect';
import { ReactNode, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { GroupBase, OptionsOrGroups } from 'react-select';

import { EVENT_PROPERTY_FILTER_TYPE_OPTIONS } from '../constants';
import { EventTypeRow, PropertyType, SupportedFilterConditions } from '../types';

interface UserPropertyFilterProps {
  value: EventTypeRow;
  onChange: (value: EventTypeRow) => void;
  remove: (index: number) => void;
  rowIndex: number;
  groupOperatorSelector: ReactNode;
}

function UserPropertyFilterRow({ value, rowIndex, onChange, remove, groupOperatorSelector }: UserPropertyFilterProps) {
  const [filterBy, setFilterBy] = useState<SupportedFilterConditions>(SupportedFilterConditions.equal);
  const [valueArray, setValueArray] = useState<SelectOptionsType[]>(
    value && value.filter ? (value?.filter?.value as string[]).map((f) => ({ value: f, label: f })) : [],
  );
  const { loading, properties } = useAllProperties();
  const [selectedProperty, setSelectedProperty] = useState<PropertiesAndCohorts>();

  useEffect(() => {
    if (isLengthyArray(properties) && !selectedProperty && value.property) {
      if (isLengthyArray(value.property)) {
        const property = properties.find((item) => item.value === value.property[0]);
        setSelectedProperty(property);
      }
      if (typeof value.property === 'string' && value.property) {
        const property = properties.find((item) => item.value === value.property);
        setSelectedProperty(property);
      }
    }
  }, [value.property, properties]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedProperty && selectedProperty.value) {
      const tempValue: EventTypeRow = {
        type: PropertyType.USER,
        property: selectedProperty?.value,
        filter: {
          condition: SupportedFilterConditions.array,
          value: valueArray?.map((item) => item.value) ?? [],
        },
      };
      onChange(tempValue);
    }
  }, [valueArray?.length, filterBy, selectedProperty]); // eslint-disable-line react-hooks/exhaustive-deps

  const propertyValuesSearch = useMutation(searchDimensionValues, {
    retry: 1,
    onSuccess: (_response) => {},
    onError: (err: Error, variables) => {
      Tracker.trackError(err, ErrorTags.DIMENSION_VALUES_SEARCH_ERROR, {
        dimension: variables.dimension,
        searchString: variables.searchString,
        limit: variables.limit,
        offset: variables.offset,
      });
    },
  });

  const loadPropertyValues = (
    inputValue: string,
    _cb: any,
  ): Promise<
    OptionsOrGroups<PropertiesAndCohorts | SelectOptionsType, GroupBase<PropertiesAndCohorts | SelectOptionsType>>
  > | void =>
    new Promise((resolve) => {
      if (!selectedProperty?.value) {
        resolve([]);
        return;
      }
      propertyValuesSearch
        .mutateAsync({
          dimension: selectedProperty ? selectedProperty.value : '',
          limit: 1000,
          searchString: inputValue.length < 1 ? '<all>' : inputValue,
          offset: 0,
        })
        .then((dimensionValuesResponse: string | string[]) => {
          const convertedValues = convertToLabelValue(dimensionValuesResponse);
          const dimensionVals = Array.isArray(convertedValues) ? convertedValues : [convertedValues];
          resolve(dimensionVals);
        })
        .catch((err: Error) => {
          Tracker.trackError(err, ErrorTags.DIMENSION_VALUES_SEARCH_ERROR, {
            dimension: selectedProperty,
            searchString: inputValue,
            limit: 1000,
            offset: 0,
          });
          resolve([]);
        });
    });

  return (
    <div className="flex flex-row gap-x-2 gap-y-2 flex-wrap">
      <div className="flex justify-center items-center">
        {rowIndex === 0 ? <span className="w-20 text-center">where</span> : groupOperatorSelector}
      </div>
      <div>
        <Select
          placeholder="Select a filter property"
          isLoading={loading}
          options={properties}
          value={selectedProperty}
          onChange={(selectedValue) => {
            const option = selectedValue as PropertiesAndCohorts;
            setSelectedProperty(option);
            setValueArray([]);
          }}
          selectClassNames={{
            container: 'w-[300px]',
          }}
        />
      </div>
      <div className="min-w-80">
        <Select
          options={EVENT_PROPERTY_FILTER_TYPE_OPTIONS}
          value={
            filterBy === SupportedFilterConditions.equal
              ? EVENT_PROPERTY_FILTER_TYPE_OPTIONS.find((entry) => entry.label === 'IS')
              : undefined
          }
          onChange={(val) => {
            setFilterBy((val as SelectOptionsType).value as SupportedFilterConditions);
          }}
          placeholder="filter by..."
        />
      </div>
      <div>
        <AsyncCreatableSelect
          key={selectedProperty?.value}
          isMulti
          placeholder="Search values.."
          onChange={(val) => {
            setValueArray(val as SelectOptionsType[]);
          }}
          value={valueArray}
          loadOptions={loadPropertyValues}
          defaultOptions
          selectClassNames={{
            container: 'w-[300px]',
          }}
        />
      </div>
      <Button variant="icon" onClick={() => remove(rowIndex)}>
        <DeleteIcon />
      </Button>
    </div>
  );
}

export default UserPropertyFilterRow;

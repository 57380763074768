import { Filter } from '@src/client/helpers/reports/types';
import { atom, selector, useResetRecoilState } from 'recoil';

import { getValidFilters } from './utils';

export const globalFiltersStateV2 = atom<Filter[]>({
  key: 'globalFiltersStateV2',
  default: [],
});

export const validGlobalFiltersState = selector({
  key: 'validGlobalFiltersState',
  get: ({ get }) => {
    const filters = get(globalFiltersStateV2);
    return getValidFilters(filters);
  },
});

/* Helpers */

export const useResetGlobalFiltersStateV2 = () => {
  const resetGlobalFilters = useResetRecoilState(globalFiltersStateV2);

  return () => {
    resetGlobalFilters();
  };
};

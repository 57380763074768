import { RequestByItemExtId } from '@src/client/lib/api/types/request';
import { fetchEndpoint, PlatformService } from '@src/client/lib/api/utils';

import {
  CohortItemResponse,
  CreateCohortRequest,
  CreateCohortResponse,
  DeleteItemResponse,
  DownloadItemResponse,
  UpdateCohortRequest,
} from '../types';
import { CohortEndpoints } from './endpoints';

/* eslint-disable import/prefer-default-export */
export const deleteCohort = async (payload: RequestByItemExtId) => {
  const data: DeleteItemResponse = await fetchEndpoint(
    CohortEndpoints.cohortByItemExtId,
    { item_ext_id: payload.itemExtId },
    {},
    {},
    'DELETE',
    PlatformService,
  );
  return data;
};

export const createCohort = async (payload: CreateCohortRequest) => {
  const data: CreateCohortResponse = await fetchEndpoint(
    CohortEndpoints.createCohort,
    {},
    {},
    payload,
    'POST',
    PlatformService,
  );
  return data;
};

export const uploadCohortCsv = async (payload: FormData) => {
  const data: CreateCohortResponse = await fetchEndpoint(
    CohortEndpoints.uploadCsvFile,
    {},
    {},
    payload,
    'POST',
    PlatformService,
    {},
    'multipart/form-data',
  );
  return data;
};

export const downloadCohortCsv = async (payload: RequestByItemExtId) => {
  const data: DownloadItemResponse = await fetchEndpoint(
    CohortEndpoints.downloadCsvFile,
    { item_ext_id: payload.itemExtId },
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const updateCohort = async (payload: UpdateCohortRequest) => {
  const data: CreateCohortResponse = await fetchEndpoint(
    CohortEndpoints.cohortByItemExtId,
    { item_ext_id: payload.itemExternalId },
    {},
    payload,
    'POST',
    PlatformService,
  );
  return data;
};

export const getCohortItem = async (payload: RequestByItemExtId) => {
  const data: CohortItemResponse = await fetchEndpoint(
    CohortEndpoints.cohortByItemExtId,
    { item_ext_id: payload.itemExtId },
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};
